import axios from "axios"
import { auth } from "./jwt-token-access/auth-token-header"
import { Notify } from "../components/Common"

//apply base url for axios
const API_URL = process.env?.REACT_APP_API_URL || "http://localhost:5000"

const axiosApi = axios.create({
  baseURL: API_URL,
})

const path = "/graphql"

//axiosApi.defaults.headers.common["Authorization"] = null

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(config = {}) {
  return await axiosApi.get(path, { ...config }).then(response => response.data)
}

export async function post(data, config = {}) {
  return axiosApi
    .post(path, { ...data }, {
      ...config, headers: {
        authorization: auth.authHeader
      }
    })
    .then(response => response.data)
}

export async function print(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, {
      ...config, headers: {
        authorization: auth.authHeader
      }
    })
    .then(response => response.data)
}

export async function put(data, config = {}) {
  return axiosApi
    .put(path, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(config = {}) {
  return await axiosApi
    .delete(path, { ...config })
    .then(response => response.data)
}

export async function getExcel(url, name, config = {
  responseType: 'blob'
}) {
  return await axiosApi.get(url, {
    ...config, headers: {
      authorization: auth.authHeader
    }
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${name}.xlsx`);
    document.body.appendChild(link);
    link.click();
  }).catch(({ response: error }) => {
    var reader = new FileReader();
    reader.onload = function () {
      Notify('error', reader.result)
    }
    reader.readAsText(error.data)
  });
}

export function stringify(obj_from_json) {
  if (typeof obj_from_json !== "object" || Array.isArray(obj_from_json)) {
    // not an object, stringify using native function
    return JSON.stringify(obj_from_json);
  }
  // Implements recursive object serialization according to JSON spec
  // but without quotes around the keys.
  let props = Object
    .keys(obj_from_json)
    .map(key => `${key}:${stringify(obj_from_json[key])}`)
    .join(",");
  return `{${props}}`;
}