export const CREATE_ATTENDANCE = "CREATE_ATTENDANCE"
export const CREATE_ATTENDANCE_SUCCESS = "CREATE_ATTENDANCE_SUCCESS"
export const CREATE_ATTENDANCE_ERROR = "CREATE_ATTENDANCE_ERROR"

export const GET_ATTENDANCE = "GET_ATTENDANCE"
export const GET_ATTENDANCE_SUCCESS = "GET_ATTENDANCE_SUCCESS"
export const GET_ATTENDANCE_ERROR = "GET_ATTENDANCE_ERROR"

export const EDIT_ATTENDANCE = "EDIT_ATTENDANCE"
export const EDIT_ATTENDANCE_SUCCESS = "EDIT_ATTENDANCE_SUCCESS"
export const EDIT_ATTENDANCE_ERROR = "EDIT_ATTENDANCE_ERROR"

export const DELETE_ATTENDANCE = "DELETE_ATTENDANCE"
export const DELETE_ATTENDANCE_SUCCESS = "DELETE_ATTENDANCE_SUCCESS"
export const DELETE_ATTENDANCE_ERROR = "DELETE_ATTENDANCE_ERROR" 

export const SET_ATTENDANCE_FILTER = "SET_ATTENDANCE_FILTER"
export const GET_ATTENDANCE_FILTER = "GET_ATTENDANCE_FILTER"