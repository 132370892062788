import { takeEvery, fork, put, all, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { SET_SALARY_FILTER } from "./actionTypes"
import {
  salaryGetFilter
} from "./actions"


function* sallarySetFilter({ payload }) {
  yield put(salaryGetFilter({ ...payload }))
}

export function* watchSalary() {
  yield takeEvery(SET_SALARY_FILTER, sallarySetFilter)
}

function* salarySaga() {
  yield all([fork(watchSalary)])
}

export default salarySaga
