import {
  SET_TAB,
  GET_TAB
} from "./actionTypes"

export const setTab = ({ name, path }) => {
  return {
    type: SET_TAB,
    payload: { name, path },
  }
}

export const getTab = ({ name, path }) => {
  return {
    type: GET_TAB,
    payload: { name, path },
  }
}
