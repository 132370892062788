import React from 'react'
import { ErrorMessage } from 'formik';
import AsyncSelect from "react-select/async";
import _ from "lodash";

class Select2Child extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			options: 0
		}
	}

	loadOptions = (inputValue) => {
		return new Promise(resolve => {
			const reg = new RegExp(inputValue, "i");
			const filtered = this.props.options.filter(i => reg.test(i.label));
			resolve(filtered.slice(0, this.props.view));
		})
	};

	render() {
		const { errors, touched, name, select_ref, handleSelect, view, options, defaultValue, setValue, isMulti, disabled, placeholder, target=false} = this.props

		const colourStyles = {}
		return (
			<div>
				<AsyncSelect
					{...{
						...(select_ref && { ref: select_ref }),
						...(setValue && { setValue }),
						...(isMulti && { isMulti }),
						name,
						...(placeholder && { placeholder }),
						styles: colourStyles,
						isDisabled: disabled ? true : false,
						defaultOptions: options.slice(0, view),
						className: `basic-multi-select ${errors && touched ? ' is-invalid' : ''}`,
						classNamePrefix: 'select',
						loadOptions: (inputValue) => this.loadOptions(inputValue),
						defaultValue,
						onChange: (selectChoice, { name }) => { handleSelect(selectChoice, { name }) },
						...(target && {menuPortalTarget: document.body}),
					}}
				/>
				{errors && touched && <ErrorMessage name={name} component="div" style={{ color: 'red' }} className="invalid-feedback" />}
			</div>
		)
	}
}

function compare({ is_notmemo, ...prevProps }, { is_notmemo :n, ...nextProps }) {
	if(is_notmemo && n ){
	  return false
	}
	return _.isEqual(_.omit({ ...prevProps }, _.functions({ ...prevProps })), _.omit({ ...nextProps }, _.functions({ ...nextProps })))
  }
  
  const Select2 = React.memo(Select2Child, compare);
  
  export { Select2 }