import {
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  GET_GROUP_USER_SELECT2,
  GET_GROUP_USER_SELECT2_ERROR,
  GET_GROUP_USER_SELECT2_SUCCESS,
  GET_USER_SELECT2,
  GET_USER_SELECT2_SUCCESS,
  GET_USER_SELECT2_ERROR,
  SET_TAB,
  GET_TAB
} from "./actionTypes"

const initialState = {
  userCreateSuccess: null,
  userCreateError: null,
  loading: false,
  userGetError: null,
  userGetSuccess: null,
  userGetLoading: false,
  userEditError: null,
  userEditSuccess: null,
  userEditLoading: false,
  userDeleteError: null,
  userDeleteSuccess: null,
  userDeleteLoading: false,
  userGroupError: null,
  userGroupSuccess: null,
  userGroupLoading: false,
  userSelect2Error: null,
  userSelect2Success: null,
  userSelect2Loading: false,
  name: "Info",
  path: "/data_pegawai/info",
}

const createUser = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_USER:
      state = {
        ...state,
        userCreateSuccess: null,
        userCreateError: null,
        loading: true
      }
      break
    case CREATE_USER_SUCCESS:
      state = {
        ...state,
        userCreateSuccess: action.payload,
        loading: false
      }
      break
    case CREATE_USER_ERROR:
      state = { ...state, userCreateError: action.payload, loading: false }
      break

    case GET_USER:
      state = {
        ...state,
        userGetError: null,
        userGetSuccess: null,
        userGetLoading: true,
      }
      break
    case GET_USER_SUCCESS:
      state = {
        ...state,
        userGetSuccess: action.payload,
        userGetLoading: false
      }
      break
    case GET_USER_ERROR:
      state = {
        ...state,
        userGetError: action.payload,
        userGetLoading: false
      }
      break

    case EDIT_USER:
      state = {
        ...state,
        userEditError: null,
        userEditSuccess: null,
        userEditLoading: true,
      }
      break
    case EDIT_USER_SUCCESS:
      state = {
        ...state,
        userEditSuccess: action.payload,
        userEditLoading: false
      }
      break
    case EDIT_USER_ERROR:
      state = {
        ...state,
        userEditError: action.payload,
        userEditLoading: false
      }
      break

    case DELETE_USER:
      state = {
        ...state,
        userDeleteError: null,
        userDeleteSuccess: null,
        userDeleteLoading: true,
      }
      break
    case DELETE_USER_SUCCESS:
      state = {
        ...state,
        userDeleteSuccess: action.payload,
        userDeleteLoading: false
      }
      break
    case DELETE_USER_ERROR:
      state = {
        ...state,
        userDeleteError: action.payload,
        userDeleteLoading: false
      }
      break

    case GET_GROUP_USER_SELECT2:
      state = {
        ...state,
        userGroupError: null,
        userGroupSuccess: null,
        userGroupLoading: true,
      }
      break
    case GET_GROUP_USER_SELECT2_SUCCESS:
      state = {
        ...state,
        userGroupSuccess: action.payload,
        userGroupLoading: false
      }
      break
    case GET_GROUP_USER_SELECT2_ERROR:
      state = {
        ...state,
        userGroupError: action.payload,
        userGroupLoading: false
      }
      break

    case GET_USER_SELECT2:
      state = {
        ...state,
        userSelect2Error: null,
        userSelect2Success: null,
        userSelect2Loading: true,
      }
      break
    case GET_USER_SELECT2_SUCCESS:
      state = {
        ...state,
        userSelect2Success: action.payload,
        userSelect2Loading: false
      }
      break
    case GET_USER_SELECT2_ERROR:
      state = {
        ...state,
        userSelect2Error: action.payload,
        userSelect2Loading: false
      }
      break
    case SET_TAB:
      state = { ...state }
      break
    case GET_TAB:
      state = { ...action.payload }
      break
    default:
      state = { ...state }
      break
  }
  return state
}


export default createUser
