import { del, get, post, put, stringify } from "../api_helper"

const createWithdrawal = data => {
    const query = `
        mutation createWithdrawal($data : CreateWithdrawalInput!){
            createWithdrawal(input : $data) {
             id
            }
        }
    `
    return post({ query, variables: { data } })
}

const getWithdrawal = data => {
    const query = `
        query getSingleWithdrawal($id : Int!) {
            getSingleWithdrawal(id : $id) {
                id_store
                store {
                    name
                }
                date
                amount
            }
        }
    `
    return post({ query, variables: { ...data } })
}

const editWithdrawal = data => {
    const query = `
        mutation updateWithdrawal($data : UpdateWithdrawalInput!) {
            updateWithdrawal(input : $data) {
             id
            }
        }
    `
    return post({ query, variables: { data } })
}


const deleteWithdrawal = data => {
    const query = `
        mutation daleteWithdrawal($data : DeleteWithdrawalInput!) {
            daleteWithdrawal(input : $data) {
             id
            }
        }
    `
    return post({ query, variables: { data } })
}



export {
    createWithdrawal,
    getWithdrawal,
    editWithdrawal,
    deleteWithdrawal
}
