import {
  CREATE_WITHDRAWAL,
  CREATE_WITHDRAWAL_SUCCESS,
  CREATE_WITHDRAWAL_ERROR,
  GET_WITHDRAWAL,
  GET_WITHDRAWAL_SUCCESS,
  GET_WITHDRAWAL_ERROR,
  EDIT_WITHDRAWAL,
  EDIT_WITHDRAWAL_SUCCESS,
  EDIT_WITHDRAWAL_ERROR,
  DELETE_WITHDRAWAL,
  DELETE_WITHDRAWAL_SUCCESS,
  DELETE_WITHDRAWAL_ERROR,
  SET_WITHDRAWAL_FILTER,
  GET_WITHDRAWAL_FILTER
} from "./actionTypes"

const initialState = {
  createSuccess: null,
  createError: null,
  createLoading: false,
  getError: null,
  getSuccess: null,
  getLoading: false,
  editError: null,
  editSuccess: null,
  editLoading: false,
  deleteError: null,
  deleteSuccess: null,
  deleteLoading: false,
  getFilter: null,
  loadingFilter: false,
}

const createWithdrawal = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_WITHDRAWAL:
      state = {
        ...state,
        createSuccess: null,
        createError: null,
        createLoading: true
      }
      break
    case CREATE_WITHDRAWAL_SUCCESS:
      state = {
        ...state,
        createSuccess: action.payload,
        createLoading: false
      }
      break
    case CREATE_WITHDRAWAL_ERROR:
      state = {
        ...state,
        createError: action.payload,
        createLoading: false
      }
      break

    case GET_WITHDRAWAL:
      state = {
        ...state,
        getError: null,
        getSuccess: null,
        getLoading: true,
      }
      break
    case GET_WITHDRAWAL_SUCCESS:
      state = {
        ...state,
        getSuccess: action.payload,
        getLoading: false
      }
      break
    case GET_WITHDRAWAL_ERROR:
      state = {
        ...state,
        getError: action.payload,
        getLoading: false
      }
      break

    case EDIT_WITHDRAWAL:
      state = {
        ...state,
        editError: null,
        editSuccess: null,
        editLoading: true,
      }
      break
    case EDIT_WITHDRAWAL_SUCCESS:
      state = {
        ...state,
        editSuccess: action.payload,
        editLoading: false
      }
      break
    case EDIT_WITHDRAWAL_ERROR:
      state = {
        ...state,
        editError: action.payload,
        editLoading: false
      }
      break

    case DELETE_WITHDRAWAL:
      state = {
        ...state,
        deleteError: null,
        deleteSuccess: null,
        deleteLoading: true,
      }
      break
    case DELETE_WITHDRAWAL_SUCCESS:
      state = {
        ...state,
        deleteSuccess: action.payload,
        deleteLoading: false
      }
      break
    case DELETE_WITHDRAWAL_ERROR:
      state = {
        ...state,
        deleteError: action.payload,
        deleteLoading: false
      }
      break
    case SET_WITHDRAWAL_FILTER:
      state = {
        ...state,
        getFilter: null,
        loadingFilter: true,
      }
      break
    case GET_WITHDRAWAL_FILTER:
      state = {
        ...state,
        getFilter: action.payload,
        loadingFilter: false
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default createWithdrawal
