import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
    Card,
    CardBody,
    Media,
    Col,
    CardTitle
} from "reactstrap";
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import moment from "moment";

const GET_DASHBOARD = gql`
 query getDashboardOrder($filter : filterRevenue!){
    getDashboardOrder(input : $filter){
        title
        iconClass 
        description
    }
  }
`;

const SUBSCRIPTION_DASHBOARD = gql`
subscription Order($filter : filterRevenue!){
    dashboardOrder(input : $filter){
       title
       iconClass 
       description
    }
  }
`;

const Order = (props) => {
    const { filter: { date_option, date_1, date_2, ...f } } = useSelector(state => ({
        filter: state.Dashboard.getFilter
    }))

    const filter = {
        date_1: moment(date_1).format('YYYY-MM-DD'),
        date_2: moment(date_2).format('YYYY-MM-DD'),
        ...f
    }
    const { loading, error, data, subscribeToMore = () => { } } = useQuery(GET_DASHBOARD, {
        variables: {
            filter
        },
        fetchPolicy: 'network-only'
    });

    useEffect(() => {
        subscribeToMore && subscribeToMore({
            document: SUBSCRIPTION_DASHBOARD,
            variables: { filter },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData?.data) return prev;
                const { dashboardOrder } = subscriptionData?.data;
                return {
                    getDashboardOrder: dashboardOrder
                };
            }
        })
    }, [])

    if (loading) return (
        <Col lg="12" style={{
            opacity: 0.7,
            cursor: 'not-allowed',
            pointerEvents: 'none',
            height: 700
        }}>
            <Card lassName="mini-stats-wid" style={{ height: 700 }}>
                <CardBody>
                    <CardTitle> Loading ...</CardTitle>
                    <hr className="mb-4" />
                </CardBody>
            </Card>
        </Col>
    )

    if (error) return (
        <Col lg="12" style={{
            opacity: 0.7,
            cursor: 'not-allowed',
            pointerEvents: 'none',
            height: 700
        }}>
            <Card lassName="mini-stats-wid" style={{ height: 700 }}>
                <CardBody>
                    <CardTitle> Have Problems ...</CardTitle>
                    <hr className="mb-4" />
                </CardBody>
            </Card>
        </Col>
    )

    const { getDashboardOrder } = data;
    return (
        <React.Fragment>
            {/* Reports Render */}
            {getDashboardOrder.map((report, key) => (
                <Col lg="12" key={"_col_" + key}>
                    <Card className="mini-stats-wid" style={{ height: 130 }}>
                        <CardBody>
                            <Media>
                                <Media body>
                                    <p className="text-muted fw-medium">
                                        {report.title}
                                    </p>
                                    <h3 className="mb-0">{report.description}</h3>
                                </Media>
                                <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon" style={{ width: '4rem', height: '4rem' }}>
                                    <span className="avatar-title rounded-circle bg-primary">
                                        <i
                                            className={
                                                "bx " + report.iconClass + " font-size-32"
                                            }
                                            style={{ fontSize: 30 }}
                                        ></i>
                                    </span>
                                </div>
                            </Media>
                        </CardBody>
                    </Card>
                </Col>
            ))}
        </React.Fragment>
    )
}

export default Order;