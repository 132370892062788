import { del, get, post, put, stringify } from "../api_helper"

const getAllowances = data => {
    const query = `
        query getAllowances {
            getAllAllowances{
                from_month
                to_month
                amount
                is_now
                is_active
              }
        }
    `
    return post({ query })
}

const postAllowanceEdit = data => {
    const query = `
        mutation postAllowanceEdit {
            updateAllowances(input : ${stringify(data?.data).replace(/"/g, "")}) {
             id
            }
        }
    `
    return post({ query })
}


export {
    getAllowances,
    postAllowanceEdit
}
