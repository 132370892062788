import { takeEvery, fork, put, all, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { CREATE_OPSCOST, GET_OPSCOST, EDIT_OPSCOST, DELETE_OPSCOST } from "./actionTypes"
import {
  opscostCreateSuccess,
  opscostCreateError,
  opscostGetSuccess,
  opscostGetError,
  opscostEditError,
  opscostEditSuccess,
  opscostDeleteError,
  opscostDeleteSuccess
} from "./actions"

import {
  postOpsCostCreate,
  getOpsCost,
  postOpsCostEdit,
  postOpsCostDelete,
} from "../../../helpers/backendAPI/operating_costs"
import { Notify } from "../../../components/Common"
import moment from 'moment'

function* opscostGet({ payload: { opscost } }) {
  try {
    const response = yield call(getOpsCost, opscost?.id)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(opscostGetError(response?.errors[0]?.message))
    } else {
      yield put(
        opscostGetSuccess(response?.data?.getSingleOpsCost)
      )
    }
  } catch (error) {
    Notify('error', error?.message)
    yield put(opscostGetError(error?.message))
  }
}

function* opscostCreate({ payload: { opscost: { date, ...o }, setSubmitting, history } }) {
  try {
    const response = yield call(postOpsCostCreate, {
      date: moment(date).format(),
      ...o
    })
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(opscostCreateError(response?.errors[0]?.message))
    } else {
      Notify('success', `Biaya Operasional baru berhasil ditambahkan`)
      yield put(
        opscostCreateSuccess('Biaya Operasional baru berhasil ditambahkan')
      )
      history?.tog_create();
    }
    setSubmitting(false)
  } catch (error) {
    Notify('error', error?.message)
    setSubmitting(false)
    yield put(opscostCreateError(error?.message))
  }
}

function* opscostEdit({ payload: { opscost: { date, ...o }, setSubmitting, history } }) {
  try {
    const response = yield call(postOpsCostEdit, {
      date: moment(date).format(),
      ...o
    })
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(opscostEditError(response?.errors[0]?.message))
    } else {
      Notify('success', `Biaya Operasional berhasil diubah`)
      yield put(
        opscostEditSuccess('Biaya Operasional berhasil diubah')
      )
      history?.tog_edit();
    }
    setSubmitting(false)
  } catch (error) {
    Notify('error', error?.message)
    setSubmitting(false)
    yield put(opscostEditError(error?.message))
  }
}

function* opscostDelete({ payload: { opscost } }) {
  try {
    const response = yield call(postOpsCostDelete, opscost)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(opscostDeleteError(response?.errors[0]?.message))
    } else {
      Notify('success', `Biaya Operasional berhasil dihapus`)
      yield put(
        opscostDeleteSuccess('Biaya Operasional berhasil dihapus')
      )
    }
  } catch (error) {
    Notify('error', error?.message)
    yield put(opscostDeleteError(error?.message))
  }
}



export function* watchOpsCost() {
  yield takeEvery(GET_OPSCOST, opscostGet)
  yield takeEvery(CREATE_OPSCOST, opscostCreate)
  yield takeEvery(EDIT_OPSCOST, opscostEdit)
  yield takeEvery(DELETE_OPSCOST, opscostDelete)
}

function* opscostSaga() {
  yield all([fork(watchOpsCost)])
}

export default opscostSaga
