import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React from "react"
import { Row, Col, Alert, Card, CardBody, Container } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// action
import { userResetPassword } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"

import { Formik, Form } from "formik"
import * as Yup from "yup"
import { RowInput, TextField } from '../../components/Common'
import { useLocation } from "react-router-dom";

const ResetPassword = props => {
    const search = useLocation().search;
    const token = new URLSearchParams(search).get('token');
    const dispatch = useDispatch()
    const { resetError, resetSuccessMsg, apk_name } = useSelector(state => ({
        resetError: state.ResetPassword.resetError,
        resetSuccessMsg: state.ResetPassword.resetSuccessMsg,
        apk_name : state.Layout.apk_name,
    }))

    function handleValidSubmit(values, setSubmitting) {
        dispatch(userResetPassword(values, setSubmitting))
    }

    return (
        <React.Fragment>
            <MetaTags>
                <title>
                    Reset Password | {apk_name}
                </title>
            </MetaTags>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="fas fa-home h2" />
                </Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary bg-softbg-soft-primary">
                                    <Row>
                                        <Col xs={7}>
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">Welcome Back !</h5>
                                                <p>Sign in to continue to Skote.</p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                            <img src={profile} alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <Link to="/">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img
                                                        src={logo}
                                                        alt=""
                                                        className="rounded-circle"
                                                        height="34"
                                                    />
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="p-2">
                                        {resetError ? (
                                            <Alert color="danger">
                                                {resetError}
                                            </Alert>
                                        ) : null}
                                        {resetSuccessMsg ? (
                                            <Alert color="success">
                                                {resetSuccessMsg}
                                            </Alert>
                                        ) : null}

                                        <Formik
                                            initialValues={{
                                                token,
                                                new_password: '',
                                                verify_password: '',
                                            }}
                                            validationSchema={Yup.object().shape({
                                                new_password: Yup.string().required('Password Baru Tidak Boleh Kosong'),
                                                verify_password: Yup.string().required('Ulangi Password Baru Tidak Boleh Kosong')
                                                    .oneOf([Yup.ref('new_password'), null], 'Ulangi Password Baru Tidak Sama'),

                                            })}

                                            onSubmit={(values, { setSubmitting }) => handleValidSubmit(values, setSubmitting)}
                                            render={({ errors, touched, isSubmitting, }) => {
                                                return (
                                                    <Form>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <RowInput
                                                                    label="Password Baru"
                                                                    labelSize={12}
                                                                    lineHeight={1}
                                                                >
                                                                    <TextField
                                                                        name="new_password"
                                                                        type="password"
                                                                        errors={errors.new_password}
                                                                        touched={touched.new_password}
                                                                    />
                                                                </RowInput>
                                                                <RowInput
                                                                    label="Ulangi Password Baru"
                                                                    labelSize={12}
                                                                    lineHeight={1}
                                                                >
                                                                    <TextField
                                                                        name="verify_password"
                                                                        type="password"
                                                                        errors={errors.verify_password}
                                                                        touched={touched.verify_password}
                                                                    />
                                                                </RowInput>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mt-3">
                                                            <Col className="text-end">
                                                                <button
                                                                    className="btn btn-primary w-md "
                                                                    type="submit"
                                                                    disabled={isSubmitting}
                                                                >
                                                                    {isSubmitting ? "Loading ..." : "Reset"}
                                                                </button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                )
                                            }}
                                        />

                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>
                                    Kembali ke halaman {" "}
                                    <Link to="login" className="font-weight-medium text-primary">
                                        Login
                                    </Link>{" "}
                                </p>
                                <p>
                                    © {new Date().getFullYear()} {apk_name}. Crafted with{" "}
                                    <i className="mdi mdi-heart text-danger" /> by SIA-Soft
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

ResetPassword.propTypes = {
    history: PropTypes.object,
}

export default withRouter(ResetPassword)
