import React, { useState, useEffect } from "react"
import Offcanvas from 'react-bootstrap/Offcanvas'
import {
    Button,
    Row,
    Col,
} from 'reactstrap'
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import { getStoreSelect2, getUserSelect2, recapSaleAdminSetFilter } from "../../store/actions"
import { DateRange, RowInput, SelectAPI } from '../../components/Common'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

const Filter = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => !s);

    const dispatch = useDispatch()
    const { stores, users, filter, loadingFilter, current_user } = useSelector(state => ({
        stores: state.Stores.storeSelect2Success,
        users: state.Users.userSelect2Success,
        filter: state.RecapSaleAdmin.getFilter,
        loadingFilter: state.RecapSaleAdmin.loadingFilter,
        current_user: state.Login.current_user
    }))

    const { id_group = 0, id: id_user = 0, name: user_name = '', districtsGrupAccess=[]} = current_user

    const defaultUser = districtsGrupAccess.includes(id_group) ? [{ value: id_user, label: user_name }] : filter?.id_user || []
    const initialValues = {
        date_1: filter?.date_1 || moment().startOf('month')._d,
        date_2: filter?.date_2 || moment().endOf('month')._d,
        id_store: filter?.id_store || [],
        id_user: defaultUser,
        date_option : filter?.date_option || 3
    }

    useEffect(() => dispatch(recapSaleAdminSetFilter(initialValues)), [])

    const [loading, setLoading] = useState(false)

    const handleReset = (reset) => {
        setLoading(true)
        reset()
        setTimeout(() => {
            setLoading(false)
            dispatch(recapSaleAdminSetFilter({
                date_1: moment().startOf('month')._d,
                date_2: moment().endOf('month')._d,
                id_store: [],
                id_user: defaultUser,
                date_option : 3
            }))
        }, 10);
    }

    const setDate = (start,end, date_option, setFieldValue) =>{
        setFieldValue('date_1', start);
        setFieldValue('date_2', end);
        setFieldValue('date_option', date_option)
    }
    return (
        <Row className="mb-3">
            <Col lg={12} className="text-end">
                <button type="button" className="btn btn-outline-primary btn-lg" onClick={toggleShow}>
                    <i className='bx bx-filter font-size-16 align-middle me-2' ></i>
                    {initialValues.date_1 && initialValues.date_2 ? `${moment(initialValues.date_1).format('DD MMM YYYY')} s/d ${moment(initialValues.date_2).format('DD MMM YYYY')}` : 'Sortir'}
                </button>
                <Offcanvas show={show} onHide={handleClose}
                    name='Enable both scrolling & backdrop'
                    scroll={true}
                    backdrop={true}
                    placement="end"
                    style={{ width: '545px' }}
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Sortir</Offcanvas.Title>
                    </Offcanvas.Header>
                    {!loadingFilter ? <Offcanvas.Body>
                        <Formik
                            enableReinitialize
                            initialValues={initialValues}
                            validationSchema={Yup.object().shape({
                                date_1: Yup.string().required('Periode Tgl 1 tidak boleh kosong'),
                                date_2: Yup.string().required('Periode Tgl 2 tidak boleh kosong'),
                            })}

                            onSubmit={(values, { setSubmitting }) => dispatch(recapSaleAdminSetFilter(values))}

                            render={({ values, errors, touched, isSubmitting, setFieldValue, resetForm }) => {

                                const _handleSelect = (selectChoice, { name }) => setFieldValue(name, selectChoice);
                                return (
                                    <>
                                        <Form>
                                            <Row>
                                                <Col lg={12}>
                                                    <RowInput
                                                        label="Periode"
                                                        labelSize={2}
                                                        lineHeight={0}
                                                    >
                                                        : {values.date_1 && values.date_2 ? `${moment(values.date_1).format('DD MMM YYYY')} s/d ${moment(values.date_2).format('DD MMM YYYY')}` : ''}
                                                    </RowInput>

                                                    <RowInput
                                                        label=""
                                                        labelSize={0}
                                                        lineHeight={0}
                                                    >
                                                        <DateRange
                                                            name="date"
                                                            selected={values?.date_1}
                                                            onChange={(dates) => {
                                                                const [start, end] = dates;
                                                                setFieldValue('date_1', start);
                                                                setFieldValue('date_2', end);
                                                            }}
                                                            startDate={values?.date_1}
                                                            endDate={values?.date_2}
                                                        />

                                                    </RowInput>
                                                    <RowInput
                                                        label=""
                                                        labelSize={0}
                                                        lineHeight={3}
                                                    >
                                                        <div className="btn-group" role="group">
                                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio1" onClick={()=> setDate(new Date(), new Date(), 1, setFieldValue)} autoComplete="off" defaultChecked={values?.date_option === 1} />
                                                            <label className="btn btn-outline-secondary" htmlFor="btnradio1">Hari Ini</label>

                                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio2" onClick={()=> setDate(moment().subtract(1, 'days')._d, moment().subtract(1, 'days')._d, 2, setFieldValue)} autoComplete="off" defaultChecked={values?.date_option === 2} />
                                                            <label className="btn btn-outline-secondary" htmlFor="btnradio2">Kemarin</label>

                                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio3" onClick={()=> setDate(moment().startOf('month')._d, moment().endOf('month')._d, 3, setFieldValue)} autoComplete="off" defaultChecked={values?.date_option === 3} />
                                                            <label className="btn btn-outline-secondary" htmlFor="btnradio3">Bulan Ini</label>
                                                            
                                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio4"  onClick={()=> setDate(moment().subtract(1,'months').startOf('month')._d, moment().subtract(1,'months').endOf('month')._d, 4, setFieldValue)} autoComplete="off" defaultChecked={values?.date_option === 4} />
                                                            <label className="btn btn-outline-secondary" htmlFor="btnradio4">Bulan Lalu</label>
                                                            
                                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio5" onClick={()=> setDate(moment().subtract(7, 'days')._d, new Date(), 5, setFieldValue)} autoComplete="off" defaultChecked={values?.date_option === 5} />
                                                            <label className="btn btn-outline-secondary" htmlFor="btnradio5">7 Hari Lalu</label> 
                                                            
                                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio6" onClick={()=> setDate(moment().subtract(30, 'days')._d, new Date(), 6, setFieldValue)}  autoComplete="off" defaultChecked={values?.date_option === 6} />
                                                            <label className="btn btn-outline-secondary" htmlFor="btnradio6">30 Hari Lalu</label>  
                                                            
                                                        </div>

                                                    </RowInput>
                                                    <RowInput
                                                        label="Toko"
                                                        labelSize={2}
                                                        size={8}
                                                    >
                                                        {!loading ? <SelectAPI
                                                            name="id_store"
                                                            errors={errors.id_store}
                                                            touched={touched.id_store}
                                                            disabled={false}
                                                            select_ref={false}
                                                            placeholder={`Semua Toko`}
                                                            options={stores}
                                                            defaultValue={filter?.id_store}
                                                            target={true}
                                                            portal={document.querySelector("offcanvas-body")}
                                                            onMenuOpen={() => dispatch(getStoreSelect2({ name: '' }))}
                                                            isMulti={true}
                                                            nameQuery={`getAllStore`}
                                                            handleSelect={_handleSelect}
                                                        /> : ''}
                                                    </RowInput>
                                                    <RowInput
                                                        label="Karyawan"
                                                        labelSize={2}
                                                        size={8}
                                                    >
                                                        {!loading ? <SelectAPI
                                                            name="id_user"
                                                            errors={errors.id_user}
                                                            touched={touched.id_user}
                                                            disabled={districtsGrupAccess.includes(id_group)}
                                                            select_ref={false}
                                                            placeholder={`Semua Karyawan`}
                                                            options={users}
                                                            defaultValue={defaultUser}
                                                            target={true}
                                                            portal={document.querySelector("offcanvas-body")}
                                                            onMenuOpen={() => dispatch(getUserSelect2({ name: '' }))}
                                                            isMulti={true}
                                                            nameQuery={`getAllUser`}
                                                            handleSelect={_handleSelect}
                                                        /> : ''}
                                                    </RowInput>

                                                    <hr></hr>
                                                    <Row className="justify-content-end">
                                                        <Col lg="12">
                                                            <Button type="submit" color="primary" disabled={isSubmitting}>
                                                                <i className='bx bx-filter font-size-16 align-middle me-2' ></i>
                                                                {isSubmitting ? 'Loading...' : 'Terapkan'}
                                                            </Button>
                                                            &nbsp;
                                                            <Button type="button" color="secondary" onClick={() => handleReset(resetForm)}>
                                                                <i className='bx bx-reset font-size-16 align-middle me-2' ></i>
                                                                {'Reset'}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </>
                                )
                            }}
                        />
                    </Offcanvas.Body> : ''}
                </Offcanvas>
            </Col>
        </Row>
    )
}

export default Filter