const Statis = {
    status : [
        {value : 1, label : "BELUM"},
        {value : 2, label : "DIKIRIM"},
        {value : 3, label : "REFUND "},
        {value : 4, label : "CANCLE "},
    ],
    expeditions : [
        {value : 1, label : "JNE"},
        {value : 2, label : "SICEPAT"},
        {value : 3, label : "J&T"},
        {value : 4, label : "ANTERAJA"},
        {value : 5, label : "GOJEK"},
        {value : 6, label : "POS"},
        {value : 7, label : "WAHANA"},
        {value : 8, label : "PENGIRIMAN PRIBADI"},
    ],
    suppliers : [
        {value : 1, label : "SHOPEE"},
        {value : 2, label : "TOKOPEDIA"},
        {value : 3, label : "BLIBLI"},
        {value : 4, label : "LAZADA"},
    ]
}
export default Statis