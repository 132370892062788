import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { Breadcrumb, Loader } from '../../components/Common'
import { useDispatch, useSelector } from "react-redux"
import { incomeStatementGet } from "../../store/actions"
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Table,
    Spinner
} from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import Filter from './filter'

const IncomeStatements = props => {
    const dispatch = useDispatch()

    const { apk_name, filter, data } = useSelector(state => ({
        apk_name: state.Layout.apk_name,
        filter: state.IncomeStatements.getFilter,
        data: state.IncomeStatements.getSuccess,
    }))
    const [page, setPage] = useState(1)
    useEffect(() => setPage(1), [filter])
    useEffect(() => page < 4 && dispatch(incomeStatementGet({ page, year: filter?.year?.getFullYear() })), [page, filter])

    const fetchMoreData = () => setPage(prevState => prevState + 1)

    const nbsp = <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    const description = [
        { name: 'Total Omzet', desc: ': Total Omzet Dalam 1 Bulan' },
        { name: 'Total Belanja Supplier', desc: ': Total Belanja Supplier Dalam 1 Bulan' },
        { name: 'Profit Kotor', desc: ': Total Omzet 1 Bulan - Total Belanja Supplier 1 Bulan' },
        { name: 'Biaya Gaji & Bonus', desc: ': Dari Menu Perhitungan Gaji' },
        { name: 'Biaya Operasional', desc: ': Dari Menu Setting Aplikasi' },
        { name: 'Total Biaya Operasional', desc: ': Total Gaji + Biaya Operasional 1 Bulan' },
        { name: 'Profit Bersih', desc: ': Profit Kotor - Total Biaya Operasional 1 Bulan' },
    ]

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Laporan Laba Rugi | {apk_name}</title>
                </MetaTags>

                <Container fluid={true}>
                    <Breadcrumb title="Laporan Laba Rugi" breadcrumbItems={[
                        { title: 'Dashboard', link: '/dashboard' },
                        { title: 'Laporan Laba Rugi', link: '/laporan_laba_rugi' },
                    ]} />
                    <Row>
                        <Col lg={12}>
                            <Filter />
                            <InfiniteScroll
                                dataLength={data?.length}
                                next={fetchMoreData}
                                style={{ overflow: 'none !important' }}
                                hasMore={page < 4}
                                loader={<div className="text-center mb-3"><Spinner className="ms-2" color="primary" />&nbsp;&nbsp;<i>Loading...</i></div>}
                            >
                                <Row className="gridRow">
                                    {(data && data?.length) ? data.map(({ data }, i) =>
                                        <Col lg={4} key={i}>
                                            <Card>
                                                <CardBody>
                                                    <CardTitle className="mb-4">{data?.name}</CardTitle>
                                                    <hr></hr>
                                                    <div className="table-responsive">
                                                        <Table className="table align-middle table-nowrap compact">
                                                            <tbody>
                                                                {
                                                                    data?.data?.map(({ name, amount, level }, i) =>
                                                                    (
                                                                        <tr key={i}>
                                                                            <td style={{ width: "20px" }}>
                                                                                <div className="font-size-11 text-primary">
                                                                                    <i className='bx bxs-droplet' />
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <h5 className="font-size-14 mb-0">{level > 1 && new Array(level).fill(nbsp)}{name}</h5>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="text-end">
                                                                                    <h5 className="font-size-14 mb-0">{amount.toLocaleString('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 })}</h5>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ) : ''}
                                    {
                                        (data && data?.length === 13) ? <Col lg={8}>
                                            <Card>
                                                <CardBody>
                                                    <CardTitle className="mb-4">Keterangan</CardTitle>
                                                    <hr></hr>
                                                    <div className="table-responsive">
                                                        <Table className="table align-middle table-nowrap compact">
                                                            <tbody>
                                                                {description.map(({ name, desc }, i) =>
                                                                (
                                                                    <tr key={i}>
                                                                        <td style={{ width: "20px" }}>
                                                                            <div className="font-size-11 text-primary">
                                                                                <i className='bx bxs-droplet' />
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div style={{ width: "10%" }}>
                                                                                <h5 className="font-size-14 mb-0">{name}</h5>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="">
                                                                                <h5 className="font-size-14 mb-0">{desc}</h5>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col> : ''
                                    }
                                </Row>
                            </InfiniteScroll>


                        </Col>
                    </Row>
                </Container>

            </div>
        </React.Fragment>
    );
};


export default IncomeStatements;
