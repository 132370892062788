import {
  SET_RECAPSALEADMIN_FILTER,
  GET_RECAPSALEADMIN_FILTER
} from "./actionTypes"

export const recapSaleAdminSetFilter = message => {
  return {
    type: SET_RECAPSALEADMIN_FILTER,
    payload: message,
  }
}

export const recapSaleAdminGetFilter = message => {
  return {
    type: GET_RECAPSALEADMIN_FILTER,
    payload: message,
  }
}

