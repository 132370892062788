import {
  SET_RECAPSALEADMIN_FILTER,
  GET_RECAPSALEADMIN_FILTER
} from "./actionTypes"

const initialState = {
  getFilter: null,
  loadingFilter: false,
}

const recapSaleAdmin = (state = initialState, action) => {
  switch (action.type) {
    case SET_RECAPSALEADMIN_FILTER:
      state = {
        ...state,
        getFilter: null,
        loadingFilter: true,
      }
      break
    case GET_RECAPSALEADMIN_FILTER:
      state = {
        ...state,
        getFilter: action.payload,
        loadingFilter: false
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default recapSaleAdmin
