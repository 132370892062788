import React, { useState } from "react";
import MetaTags from 'react-meta-tags';
import { DataGrid, Breadcrumb, Access, Notify } from '../../components/Common'
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Button
} from "reactstrap";
import { useSelector } from "react-redux"
import { getExcel } from "../../helpers/api_helper"
import moment from "moment";
import Filter from './filter'
import printJS from 'print-js'
import { print } from "../../helpers/api_helper";

const Salaries = props => {
    const { filter, loading2, apk_name } = useSelector(state => ({
        filter: state.Salaries.getFilter,
        loading2: state.Salaries.loadingGetFilter,
        apk_name: state.Layout.apk_name,
    }))
    const [isPrinting, setIsPrinting] = useState(false)

    const tableColumns = [
        {
            name: 'Periode',
            selector: 'period',
            sortable: true,
        },
        {
            name: 'Karyawan',
            selector: 'user_name',
            sortable: true,
        },
        {
            name: 'Jabatan',
            selector: 'group_name',
            sortable: true,
        },
        {
            name: 'Lama Bekerja',
            selector: 'work_day',
            sortable: true,
        },
        {
            name: 'Gaji Pokok',
            selector: 'salary',
            sortable: true,
        },
        {
            name: 'Tunjangan',
            selector: 'allowance',
            sortable: true,
        },
        {
            name: 'Bonus',
            selector: 'bonus',
            sortable: true,
        },
        {
            name: 'Sanksi Potongan',
            selector: 'salary_cut',
            sortable: true,
        },
        {
            name: 'Gaji Total',
            selector: 'salary_total',
            sortable: true,
        },
        {
            name: '',
            selector: 'id',
            sortable: true,
            right: true
        },

    ]

    const dataRender = ({ id, period: p, work_day, salary: s, allowance: a, bonus: b, salary_cut: c, salary_total: t, ...e }) => {
        const period = moment(p).format('DD/MM/YYYY')
        const salary = s.toLocaleString('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 })
        const allowance = a.toLocaleString('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 })
        const bonus = b.toLocaleString('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 })
        const salary_cut = c.toLocaleString('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 })
        const salary_total = t.toLocaleString('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 })
        return {
            period,
            work_day: `${work_day} Bulan`,
            salary,
            allowance,
            bonus,
            salary_cut,
            salary_total,
            id: <>
                <Access id={`46`}>{isPrinting ? 'Loading ...' : <i className="mdi mdi-24px mdi-receipt" onClick={() => handlePrint({
                    apk_name,
                    period,
                    salary,
                    allowance,
                    bonus,
                    salary_cut,
                    salary_total,
                    t,
                    ...e
                })}></i>}</Access>
            </>,
            ...e
        }
    }

    const handlePrint = (data) => {
        setIsPrinting(true)
        print('/api/salary_slip', data).then(res => {
            printJS({ printable: res, type: 'raw-html' })
            setIsPrinting(false)
        }).catch(err => {
            setIsPrinting(false)
            Notify('error', err?.message)
        })
    }

    const handleQuery = (query, values) => {
        Object.keys(values).forEach(e => {
            if (e === 'id_user') {
                query += `${e}=${values[e] && values[e].length ? values[e].map(({ value }) => value) : ''}&`
                query += `user_name=${values[e] && values[e].length ? values[e].map(({ label }) => label) : 'Semua Karyawan'}&`
            } else {
                query += `${e}=${(e === 'date') ? values[e] ? moment(values[e]).format('YYYY-MM') : '' : values[e]}&`
            }
        });
        query = query.slice(0, -1)
        return query
    }

    const handleExport = () => {
        const query = handleQuery(`?`, filter)
        getExcel(`/api/export_salaries${query}`, 'Laporan Gaji')
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Laporan Gaji | {apk_name}</title>
                </MetaTags>
                <Container fluid={true} >
                    <Breadcrumb title="Laporan Gaji" breadcrumbItems={[
                        { title: 'Dashboard', link: '/dashboard' },
                        { title: 'Laporan Gaji', link: '/laporan_gaji' }
                    ]} />
                    <Row>
                        <Col lg={12}>
                            <Filter />
                            <Card>
                                <CardBody>
                                    <CardTitle className="text-end">
                                        <Access id={`47`}>
                                            <Button color="success" className="mr-1" onClick={handleExport}><i className="bx bx-file font-size-16 align-middle me-2"></i>Download Excel</Button>&nbsp;&nbsp;
                                        </Access>
                                    </CardTitle>
                                    {!loading2 && filter && <DataGrid
                                        dataRender={dataRender}
                                        title={'Data Gaji'}
                                        nameQuery={`getIndexEmployeePeriod`}
                                        defSort={['period', 'asc']}
                                        filterAdd={{
                                            date: moment(filter?.date).format('YYYY-MM-DD'),
                                            id_user: filter?.id_user
                                        }}
                                        variablefilter={"inputPaginationEmployeePeriod!"}
                                        tableColumns={tableColumns} />}
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment >
    );
};

export default Salaries;
