import React from "react"
import { Container, Row, Col } from "reactstrap"
import { useSelector } from "react-redux"

const Footer = () => {
  const { apk_name } = useSelector(state => ({
    apk_name: state.Layout.apk_name,
  }))

  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>© {new Date().getFullYear()} {apk_name}. Crafted with {" "} <i className="mdi mdi-heart text-danger" /> by SIA-Soft</Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                <small>V 22.06.08-01</small>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
