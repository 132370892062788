import { takeEvery, fork, put, all, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { GET_ALLOWANCES, EDIT_ALLOWANCES } from "./actionTypes"
import {
  allowancesEditError,
  allowancesEditSuccess,
  allowancesGetError,
  allowancesGetSuccess
} from "./actions"

import {
  getAllowances,
  postAllowanceEdit
} from "../../../helpers/backendAPI/allowances"

import { Notify } from "../../../components/Common"

function* allowancesGet({ payload: { allowances } }) {
  try {
    const response = yield call(getAllowances, allowances)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(allowancesGetError(response?.errors[0]?.message))
    } else {
      yield put(
        allowancesGetSuccess(response?.data?.getAllAllowances)
      )
    }
  } catch (error) {
    Notify('error', error?.message)
    yield put(allowancesGetError(error?.message))
  }
}


function* allowancesEdit({ payload: { allowances, setSubmitting } }) {
  try {
    const response = yield call(postAllowanceEdit, allowances)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(allowancesEditError(response?.errors[0]?.message))
    } else {
      Notify('success', `Ketentuan Tunjangan berhasil diubah`)
      yield put(
        allowancesEditSuccess(`Ketentuan Tunjangan berhasil diubah`)
      )
    }
    setSubmitting(false)
  } catch (error) {
    Notify('error', error?.message)
    setSubmitting(false)
    yield put(allowancesEditError(error?.message))
  }
}

export function* watchAllowances() {
  yield takeEvery(EDIT_ALLOWANCES, allowancesEdit)
  yield takeEvery(GET_ALLOWANCES, allowancesGet)
}

function* allowancesSaga() {
  yield all([fork(watchAllowances)])
}

export default allowancesSaga
