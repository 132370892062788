import { takeEvery, fork, put, all, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { CREATE_ATTENDANCE, GET_ATTENDANCE, EDIT_ATTENDANCE, DELETE_ATTENDANCE, SET_ATTENDANCE_FILTER } from "./actionTypes"
import {
  attendanceGetSuccess,
  attendanceGetError,
  attendanceCreateSuccess,
  attendanceCreateError,
  attendanceEditError,
  attendanceEditSuccess,
  attendanceDeleteError,
  attendanceDeleteSuccess,
  attendanceGetFilter
} from "./actions"

import {
  createAttendance,
  getAttendance,
  editAttendance,
  deleteAttendance
} from "../../helpers/backendAPI/attendances"

import { Notify } from "../../components/Common"

function* attendanceGet({ payload: { attendance } }) {
  try {
    const response = yield call(getAttendance, attendance)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(attendanceGetError(response?.errors[0]?.message))
    } else {
      yield put(
        attendanceGetSuccess(response?.data?.getSingleAttendance)
      )
    }
  } catch (error) {
    Notify('error', error?.message)
    yield put(attendanceGetError(error?.message))
  }
}

function* attendanceCreate({ payload: { attendance, setSubmitting, history } }) {
  try {
    const response = yield call(createAttendance, attendance)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(attendanceCreateError(response?.errors[0]?.message))
    } else {
      Notify('success', `Absensi baru berhasil ditambahkan`)
      yield put(
        attendanceCreateSuccess('Absensi baru berhasil ditambahkan')
      )
      history.push('/absensi')
    }
    setSubmitting(false)
  } catch (error) {
    Notify('error', error?.message)
    setSubmitting(false)
    yield put(attendanceCreateError(error?.message))
  }
}

function* attendanceEdit({ payload: { attendance, setSubmitting, history } }) {
  try {
    const response = yield call(editAttendance, attendance)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(attendanceEditError(response?.errors[0]?.message))
    } else {
      Notify('success', `Absensi berhasil diubah`)
      yield put(
        attendanceEditSuccess('Absensi berhasil diubah')
      )
      history.push('/absensi')
    }
    setSubmitting(false)
  } catch (error) {
    Notify('error', error?.message)
    setSubmitting(false)
    yield put(attendanceEditError(error?.message))
  }
}

function* attendanceDelete({ payload: { attendance } }) {
  try {
    const response = yield call(deleteAttendance, attendance)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(attendanceDeleteError(response?.errors[0]?.message))
    } else {
      Notify('success', `Absensi berhasil dihapus`)
      yield put(
        attendanceDeleteSuccess('Absensi berhasil dihapus')
      )
    }
  } catch (error) {
    Notify('error', error?.message)
    yield put(attendanceDeleteError(error?.message))
  }
}

function* attendanceSetFilter({ payload }) {
  yield put(attendanceGetFilter({ ...payload }))
}

export function* watchAttendance() {
  yield takeEvery(GET_ATTENDANCE, attendanceGet)
  yield takeEvery(CREATE_ATTENDANCE, attendanceCreate)
  yield takeEvery(EDIT_ATTENDANCE, attendanceEdit)
  yield takeEvery(DELETE_ATTENDANCE, attendanceDelete)
  yield takeEvery(SET_ATTENDANCE_FILTER, attendanceSetFilter)
}

function* attendanceSaga() {
  yield all([fork(watchAttendance)])
}

export default attendanceSaga
