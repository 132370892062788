import {
  EDIT_BASE,
  EDIT_BASE_SUCCESS,
  EDIT_BASE_ERROR,
} from "./actionTypes"

const initialState = {
  baseEditError: null,
  baseEditSuccess: null,
  baseEditLoading: false,
}

const createBase = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_BASE:
      state = {
        ...state,
        baseEditError: null,
        baseEditSuccess: null,
        baseEditLoading: true,
      }
      break
    case EDIT_BASE_SUCCESS:
      state = {
        ...state,
        baseEditSuccess: action.payload,
        baseEditLoading: false
      }
      break
    case EDIT_BASE_ERROR:
      state = {
        ...state,
        baseEditError: action.payload,
        baseEditLoading: false
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default createBase
