import React, {Fragment, useState} from 'react'
import CryptoJS from 'crypto-js';
import _ from "lodash";
const KEY= '@Hamaz21_!siaSOFT'

const ValidPass=(text) => {
    const bytes  = CryptoJS.AES.decrypt(text, KEY);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
 }

const AccessChild = (props) => {
    const { access : ability} = JSON.parse(localStorage.getItem('authUser'))

    const { id, children, com_else } = props
    if (ability) {
        const bisaakses = ability.find(e => ValidPass(e.id_list) === id)
        return (
            <Fragment>
                {bisaakses ? children : com_else ? com_else : null}
            </Fragment>
        )
    } else {
        return null
    }
}

function compare({ is_notmemo, ...prevProps }, { is_notmemo :n, ...nextProps }) {
    if(is_notmemo && n ){
      return false
    }
    return _.isEqual(_.omit({ ...prevProps }, _.functions({ ...prevProps })), _.omit({ ...nextProps }, _.functions({ ...nextProps })))
  }
  
const Access = React.memo(AccessChild, compare);
  

export { Access }