import { del, get, post, put, stringify} from "../api_helper"

const postOpsCostCreate = data => {
    const query = `
        mutation createOpsCost {
            createOpsCost(input : ${stringify(data)}) {
             id
            }
        }
    `
    return post({ query })
}

const getOpsCost = data => {
    const query = `
        query getSingleOpsCost {
            getSingleOpsCost(id : ${data}) {
                name
                amount
                date
            }
        }
    `
    return post({ query })
}

const postOpsCostEdit = data => {
    const query = `
        mutation updateOpsCost {
            updateOpsCost(input : ${stringify(data)}) {
             id
            }
        }
    `
    return post({ query })
}


const postOpsCostDelete = data => {
    const query = `
        mutation daleteOpsCost {
            daleteOpsCost(input : ${stringify(data)}) {
             id
            }
        }
    `
    return post({ query })
}



export {
    postOpsCostCreate,
    getOpsCost,
    postOpsCostEdit,
    postOpsCostDelete,
}
