import React, { useEffect } from "react";
import { Nav, NavLink, NavItem, TabContent, TabPane, Row, Col } from "reactstrap";
import {
    NavLink as RouterNavLink,
    Route,
    Switch,
    Redirect,
    useLocation,
} from "react-router-dom";
import startCase from "lodash.startcase";
import { Loader } from "../../components/Common";
import { userSetTab } from "../../store/actions";
import { useDispatch } from "react-redux"

const components = {
    info: React.lazy(() => import("./info")),
    log_aktivitas: React.lazy(() => import("./log_activity")),
};

const TabNav = ({ id }) => {
    const dispatch = useDispatch()
    const { pathname } = useLocation();
    const getRoute = (route) => `/data_pegawai/detail/${id}/${route}`;

    const renderNavItems = () =>
        Object.keys(components).map(route => (
            <NavItem key={`${route}-nav-item`}>
                <NavLink className="mb-3 text-start"  style={{ cursor: "pointer" }} tag={RouterNavLink} to={getRoute(route)} exact>
                    {startCase(route)}
                </NavLink>
            </NavItem>
        ));

    const renderRoutes = () =>
        Object.entries(components).map(([route, Component]) => (
            <Route key={`${route}-route`} path={getRoute(route)}>
                <TabPane tabId={getRoute(route)}>
                    <Component id={id} />
                </TabPane>
            </Route>
        ));

    useEffect(() => {
        const path = pathname.split("/")
        const name = path.length === 5 ? path[4]?.replace("_", " ") : ''
        dispatch(userSetTab({ name, path: pathname }))
    }, [pathname])

    return (
        <>
            <Row>
                <Col xl="2">
                    <Nav className="flex-column vertical-icon">{renderNavItems()}</Nav>
                </Col>
                <Col xl="10">
                    <TabContent activeTab={pathname}>
                        <React.Suspense fallback={<Loader />}>
                            <Switch>
                                {renderRoutes()}
                                <Redirect from="/" to={`/data_pegawai/detail/${id}/info`} />
                            </Switch>
                        </React.Suspense>
                    </TabContent>
                </Col>
            </Row>
        </>
    );
};

export default TabNav;
