import { del, get, post, put, stringify } from "../api_helper"

const postBaseEdit = data => {
    const query = `
        mutation createUserGroups {
            createUserGroups(input : ${stringify(data).replace(/"/g, "")}) {
             id
            }
        }
    `
    return post({ query })
}


export {
    postBaseEdit
}
