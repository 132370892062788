import {
  CREATE_WORK_GUIDE,
  CREATE_WORK_GUIDE_SUCCESS,
  CREATE_WORK_GUIDE_ERROR,
  GET_WORK_GUIDE,
  GET_WORK_GUIDE_SUCCESS,
  GET_WORK_GUIDE_ERROR,
  EDIT_WORK_GUIDE,
  EDIT_WORK_GUIDE_SUCCESS,
  EDIT_WORK_GUIDE_ERROR,
  DELETE_WORK_GUIDE,
  DELETE_WORK_GUIDE_SUCCESS,
  DELETE_WORK_GUIDE_ERROR
} from "./actionTypes"

const initialState = {
  createSuccess: null,
  createError: null,
  createLoading: false,
  getError: null,
  getSuccess: null,
  getLoading: false, 
  editError: null,
  editSuccess: null,
  editLoading: false, 
  deleteError: null,
  deleteSuccess: null,
  deleteLoading: false
}

const createGuide = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_WORK_GUIDE:
      state = {
        ...state,
        createSuccess: null,
        createError: null,
        createLoading: true
      }
      break
    case CREATE_WORK_GUIDE_SUCCESS:
      state = {
        ...state,
        createSuccess: action.payload,
        createLoading: false
      }
      break
    case CREATE_WORK_GUIDE_ERROR:
      state = { 
        ...state, 
        createError: action.payload, 
        createLoading: false 
      }
      break

    case GET_WORK_GUIDE:
      state = {
        ...state,
        getError: null,
        getSuccess: null,
        getLoading: true,
      }
      break
    case GET_WORK_GUIDE_SUCCESS:
      state = {
        ...state,
        getSuccess: action.payload,
        getLoading: false
      }
      break
    case GET_WORK_GUIDE_ERROR:
      state = {
        ...state,
        getError: action.payload,
        getLoading: false
      }
      break 
      
      case EDIT_WORK_GUIDE:
      state = {
        ...state,
        editError: null,
        editSuccess: null,
        editLoading: true,
      }
      break
    case EDIT_WORK_GUIDE_SUCCESS:
      state = {
        ...state,
        editSuccess: action.payload,
        editLoading: false
      }
      break
    case EDIT_WORK_GUIDE_ERROR:
      state = {
        ...state,
        editError: action.payload,
        editLoading: false
      }
      break   
      
      case DELETE_WORK_GUIDE:
      state = {
        ...state,
        deleteError: null,
        deleteSuccess: null,
        deleteLoading: true,
      }
      break
    case DELETE_WORK_GUIDE_SUCCESS:
      state = {
        ...state,
        deleteSuccess: action.payload,
        deleteLoading: false
      }
      break
    case DELETE_WORK_GUIDE_ERROR:
      state = {
        ...state,
        deleteError: action.payload,
        deleteLoading: false
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default createGuide
