import React from 'react'
import { ErrorMessage } from 'formik';
import AsyncSelect from "react-select/async";
import { Notify } from '../Common'
import _ from "lodash";
import { post } from "../../helpers/api_helper";

class SelectAPIChild extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    loadOptions = (inputValue) => {
        return new Promise(resolve => {
            const { typing = 2, addField=''} = this.props
            if (inputValue.length > typing) {
                const query = `
                        query getUserGroup {
                            ${this.props.nameQuery}(input : { name : "${inputValue}" }) {
                            label
                            value
                            ${addField}
                            }
                        }
                    `
                post({ query }).then(response => {
                    if (response?.errors?.length) {
                        Notify('error', response?.errors[0]?.message)
                        resolve(null)
                    } else {
                        resolve(response?.data?.[this.props.nameQuery])
                    }
                       
                }).catch(e => {
                    Notify('error', e?.message)
                    resolve(null)
                })


            } else {
                resolve(this.props.options)
            }

        })
    };


    render() {
        const { errors, touched, name, select_ref, handleSelect, view, options = [], portal= document.body,  defaultValue, disabled, isMulti, placeholder, position = 'absolute', onMenuOpen, target = false, isClearable = false } = this.props

        const colourStyles = {
            control: styles => ({
                ...styles,
                ...((errors && touched) && { border: 'red solid 1px' }),
            }),
            menu: (styles, { data }) => {
                return {
                    ...styles,
                    position,
                };
            },
            menuPortal: () => ({ zIndex: "1000", width: "240px", position: "absolute", top: "214px", backgroundColor: "white", left: "17px", boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" })
        }

        return (
            <React.Fragment>

                <AsyncSelect
                    {...{
                        ...(select_ref && { ref: select_ref }),
                        ...(isMulti && { isMulti }),
                        name,
                        isClearable,
                        isDisabled: disabled ? true : false,
                        defaultOptions: view ? options.slice(0, view) : options,
                        ...(placeholder && { placeholder }),
                        styles: colourStyles,
                        className: `basic-multi-select ${errors && touched ? 'is-invalid' : ''}`,
                        classNamePrefix: 'select',
                        loadOptions: (inputValue) => this.loadOptions(inputValue),
                        ...(onMenuOpen && { onMenuOpen }),
                        defaultValue,
                        onChange: (selectChoice, { name }) => { handleSelect(selectChoice, { name }) },
                        ...(target && { menuPortalTarget: portal }),
                        isOptionSelected: (option, selectValue) => selectValue.some(i => i === option)
                    }}
                />

                {(errors && touched) && <ErrorMessage name={name} component="div" style={{ color: 'red' }} className="invalid-feedback" />}
            </React.Fragment>
        )
    }
}

function compare({ is_notmemo, ...prevProps }, { is_notmemo: n, ...nextProps }) {
    if (is_notmemo && n) {
        return false
    }
    return _.isEqual(_.omit({ ...prevProps }, _.functions({ ...prevProps })), _.omit({ ...nextProps }, _.functions({ ...nextProps })))
}

const SelectAPI = React.memo(SelectAPIChild, compare);

export { SelectAPI }