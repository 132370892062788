import React, { useEffect } from "react";
import MetaTags from 'react-meta-tags';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { RowInput, DatePic, SelectAPI, Breadcrumb, Loader, FieldMask } from '../../components/Common'
import { useDispatch, useSelector } from "react-redux"
import { withdrawalEdit as withdrawalEditRedux, getStoreSelect2, withdrawalGet } from "../../store/actions"
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Button
} from "reactstrap";
import { useParams } from "react-router-dom";

const WithdrawalEdit = props => {
    const dispatch = useDispatch()

    const { id } = useParams()
    const { stores, withdrawal, apk_name} = useSelector(state => ({
        stores: state.Stores.storeSelect2Success,
        withdrawal: state.Withdrawals.getSuccess,
        apk_name: state.Layout.apk_name,
    }))

    useEffect(() => {
        dispatch(getStoreSelect2({ name: '' }))
        dispatch(withdrawalGet({ id: parseFloat(id) }))
    }, [])

    if (!stores || !withdrawal) return <Loader />
    const {
        id_store,
        store,
        date,
        amount
    } = withdrawal
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Edit Penarikan Dana | {apk_name}</title>
                </MetaTags>

                <Formik
                    initialValues={{
                        id: parseFloat(id),
                        id_store,
                        date: new Date(date),
                        amount
                    }}
                    validationSchema={Yup.object().shape({
                        id_store: Yup.number().typeError('Toko Harus diisi').required('Toko Harus diisi'),
                        amount: Yup.number().typeError('Jumlah Harus Angka').required('Jumlah Tidak Boleh Kosong'),
                    })}

                    onSubmit={(values, { setSubmitting }) => dispatch(withdrawalEditRedux(values, setSubmitting, props.history))}

                    render={({ values, errors, touched, isSubmitting, setFieldValue }) => {

                        const _handleSelect = (selectChoice, { name }) => setFieldValue(name, selectChoice.value);

                        const onChangePrice = (event) => {
                            const { name, value } = event.target
                            let newval = 0
                            if (value) newval = value.replace(/,/g, '')
                            setFieldValue(name, parseFloat(newval))
                        }

                        return (
                            <>
                                <Form>
                                    <Container fluid={true} style={{ minHeight: 500 }}>
                                        <Breadcrumb title="Edit Penarikan Dana" breadcrumbItems={[
                                            { title: 'Dashboard', link: '/dashboard' },
                                            { title: 'Laporan Penarikan Dana', link: '/laporan_tarik_dana' },
                                            { title: 'Edit', link: `/laporan_tarik_dana/edit/${id}` }
                                        ]} />
                                        <Row>
                                            <Col lg={7}>
                                                <Card>
                                                    <CardBody>
                                                        <CardTitle className="mb-4"></CardTitle>

                                                        <RowInput
                                                            label="Dari Toko"
                                                            mandatory={true}
                                                            labelSize={3}
                                                            size={8}
                                                        >
                                                            <SelectAPI
                                                                name="id_store"
                                                                errors={errors.id_store}
                                                                touched={touched.id_store}
                                                                disabled={false}
                                                                select_ref={false}
                                                                options={stores}
                                                                defaultValue={[{ value: values.id_store, label: store?.name }]}
                                                                nameQuery={`getAllStore`}
                                                                handleSelect={_handleSelect}
                                                            />
                                                        </RowInput>
                                                        <RowInput
                                                            label="Tanggal"
                                                            labelSize={3}
                                                            size={8}
                                                        >
                                                            <DatePic
                                                                name='date'
                                                                value={values.date}
                                                                showTime={false}
                                                                placeholderText={`dd/mm/yyyy`}
                                                                errors={errors.date}
                                                                touched={touched.date}
                                                                onChange={(date) => setFieldValue('date', date)}
                                                            />
                                                        </RowInput>
                                                        <RowInput
                                                            label="Jumlah"
                                                            labelSize={3}
                                                            size={8}
                                                            mandatory={true}
                                                        >
                                                            <FieldMask
                                                                name={`amount`}
                                                                type="text"
                                                                onChange={(e) => onChangePrice(e)}
                                                                errors={errors?.amount}
                                                                touched={touched?.amount}
                                                            />
                                                        </RowInput>

                                                        <hr></hr>
                                                        <Row className="justify-content-end">
                                                            <Col lg="12">
                                                                <Button type="submit" color="primary" disabled={isSubmitting}>
                                                                    <i className="bx bx-save font-size-16 align-middle me-2"></i>
                                                                    {isSubmitting ? 'Loading...' : 'Simpan'}
                                                                </Button>
                                                                &nbsp;
                                                                <Button type="button" color="secondary" onClick={() => { props.history.push('/laporan_tarik_dana') }}>
                                                                    <i className="bx  bx-left-arrow-circle font-size-16 align-middle me-2"></i>
                                                                    {'Kembali'}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Form>
                            </>
                        )
                    }}
                />
            </div>
        </React.Fragment>
    );
};



export default WithdrawalEdit;
