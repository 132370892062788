import {
  GET_PRESENCES,
  GET_PRESENCES_SUCCESS,
  GET_PRESENCES_ERROR,
  EDIT_PRESENCES,
  EDIT_PRESENCES_SUCCESS,
  EDIT_PRESENCES_ERROR,
} from "./actionTypes"

const initialState = {
  presencesGetError: null,
  presencesGetSuccess: null,
  presencesGetLoading: false,
  presencesEditError: null,
  presencesEditSuccess: null,
  presencesEditLoading: false,
}

const createPresences = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRESENCES:
      state = {
        ...state,
        presencesGetError: null,
        presencesGetSuccess: null,
        presencesGetLoading: true,
      }
      break
    case GET_PRESENCES_SUCCESS:
      state = {
        ...state,
        presencesGetSuccess: action.payload,
        presencesGetLoading: false
      }
      break
    case GET_PRESENCES_ERROR:
      state = {
        ...state,
        presencesGetError: action.payload,
        presencesGetLoading: false
      }
      break

    case EDIT_PRESENCES:
      state = {
        ...state,
        presencesEditError: null,
        presencesEditSuccess: null,
        presencesEditLoading: true,
      }
      break
    case EDIT_PRESENCES_SUCCESS:
      state = {
        ...state,
        presencesEditSuccess: action.payload,
        presencesEditLoading: false
      }
      break
    case EDIT_PRESENCES_ERROR:
      state = {
        ...state,
        presencesEditError: action.payload,
        presencesEditLoading: false
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default createPresences
