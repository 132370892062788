export const CREATE_USER = "CREATE_USER"
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS"
export const CREATE_USER_ERROR = "CREATE_USER_ERROR"

export const GET_USER = "GET_USER"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_ERROR = "GET_USER_ERROR"

export const EDIT_USER = "EDIT_USER"
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS"
export const EDIT_USER_ERROR = "EDIT_USER_ERROR"

export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_ERROR = "DELETE_USER_ERROR" 

export const GET_GROUP_USER_SELECT2 = "GET_GROUP_USER_SELECT2"
export const GET_GROUP_USER_SELECT2_SUCCESS = "GET_GROUP_USER_SELECT2_SUCCESS"
export const GET_GROUP_USER_SELECT2_ERROR = "GET_GROUP_USER_SELECT2_ERROR"

export const GET_USER_SELECT2 = "GET_USER_SELECT2"
export const GET_USER_SELECT2_SUCCESS = "GET_USER_SELECT2_SUCCESS"
export const GET_USER_SELECT2_ERROR = "GET_USER_SELECT2_ERROR"

export const SET_TAB = "SET_TAB"
export const GET_TAB = "GET_TAB"
