import React from 'react'
import { Field, ErrorMessage } from 'formik';
import { CurrencyInput } from '../Common';
import _ from "lodash";

class FieldMaskChild extends React.Component {

  render() {
    const { errors, touched, name, type, className, onChange = () => { }, placeholder, disabled, onClick = () => { }, textAlign = 'left', ...o } = this.props

    return (
      <React.Fragment>
        {onChange &&
          <Field name={name}

            render={({ field }) => (
              <CurrencyInput
                {...field}
                {...o}
                style={{ textAlign }}
                onClick={onClick}
                placeholder={placeholder ? placeholder : ''}
                type={type}
                onChange={onChange}
                disabled={disabled}
                className={
                  className ? className : 'form-control' +
                    (errors && touched ? ' is-invalid' : '')
                }
              />
            )}
          />
        }

        <ErrorMessage
          name={name}
          component="div" style={{ color: 'red' }}
          className="invalid-feedback" />
      </React.Fragment>
    )
  }
}

function compare({ is_notmemo, ...prevProps }, { is_notmemo: n, ...nextProps }) {
  if (is_notmemo && n) {
    return false
  }
  return _.isEqual(_.omit({ ...prevProps }, _.functions({ ...prevProps })), _.omit({ ...nextProps }, _.functions({ ...nextProps })))
}

const FieldMask = React.memo(FieldMaskChild, compare);

export { FieldMask }