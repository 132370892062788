module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "93257593441-ipnvmh0r9813hamj77ed3mh68ns0omre.apps.googleusercontent.com",
    SECRET: "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",
  },
  facebook: {
    APP_ID: "576586434071353",
  },
}
