import React from "react";
import MetaTags from 'react-meta-tags';
import { useSelector } from "react-redux";
import { Breadcrumb } from '../../components/Common';
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
} from "reactstrap";
import Filter from "./filter2";
import Revenue from "./revenue";
import Order from "./orders";

const Dashboard = props => {
  const { apk_name, filter } = useSelector(state => ({
    apk_name: state.Layout.apk_name,
    filter: state.Dashboard.getFilter
  }))


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Laporan Penjualan | {apk_name}</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumb title="Dashboard" breadcrumbItems={[
            { title: 'Dashboard', link: '/dashboard' },
          ]} />
          <Row>
            <Col lg={12}>
              <Filter />
              <Row>
                <Col lg={8}>
                  {filter && <Revenue />}
                </Col> 
                <Col lg={4}>
                  {filter && <Order />}
                </Col>
              </Row>
            </Col>

          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment >
  )
}


export default Dashboard;
