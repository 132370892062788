import {
  CREATE_OPSCOST,
  CREATE_OPSCOST_SUCCESS,
  CREATE_OPSCOST_ERROR,
  GET_OPSCOST,
  GET_OPSCOST_SUCCESS,
  GET_OPSCOST_ERROR,
  EDIT_OPSCOST,
  EDIT_OPSCOST_SUCCESS,
  EDIT_OPSCOST_ERROR,
  DELETE_OPSCOST,
  DELETE_OPSCOST_SUCCESS,
  DELETE_OPSCOST_ERROR,
} from "./actionTypes"

const initialState = {
  opscostCreateSuccess: null,
  opscostCreateError: null,
  opscostCreateLoading: false,
  opscostGetError: null,
  opscostGetSuccess: null,
  opscostGetLoading: false, 
  opscostEditError: null,
  opscostEditSuccess: null,
  opscostEditLoading: false, 
  opscostDeleteError: null,
  opscostDeleteSuccess: null,
  opscostDeleteLoading: false,
}

const createOpsCost = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_OPSCOST:
      state = {
        ...state,
        opscostCreateSuccess: null,
        opscostCreateError: null,
        opscostCreateLoading: true
      }
      break
    case CREATE_OPSCOST_SUCCESS:
      state = {
        ...state,
        opscostCreateSuccess: action.payload,
        opscostCreateLoading: false
      }
      break
    case CREATE_OPSCOST_ERROR:
      state = { 
          ...state, 
          opscostCreateError: action.payload, 
          opscostCreateLoading: false 
        }
      break
    case GET_OPSCOST:
      state = {
        ...state,
        opscostGetError: null,
        opscostGetSuccess: null,
        opscostGetLoading: true,
      }
      break
    case GET_OPSCOST_SUCCESS:
      state = {
        ...state,
        opscostGetSuccess: action.payload,
        opscostGetLoading: false
      }
      break
    case GET_OPSCOST_ERROR:
      state = {
        ...state,
        opscostGetError: action.payload,
        opscostGetLoading: false
      }
      break 
      case EDIT_OPSCOST:
      state = {
        ...state,
        opscostEditError: null,
        opscostEditSuccess: null,
        opscostEditLoading: true,
      }
      break
    case EDIT_OPSCOST_SUCCESS:
      state = {
        ...state,
        opscostEditSuccess: action.payload,
        opscostEditLoading: false
      }
      break
    case EDIT_OPSCOST_ERROR:
      state = {
        ...state,
        opscostEditError: action.payload,
        opscostEditLoading: false
      }
      break   
      case DELETE_OPSCOST:
      state = {
        ...state,
        opscostDeleteError: null,
        opscostDeleteSuccess: null,
        opscostDeleteLoading: true,
      }
      break
    case DELETE_OPSCOST_SUCCESS:
      state = {
        ...state,
        opscostDeleteSuccess: action.payload,
        opscostDeleteLoading: false
      }
      break
    case DELETE_OPSCOST_ERROR:
      state = {
        ...state,
        opscostDeleteError: action.payload,
        opscostDeleteLoading: false
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default createOpsCost
