import React from "react";
import MetaTags from 'react-meta-tags';
import { DataGridFooter, Breadcrumb, Access } from '../../components/Common'
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Button
} from "reactstrap";
import { useSelector } from "react-redux"
import { getExcel } from "../../helpers/api_helper"
import moment from "moment";
import Filter from './filter2'

const RecapSaleAdminReport = props => {
    const { filter, loading2, apk_name } = useSelector(state => ({
        filter: state.RecapSaleAdmin.getFilter,
        loading2: state.RecapSaleAdmin.loadingFilter,
        apk_name: state.Layout.apk_name,
    }))

    const tableColumns = [
        {
            name: 'Tanggal',
            selector: 'date',
            sortable: true,
        },
        {
            name: 'Nama Karyawan',
            selector: 'user_name',
            sortable: true,
        },
        {
            name: 'Total Omzet',
            selector: 'omzet',
            sortable: true,
        },
        {
            name: 'Total Belanja Supplier',
            selector: 'shopping',
            sortable: true,
        },
        {
            name: 'Total Profit',
            selector: 'profit',
            sortable: true,
        },
        {
            name: 'Jumlah Order',
            selector: 'orders',
            sortable: true,
        },

    ]

    const dataRender = ({ omzet, shopping, profit, bonus, ...e }) => {
        return {
            omzet: omzet.toLocaleString('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }),
            shopping: shopping.toLocaleString('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }),
            profit: profit.toLocaleString('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }),
            ...e
        }
    }

    const handleQuery = (query, values) => {
        Object.keys(values).forEach(e => {
            if (e === 'id_store') {
                query += `${e}=${values[e] && values[e].length ? values[e].map(({ value }) => value) : ''}&`
                query += `store_name=${values[e] && values[e].length ? values[e].map(({ label }) => label) : 'Semua Toko'}&`
            } else if (e === 'id_user') {
                query += `${e}=${values[e] && values[e].length ? values[e].map(({ value }) => value) : ''}&`
                query += `user_name=${values[e] && values[e].length ? values[e].map(({ label }) => label) : 'Semua Karyawan'}&`
            } else {
                query += `${e}=${(e === 'date_1' || e === 'date_2') ? values[e] ? moment(values[e]).format('YYYY-MM-DD') : '' : values[e]}&`
            }
        });
        query = query.slice(0, -1)
        return query
    }

    const handleExport = () => {
        const query = handleQuery(`?`, filter)
        getExcel(`/api/export_recap_sales_admin${query}`, 'Laporan Rekapan Per Admin')
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Laporan Rekapan Per Admin | {apk_name}</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumb title="Laporan Rekapan Per Admin" breadcrumbItems={[
                        { title: 'Dashboard', link: '/dashboard' },
                        { title: 'Laporan Rekapan Per Admin', link: '/laporan_rekapan_admin' }
                    ]} />
                    <Row>
                        <Col lg={12}>
                            <Filter />
                            <Card>
                                <CardBody>
                                    <CardTitle className="text-end">
                                        <Button color="success" className="mr-1" onClick={handleExport}><i className="bx bx-file font-size-16 align-middle me-2"></i>Download Excel</Button>
                                    </CardTitle>
                                    {!loading2 && filter && <DataGridFooter
                                        dataRender={dataRender}
                                        title={'Data Rekapan Per Admin'}
                                        nameQuery={`getIndexRecapAdminSales`}
                                        defSort={['date', 'asc']}
                                        filterAdd={{
                                            date_1: moment(filter?.date_1).format('YYYY-MM-DD'),
                                            date_2: moment(filter?.date_2).format('YYYY-MM-DD'),
                                            id_store: filter?.id_store,
                                            id_user: filter?.id_user
                                        }}
                                        variablefilter={"inputRecapAdminPagination!"}
                                        tableColumns={tableColumns} />}
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment >
    );
};

export default RecapSaleAdminReport;
