import { takeEvery, fork, put, all, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { EDIT_BASE } from "./actionTypes"
import {
  baseEditError,
  baseEditSuccess
} from "./actions"

import {
  postBaseEdit
} from "../../../helpers/backendAPI/base_salaries"

import { Notify } from "../../../components/Common"

function* baseEdit({ payload: { base, setSubmitting } }) {
  try {
    const response = yield call(postBaseEdit, base?.data.map(e => ({id : e.value, salary: e.salary})))
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(baseEditError(response?.errors[0]?.message))
    } else {
      Notify('success', `Ketentuan Gaji Pokok berhasil diubah`)
      yield put(
        baseEditSuccess(`Ketentuan Gaji Pokok berhasil diubah`)
      )
    }
    setSubmitting(false)
  } catch (error) {
    Notify('error', error)
    setSubmitting(false)
    yield put(baseEditError(error?.message))
  }
}

export function* watchBase() {
  yield takeEvery(EDIT_BASE, baseEdit)
}

function* baseSaga() {
  yield all([fork(watchBase)])
}

export default baseSaga
