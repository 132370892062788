import React, { useState } from "react";
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom'
import { DataGrid, deleteConfirm, Breadcrumb, Access } from '../../components/Common'
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Button
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux"
import { saleDelete } from "../../store/actions"
import { getExcel } from "../../helpers/api_helper"
import moment from "moment";
import Statis from "./static_data"
import Filter from './filter2'

const SaleReport = props => {
    const dispatch = useDispatch()
    const { loading, filter, loading2, apk_name} = useSelector(state => ({
        loading: state.Sales.saleDeleteLoading,
        filter: state.Sales.saleGetFilter,
        loading2: state.Sales.loadingGetFilter,
        apk_name: state.Layout.apk_name,
    }))

    const tableColumns = [
        {
            name: 'Tanggal',
            selector: 'date',
            sortable: true,
            width : '110px'
        },
        {
            name: 'Nama Karyawan',
            selector: 'user_name',
            sortable: true,
            width : '120px'
        },
        {
            name: 'Nama Toko',
            selector: 'store_name',
            sortable: true,
            width : '120px'
        },
        {
            name: 'Nama Pembeli',
            selector: 'buyer_name',
            sortable: true,
        },
        {
            name: 'No Telp Pembeli',
            selector: 'buyer_tlp',
            sortable: true,
        },
        {
            name: 'Nominal Belanja',
            selector: 'shopping',
            sortable: true,
        },  
        {
            name: 'Penghasilan',
            selector: 'omzet',
            sortable: true,
        }, 
        {
            name: 'Profit',
            selector: 'profit',
            sortable: true,
        },
        {
            name: 'No Resi',
            selector: 'resi_no',
            sortable: true,
        },
        {
            name: 'Ekspedisi',
            selector: 'id_expedition',
            sortable: true,
            width : '110px'
        },
        {
            name: 'Status',
            selector: 'id_status',
            sortable: true,
            width : '90px'
        },  
        {
            name: 'Supplier',
            selector: 'id_supplier',
            sortable: true,
            width : '90px'
        },  
        {
            name: '',
            selector: 'id',
            sortable: true,
            right: true
        },

    ]

    const dataRender = ({ id, date, id_status, shopping, omzet, profit, id_expedition, id_supplier, ...e }) => {
        return {
            date: moment(date).format('DD/MM/YYYY'),
            id_status: Statis.status.find(x => x.value === id_status).label,
            shopping: shopping.toLocaleString('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits:0}),
            omzet: omzet.toLocaleString('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits:0}),
            profit: profit.toLocaleString('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits:0}),
            id_expedition : Statis.expeditions.find(x => x.value === id_expedition).label,
            id_supplier : Statis.suppliers.find(x => x.value === id_supplier).label,
            id: <>
                <Access id={`29`}><i className="mdi mdi-24px mdi-book-edit-outline" onClick={() => handleEdit(id)}></i></Access>
                <Access id={`78`}><i className="mdi mdi-24px mdi-trash-can-outline" onClick={() => handleDelete({ id, ...e })}></i></Access>
            </>,
            ...e
        }
    }

    const handleEdit = (id) => props.history.push(`/laporan_penjualan/edit/${id}`)

    const handleDelete = ({ id, resi_no }) => {
        deleteConfirm({
            name: resi_no,
            command: () => dispatch(saleDelete({ id, code: resi_no }))
        })
    }

    const handleQuery = (query, values) => {
        Object.keys(values).forEach(e => {
            if (e === 'id_store') {
                query += `${e}=${values[e] && values[e].length ? values[e].map(({ value }) => value) : ''}&`
                query += `store_name=${values[e] && values[e].length ? values[e].map(({ label }) => label) : 'Semua Toko'}&`
            } else if (e === 'id_user') {
                query += `${e}=${values[e] && values[e].length ? values[e].map(({ value }) => value) : ''}&`
                query += `user_name=${values[e] && values[e].length ? values[e].map(({ label }) => label) : 'Semua Karyawan'}&`
            } else {
                query += `${e}=${(e === 'date_1' || e === 'date_2') ? values[e] ? moment(values[e]).format('YYYY-MM-DD') : '' : values[e]}&`
            }
        });
        query = query.slice(0, -1)
        return query
    }

    const handleExport = () => {
        const query = handleQuery(`?`, filter)
        getExcel(`/api/export_sales${query}`, 'Laporan Penjualan')
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Laporan Penjualan | {apk_name}</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumb title="Laporan Penjualan" breadcrumbItems={[
                        { title: 'Dashboard', link: '/dashboard' },
                        { title: 'Laporan Penjualan', link: '/laporan_penjualan' }
                    ]} />
                    <Row>
                        <Col lg={12}>
                            <Filter />
                            <Card>
                                <CardBody>
                                    <CardTitle className="text-end">
                                        <Button color="success" className="mr-1" onClick={handleExport}><i className="bx bx-file font-size-16 align-middle me-2"></i>Download Excel</Button>&nbsp;&nbsp;
                                        <Access id={`28`}><Link className="btn btn-primary" to={`/laporan_penjualan/tambah`}> <i className="bx bx-plus-circle font-size-16 align-middle me-2"></i> Tambah</Link></Access>
                                    </CardTitle>
                                    {!loading && !loading2 && filter && <DataGrid
                                        dataRender={dataRender}
                                        title={'Data Penjualan'}
                                        nameQuery={`getIndexSales`}
                                        defSort={['date', 'asc']}
                                        filterAdd={{
                                            date_1: moment(filter?.date_1).format('YYYY-MM-DD'),
                                            date_2: moment(filter?.date_2).format('YYYY-MM-DD'),
                                            id_store: filter?.id_store,
                                            id_user: filter?.id_user
                                        }}
                                        variablefilter={"inputSalePagination!"}
                                        tableColumns={tableColumns} />}
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment >
    );
};

export default SaleReport;
