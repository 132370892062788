import { del, get, post, put, stringify } from "../api_helper"

const getPresences = data => {
    const query = `
        query getPresences {
            getAllPresenceStatus(input : ${stringify(data)}) {
                id
                name
                is_present
                penalty
                is_active
              }
        }
    `
    return post({ query })
}

const getPresenceSelect2 = data => {
    const query = `
        query getPresences {
            getAllPresenceStatus(input : ${stringify(data)}) {
                label
                value
                penalty
                is_present
              }
        }
    `
    return post({ query })
}

const postPresencesEdit = data => {
    const query = `
        mutation postPresencesEdit {
            updatePresenceStatus(input : [${
                data?.data?.map(e => (
                   `{id : ${e?.id || 0 } name : "${e.name}", penalty : ${e.penalty}, is_present : ${e.is_present}, is_active : ${e.is_active}}`
                ))
            }]) {
             id
            }
        }
    `
    return post({ query })
}


export {
    getPresences,
    postPresencesEdit,
    getPresenceSelect2
}
