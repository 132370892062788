import {
  SET_RECAPSALE_FILTER,
  GET_RECAPSALE_FILTER
} from "./actionTypes"

export const recapSaleSetFilter = message => {
  return {
    type: SET_RECAPSALE_FILTER,
    payload: message,
  }
}

export const recapSaleGetFilter = message => {
  return {
    type: GET_RECAPSALE_FILTER,
    payload: message,
  }
}

