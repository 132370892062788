import {
  CREATE_OPSCOST,
  CREATE_OPSCOST_SUCCESS,
  CREATE_OPSCOST_ERROR,
  GET_OPSCOST,
  GET_OPSCOST_SUCCESS,
  GET_OPSCOST_ERROR,
  EDIT_OPSCOST,
  EDIT_OPSCOST_SUCCESS,
  EDIT_OPSCOST_ERROR,
  DELETE_OPSCOST,
  DELETE_OPSCOST_SUCCESS,
  DELETE_OPSCOST_ERROR,
} from "./actionTypes"

export const opscostCreate = (opscost, setSubmitting, history) => {
  return {
    type: CREATE_OPSCOST,
    payload: { opscost, setSubmitting, history},
  }
}

export const opscostCreateSuccess = message => {
  return {
    type: CREATE_OPSCOST_SUCCESS,
    payload: message,
  }
}

export const opscostCreateError = message => {
  return {
    type: CREATE_OPSCOST_ERROR,
    payload: message,
  }
}

export const opscostGet = (opscost) => {
  return {
    type: GET_OPSCOST,
    payload: { opscost },
  }
}

export const opscostGetSuccess = message => {
  return {
    type: GET_OPSCOST_SUCCESS,
    payload: message,
  }
}

export const opscostGetError = message => {
  return {
    type: GET_OPSCOST_ERROR,
    payload: message,
  }
}

export const opscostEdit = (opscost, setSubmitting, history) => {
  return {
    type: EDIT_OPSCOST,
    payload: { opscost, setSubmitting, history},
  }
}

export const opscostEditSuccess = message => {
  return {
    type: EDIT_OPSCOST_SUCCESS,
    payload: message,
  }
}

export const opscostEditError = message => {
  return {
    type: EDIT_OPSCOST_ERROR,
    payload: message,
  }
}

export const opscostDelete = (opscost) => {
  return {
    type: DELETE_OPSCOST,
    payload: { opscost },
  }
}

export const opscostDeleteSuccess = message => {
  return {
    type: DELETE_OPSCOST_SUCCESS,
    payload: message,
  }
}

export const opscostDeleteError = message => {
  return {
    type: DELETE_OPSCOST_ERROR,
    payload: message,
  }
}

