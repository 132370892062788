import axios from "axios"
import { del, get, post, put, stringify} from "../api_helper"
const API_URL = process.env?.REACT_APP_API_URL || "http://localhost:5000"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
    const user = localStorage.getItem("user")
    if (user) return JSON.parse(user)
    return null
}

//is user is logged in
const isUserAuthenticated = () => {
    return getLoggedInUser() !== null
}

// Register Method
const postLogin = dt => {
    const query = `
            mutation login {
                login(input : { email : "${dt.username}", password : "${dt.password}"}) {
                id
                name
                email
                token
                id_group
                districtsGrupAccess
                access {
                    id_list
                }
                group {
                    name
                }
                }
            }
         `
    return axios.post(API_URL + '/graphql', { query })
}

const postForgetPwd = data => {
    const query = `
            mutation forgetPwd {
                createForgotPassword(email : "${data.email}") {
                message
                }
            }
         `
    return post({ query })
}
const postResetPwd = data => {
    const query = `
        mutation resetPwd {
            createResetPassword(input : ${stringify(data)}) {
            message
            }
        }
    `
    return post({ query })
}

export {
    getLoggedInUser,
    isUserAuthenticated,
    postLogin,
    postForgetPwd,
    postResetPwd
}
