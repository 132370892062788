export const CREATE_WORK_GUIDE = "CREATE_WORK_GUIDE"
export const CREATE_WORK_GUIDE_SUCCESS = "CREATE_WORK_GUIDE_SUCCESS"
export const CREATE_WORK_GUIDE_ERROR = "CREATE_WORK_GUIDE_ERROR"

export const GET_WORK_GUIDE = "GET_WORK_GUIDE"
export const GET_WORK_GUIDE_SUCCESS = "GET_WORK_GUIDE_SUCCESS"
export const GET_WORK_GUIDE_ERROR = "GET_WORK_GUIDE_ERROR"

export const EDIT_WORK_GUIDE = "EDIT_WORK_GUIDE"
export const EDIT_WORK_GUIDE_SUCCESS = "EDIT_WORK_GUIDE_SUCCESS"
export const EDIT_WORK_GUIDE_ERROR = "EDIT_WORK_GUIDE_ERROR"

export const DELETE_WORK_GUIDE = "DELETE_WORK_GUIDE"
export const DELETE_WORK_GUIDE_SUCCESS = "DELETE_WORK_GUIDE_SUCCESS"
export const DELETE_WORK_GUIDE_ERROR = "DELETE_WORK_GUIDE_ERROR" 
