import React from 'react'

export class Loader extends React.Component {
    render() {
        return (

            <div className="formLoader">
                <ul className="formLoading">
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
        )
    }
}

