import {
  SET_SALARY_FILTER,
  GET_SALARY_FILTER
} from "./actionTypes"

const initialState = {
  getFilter: null,
  loadingGetFilter: false,
}

const createSalary = (state = initialState, action) => {
  switch (action.type) {
    case SET_SALARY_FILTER:
      state = {
        ...state,
        getFilter: null,
        loadingGetFilter: true,
      }
      break
    case GET_SALARY_FILTER:
      state = {
        ...state,
        getFilter: action.payload,
        loadingGetFilter: false
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default createSalary
