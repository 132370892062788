import { takeEvery, fork, put, all, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { GET_BONUS, EDIT_BONUS } from "./actionTypes"
import {
  bonusEditError,
  bonusEditSuccess,
  bonusGetError,
  bonusGetSuccess
} from "./actions"

import {
  getBonus,
  postBonusEdit
} from "../../../helpers/backendAPI/bonuses"

import { Notify } from "../../../components/Common"

function* bonusGet({ payload: { bonus } }) {
  try {
    const response = yield call(getBonus, bonus)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(bonusGetError(response?.errors[0]?.message))
    } else {
      yield put(
        bonusGetSuccess(response?.data?.getAllBonuses)
      )
    }
  } catch (error) {
    Notify('error', error?.message)
    yield put(bonusGetError(error?.message))
  }
}


function* bonusEdit({ payload: { bonus, setSubmitting } }) {
  try {
    const response = yield call(postBonusEdit, bonus)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(bonusEditError(response?.errors[0]?.message))
    } else {
      Notify('success', `Ketentuan Bonus berhasil diubah`)
      yield put(
        bonusEditSuccess(`Ketentuan Bonus berhasil diubah`)
      )
    }
    setSubmitting(false)
  } catch (error) {
    Notify('error', error)
    setSubmitting(false)
    yield put(bonusEditError(error?.message))
  }
}

export function* watchBonus() {
  yield takeEvery(EDIT_BONUS, bonusEdit)
  yield takeEvery(GET_BONUS, bonusGet)
}

function* bonusSaga() {
  yield all([fork(watchBonus)])
}

export default bonusSaga
