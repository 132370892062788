import React, { useEffect } from "react";
import MetaTags from 'react-meta-tags';
import { RowInput, TextField, Breadcrumb, TextEditor, Loader } from '../../components/Common'
import { useDispatch, useSelector } from "react-redux"
import { guideGet } from "../../store/actions"
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Button
} from "reactstrap";
import { useParams } from "react-router-dom";

const GuideDetail = props => {
    const dispatch = useDispatch()
    const { id } = useParams()

    const { guide, apk_name} = useSelector(state => ({
        guide: state.WorkGuides.getSuccess,
        apk_name: state.Layout.apk_name,
    }))

    useEffect(() => dispatch(guideGet({ id: parseFloat(id) })), [])

    if (!guide) return <Loader />
    const {
        name,
        description
    } = guide

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Detail Panduan Kerja | {apk_name}</title>
                </MetaTags>

                <Container fluid={true}>
                    <Breadcrumb title="Detail Panduan Kerja" breadcrumbItems={[
                        { title: 'Dashboard', link: '/dashboard' },
                        { title: 'Panduan Kerja', link: '/panduan_kerja' },
                        { title: 'Detail', link: `/panduan_kerja/detail/${id}` }
                    ]} />
                    <Row>
                        <Col lg={10}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">{name}</CardTitle>
                                    <hr></hr>
                                    <div dangerouslySetInnerHTML={{ __html: description }} />
                                    <hr></hr>
                                    <Row className="justify-content-end">
                                        <Col lg="12">
                                            <Button type="button" color="secondary" onClick={() => { props.history.push('/panduan_kerja') }}>
                                                <i className="bx  bx-left-arrow-circle font-size-16 align-middle me-2"></i>
                                                {'Kembali'}
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

            </div>
        </React.Fragment>
    );
};


export default GuideDetail;
