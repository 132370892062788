import SweetAlert from 'sweetalert2'

const deleteConfirm = ({ name, command, html }) => {
    html = html ? html : `Anda akan menghapus data <u><b>${name}</b></u>. Setelah dihapus data tidak dapat dipulihkan kembali`
    SweetAlert.fire({
        title: 'Apakah Anda Yakin?',
        html,
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
        reverseButtons: true
    })
        .then((result) => {
            if (result.value) {
                command()
            }
        })


}

const cancleConfirm = ({ name, command }) => {
    SweetAlert.fire({
        input: 'textarea',
        html: `Anda akan membatalkan order <u><b>${name}</b></u>. Setelah dibatalkan order tidak dapat dilanjutkan kembali`,
        inputPlaceholder: 'Ketikan alasan anda membatalkan...',
        inputAttributes: {
            'aria-label': 'Type your message here'
        },
        showCancelButton: true,
        inputValidator: (value) => {
            if (!value) {
                return 'alasan pembatalan wajib diisi!'
            }
        }
    })
        .then(({ value }) => {
            if (value) {
                command(value)
            }
        })


}

const infoConfrim = (html) => {
    SweetAlert.fire({
        position: 'top-end',
        html,
        showClass: {
            popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
        }
    })
}

export { deleteConfirm, cancleConfirm, infoConfrim }