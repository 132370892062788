import {
  SET_RECAPSALE_FILTER,
  GET_RECAPSALE_FILTER
} from "./actionTypes"

const initialState = {
  getFilter: null,
  loadingFilter: false,
}

const recapSale = (state = initialState, action) => {
  switch (action.type) {
    case SET_RECAPSALE_FILTER:
      state = {
        ...state,
        getFilter: null,
        loadingFilter: true,
      }
      break
    case GET_RECAPSALE_FILTER:
      state = {
        ...state,
        getFilter: action.payload,
        loadingFilter: false
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default recapSale
