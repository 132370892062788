import React from 'react'
import RichTextEditor from "./RichTextEditor";
import { Field, ErrorMessage } from 'formik';
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";
import _ from "lodash";

class TextEditorChild extends React.Component {
  render() {
    let { errors, touched, name } = this.props
    return (
      <React.Fragment>
        <Field name={name}>
          {({ field, form }) => (
            <div className="text-editor" style={{ margin: "auto 0px" }}>
              <RichTextEditor name={name} field={field} />
              {errors && touched ? (
                <div className="explain">{errors}</div>
              ) : null}
            </div>
          )}
        </Field>



        <ErrorMessage
          name={name}
          component="div" style={{ color: 'red' }}
          className="invalid-feedback" />
      </React.Fragment>
    )
  }
}

function compare({ is_notmemo, ...prevProps }, { is_notmemo: n, ...nextProps }) {
  if (is_notmemo && n) {
    return false
  }
  return _.isEqual(_.omit({ ...prevProps }, _.functions({ ...prevProps })), _.omit({ ...nextProps }, _.functions({ ...nextProps })))
}

const TextEditor = React.memo(TextEditorChild, compare);


export { TextEditor }
