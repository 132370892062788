import {
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  GET_GROUP_USER_SELECT2,
  GET_GROUP_USER_SELECT2_SUCCESS,
  GET_GROUP_USER_SELECT2_ERROR,
  GET_USER_SELECT2,
  GET_USER_SELECT2_SUCCESS,
  GET_USER_SELECT2_ERROR,
  SET_TAB,
  GET_TAB
} from "./actionTypes"

export const userCreate = (user, setSubmitting, history) => {
  return {
    type: CREATE_USER,
    payload: { user, setSubmitting, history},
  }
}

export const userCreateSuccess = message => {
  return {
    type: CREATE_USER_SUCCESS,
    payload: message,
  }
}

export const userCreateError = message => {
  return {
    type: CREATE_USER_ERROR,
    payload: message,
  }
}

export const userGet = (user) => {
  return {
    type: GET_USER,
    payload: { user },
  }
}

export const userGetSuccess = message => {
  return {
    type: GET_USER_SUCCESS,
    payload: message,
  }
}

export const userGetError = message => {
  return {
    type: GET_USER_ERROR,
    payload: message,
  }
}

export const userEdit = (user, setSubmitting, history) => {
  return {
    type: EDIT_USER,
    payload: { user, setSubmitting, history},
  }
}

export const userEditSuccess = message => {
  return {
    type: EDIT_USER_SUCCESS,
    payload: message,
  }
}

export const userEditError = message => {
  return {
    type: EDIT_USER_ERROR,
    payload: message,
  }
}

export const userDelete = (user) => {
  return {
    type: DELETE_USER,
    payload: { user },
  }
}

export const userDeleteSuccess = message => {
  return {
    type: DELETE_USER_SUCCESS,
    payload: message,
  }
}

export const userDeleteError = message => {
  return {
    type: DELETE_USER_ERROR,
    payload: message,
  }
}

export const getGrupUserSelect2 = (user) => ({
  type: GET_GROUP_USER_SELECT2,
  payload: { user }
})

export const getGrupUserSelect2Success = userGroups => ({
  type: GET_GROUP_USER_SELECT2_SUCCESS,
  payload: userGroups,
})

export const getGrupUserSelect2Error = error => ({
  type: GET_GROUP_USER_SELECT2_ERROR,
  payload: error,
})

export const getUserSelect2 = (user) => ({
  type: GET_USER_SELECT2,
  payload: { user }
})

export const getUserSelect2Success = user => ({
  type: GET_USER_SELECT2_SUCCESS,
  payload: user,
})

export const getUserSelect2Error = error => ({
  type: GET_USER_SELECT2_ERROR,
  payload: error,
})

export const userSetTab = ({ name, path }) => {
  return {
    type: SET_TAB,
    payload: { name, path },
  }
}

export const userGetTab = ({ name, path }) => {
  return {
    type: GET_TAB,
    payload: { name, path },
  }
}
