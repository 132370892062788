import { del, get, post, put, stringify } from "../api_helper"

const getBonus = data => {
    const query = `
        query getBonus {
            getAllBonuses{
                from_amount
                to_amount
                is_infinity
                amount
                is_active
            }
        }
    `
    return post({ query })
}

const postBonusEdit = data => {
    const query = `
        mutation updateBonuses {
            updateBonuses(input : ${stringify(data?.data).replace(/"/g, "")}) {
             id
            }
        }
    `
    return post({ query })
}


export {
    getBonus,
    postBonusEdit
}
