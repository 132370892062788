import { takeEvery, fork, put, all, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { GET_PRESENCES, EDIT_PRESENCES } from "./actionTypes"
import {
  presencesEditError,
  presencesEditSuccess,
  presencesGetError,
  presencesGetSuccess
} from "./actions"

import {
  getPresences,
  getPresenceSelect2,
  postPresencesEdit
} from "../../../helpers/backendAPI/presence_statuses"

import { Notify } from "../../../components/Common"

function* presencesGet({ payload: { presences } }) {
  try {
    const response = yield presences?.order ? call(getPresenceSelect2, presences) : call(getPresences, presences)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(presencesGetError(response?.errors[0]?.message))
    } else {
      yield put(
        presencesGetSuccess(response?.data?.getAllPresenceStatus)
      )
    }
  } catch (error) {
    Notify('error', error?.message)
    yield put(presencesGetError(error?.message))
  }
}


function* presencesEdit({ payload: { presences, setSubmitting } }) {
  try {
    const response = yield call(postPresencesEdit, presences)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(presencesEditError(response?.errors[0]?.message))
    } else {
      Notify('success', `Status Kehadiran berhasil diubah`)
      yield put(
        presencesEditSuccess(`Status Kehadiran berhasil diubah`)
      )
    }
    setSubmitting(false)
  } catch (error) {
    Notify('error', error?.message)
    setSubmitting(false)
    yield put(presencesEditError(error?.message))
  }
}

export function* watchPresences() {
  yield takeEvery(EDIT_PRESENCES, presencesEdit)
  yield takeEvery(GET_PRESENCES, presencesGet)
}

function* presencesSaga() {
  yield all([fork(watchPresences)])
}

export default presencesSaga
