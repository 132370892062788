import {
  CREATE_WITHDRAWAL,
  CREATE_WITHDRAWAL_SUCCESS,
  CREATE_WITHDRAWAL_ERROR,
  GET_WITHDRAWAL,
  GET_WITHDRAWAL_SUCCESS,
  GET_WITHDRAWAL_ERROR,
  EDIT_WITHDRAWAL,
  EDIT_WITHDRAWAL_SUCCESS,
  EDIT_WITHDRAWAL_ERROR,
  DELETE_WITHDRAWAL,
  DELETE_WITHDRAWAL_SUCCESS,
  DELETE_WITHDRAWAL_ERROR,
  SET_WITHDRAWAL_FILTER,
  GET_WITHDRAWAL_FILTER
} from "./actionTypes"

export const withdrawalCreate = (withdrawal, setSubmitting, history) => {
  return {
    type: CREATE_WITHDRAWAL,
    payload: { withdrawal, setSubmitting, history},
  }
}

export const withdrawalCreateSuccess = message => {
  return {
    type: CREATE_WITHDRAWAL_SUCCESS,
    payload: message,
  }
}

export const withdrawalCreateError = message => {
  return {
    type: CREATE_WITHDRAWAL_ERROR,
    payload: message,
  }
}

export const withdrawalGet = (withdrawal) => {
  return {
    type: GET_WITHDRAWAL,
    payload: { withdrawal },
  }
}

export const withdrawalGetSuccess = message => {
  return {
    type: GET_WITHDRAWAL_SUCCESS,
    payload: message,
  }
}

export const withdrawalGetError = message => {
  return {
    type: GET_WITHDRAWAL_ERROR,
    payload: message,
  }
}

export const withdrawalEdit = (withdrawal, setSubmitting, history) => {
  return {
    type: EDIT_WITHDRAWAL,
    payload: { withdrawal, setSubmitting, history},
  }
}

export const withdrawalEditSuccess = message => {
  return {
    type: EDIT_WITHDRAWAL_SUCCESS,
    payload: message,
  }
}

export const withdrawalEditError = message => {
  return {
    type: EDIT_WITHDRAWAL_ERROR,
    payload: message,
  }
}

export const withdrawalDelete = (withdrawal) => {
  return {
    type: DELETE_WITHDRAWAL,
    payload: { withdrawal },
  }
}

export const withdrawalDeleteSuccess = message => {
  return {
    type: DELETE_WITHDRAWAL_SUCCESS,
    payload: message,
  }
}

export const withdrawalDeleteError = message => {
  return {
    type: DELETE_WITHDRAWAL_ERROR,
    payload: message,
  }
}

export const withdrawalSetFilter = message => {
  return {
    type: SET_WITHDRAWAL_FILTER,
    payload: message,
  }
}

export const withdrawalGetFilter = message => {
  return {
    type: GET_WITHDRAWAL_FILTER,
    payload: message,
  }
}
