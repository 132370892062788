import { takeEvery, fork, put, all, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { SET_INCOME_STATEMENT_FILTER, GET_INCOME_STATEMENT } from "./actionTypes"
import {
  incomeStatementGetFilter,
  incomeStatementGetError,
  incomeStatementGetSuccess
} from "./actions"

import {
  getIncomeStatement,
} from "../../helpers/backendAPI/income_statements"
import { Notify } from "../../components/Common"

function* setFilter({ payload }) {
  yield put(incomeStatementGetFilter({ ...payload }))
}

function* incomeStatementGet({ payload }) {
  try {
    const response = yield call(getIncomeStatement, payload)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(incomeStatementGetError(response?.errors[0]?.message))
    } else {
      yield put(
        incomeStatementGetSuccess(response?.data?.getIncomeStatement)
      )
    }
  } catch (error) {
    Notify('error', error?.message)
    yield put(incomeStatementGetError(error?.message))
  }
}


export function* watchIncomeStatement() {
  yield takeEvery(SET_INCOME_STATEMENT_FILTER, setFilter)
  yield takeEvery(GET_INCOME_STATEMENT, incomeStatementGet)
}

function* incomeStatemetSaga() {
  yield all([fork(watchIncomeStatement)])
}

export default incomeStatemetSaga
