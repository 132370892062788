import {
  GET_BONUS,
  GET_BONUS_SUCCESS,
  GET_BONUS_ERROR,
  EDIT_BONUS,
  EDIT_BONUS_SUCCESS,
  EDIT_BONUS_ERROR,
} from "./actionTypes"

const initialState = {
  bonusGetError: null,
  bonusGetSuccess: null,
  bonusGetLoading: false, 
  bonusEditError: null,
  bonusEditSuccess: null,
  bonusEditLoading: false, 
}

const createBonus = (state = initialState, action) => {
  switch (action.type) {
    case GET_BONUS:
      state = {
        ...state,
        bonusGetError: null,
        bonusGetSuccess: null,
        bonusGetLoading: true,
      }
      break
    case GET_BONUS_SUCCESS:
      state = {
        ...state,
        bonusGetSuccess: action.payload,
        bonusGetLoading: false
      }
      break
    case GET_BONUS_ERROR:
      state = {
        ...state,
        bonusGetError: action.payload,
        bonusGetLoading: false
      }
      break 
      
      case EDIT_BONUS:
      state = {
        ...state,
        bonusEditError: null,
        bonusEditSuccess: null,
        bonusEditLoading: true,
      }
      break
    case EDIT_BONUS_SUCCESS:
      state = {
        ...state,
        bonusEditSuccess: action.payload,
        bonusEditLoading: false
      }
      break
    case EDIT_BONUS_ERROR:
      state = {
        ...state,
        bonusEditError: action.payload,
        bonusEditLoading: false
      }
      break   
    default:
      state = { ...state }
      break
  }
  return state
}

export default createBonus
