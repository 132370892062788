import {
  GET_ALLOWANCES,
  GET_ALLOWANCES_SUCCESS,
  GET_ALLOWANCES_ERROR,
  EDIT_ALLOWANCES,
  EDIT_ALLOWANCES_SUCCESS,
  EDIT_ALLOWANCES_ERROR,
} from "./actionTypes"


export const allowancesGet = (allowances) => {
  return {
    type: GET_ALLOWANCES,
    payload: { allowances },
  }
}

export const allowancesGetSuccess = message => {
  return {
    type: GET_ALLOWANCES_SUCCESS,
    payload: message,
  }
}

export const allowancesGetError = message => {
  return {
    type: GET_ALLOWANCES_ERROR,
    payload: message,
  }
}

export const allowancesEdit = (allowances, setSubmitting, history) => {
  return {
    type: EDIT_ALLOWANCES,
    payload: { allowances, setSubmitting, history},
  }
}

export const allowancesEditSuccess = message => {
  return {
    type: EDIT_ALLOWANCES_SUCCESS,
    payload: message,
  }
}

export const allowancesEditError = message => {
  return {
    type: EDIT_ALLOWANCES_ERROR,
    payload: message,
  }
}

