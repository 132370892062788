import {
  GET_PRESENCES,
  GET_PRESENCES_SUCCESS,
  GET_PRESENCES_ERROR,
  EDIT_PRESENCES,
  EDIT_PRESENCES_SUCCESS,
  EDIT_PRESENCES_ERROR,
} from "./actionTypes"


export const presencesGet = (presences) => {
  return {
    type: GET_PRESENCES,
    payload: { presences },
  }
}

export const presencesGetSuccess = message => {
  return {
    type: GET_PRESENCES_SUCCESS,
    payload: message,
  }
}

export const presencesGetError = message => {
  return {
    type: GET_PRESENCES_ERROR,
    payload: message,
  }
}

export const presencesEdit = (presences, setSubmitting, history) => {
  return {
    type: EDIT_PRESENCES,
    payload: { presences, setSubmitting, history},
  }
}

export const presencesEditSuccess = message => {
  return {
    type: EDIT_PRESENCES_SUCCESS,
    payload: message,
  }
}

export const presencesEditError = message => {
  return {
    type: EDIT_PRESENCES_ERROR,
    payload: message,
  }
}

