import { del, get, post, put, stringify } from "../api_helper"

const postUserCreate = data => {
    const query = `
        mutation createUser {
            createUser(input : {
                name: "${data.name}",
                email: "${data.email}",
                password : "${data.password}",
                place_of_birth : "${data.place_of_birth}",
                date_of_birth : ${data.date_of_birth ? `"${data.date_of_birth}"` : null},
                date_started_work : ${data.date_started_work ? `"${data.date_started_work}"` : null},
                id_group : ${data.id_group},
                address : "${data.address}",
                image : "${data.image}",
                is_active : ${data.is_active},
            }) {
             id
             email
            }
        }
    `
    return post({ query })
}

const getUser = data => {
    const query = `
        query getUser {
            getSingleUser(id : ${data}) {
                name
                email
                place_of_birth
                date_of_birth
                date_started_work
                id_group
                group {
                    name
                }
                address
                image
                is_active
            }
        }
    `
    return post({ query })
}

const postUserEdit = data => {
    const query = `
        mutation postUserEdit {
            updateUser(input : {
                id : ${data.id},
                name: "${data.name}",
                email: "${data.email}",
                place_of_birth : "${data.place_of_birth}",
                date_of_birth : ${data.date_of_birth ? `"${data.date_of_birth}"` : null},
                date_started_work : ${data.date_started_work ? `"${data.date_started_work}"` : null},
                id_group : ${data.id_group},
                address : "${data.address}",
                image : "${data.image}",
                is_active : ${data.is_active},
            }) {
             id
            }
        }
    `
    return post({ query })
}


const postUserDelete = data => {
    const query = `
        mutation postUserDelete {
            daleteUser(input : ${stringify(data)}) {
             id
            }
        }
    `
    return post({ query })
}


const getUserGroup = data => {
    const query = `
        query getUserGroup {
            getAllGroup(input : ${stringify(data)}) {
             label
             value
             salary
            }
        }
    `
    return post({ query })
}

const getUserSelect2 = data => {
    const query = `
        query getUserSelect2 {
            getAllUser(input : ${stringify(data)}) {
             label
             value
            }
        }
    `
    return post({ query })
}

export {
    postUserCreate,
    getUser,
    postUserEdit,
    postUserDelete,
    getUserGroup,
    getUserSelect2
}
