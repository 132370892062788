import { del, get, post, put, stringify } from "../api_helper"

const createAttendance = data => {
    const query = `
        mutation createAttendance($data : CreateAttendanceInput!){
            createAttendance(input : $data) {
             id
            }
        }
    `
    return post({ query, variables : { data } })
}

const getAttendance = data => {
    const query = `
        query getSingleAttendance($id : Int!) {
            getSingleAttendance(id : $id) {
                date
                employee_count
                present_count 
                salary_cut
                details(id : $id){
                    id
                    id_user
                    user_name
                    id_status
                    status_name
                    is_present
                    salary_cut
                    note
                }
            }
        }
    `
    return post({ query,  variables : { ...data }  })
}

const editAttendance = data => {
    const query = `
        mutation updateAttendance($data : UpdateAttendanceInput!) {
            updateAttendance(input : $data) {
             id
            }
        }
    `
    return post({ query, variables : { data }})
}


const deleteAttendance = data => {
    const query = `
        mutation daleteAttendance($data : DeleteAttendanceInput!) {
            daleteAttendance(input : $data) {
             id
            }
        }
    `
    return post({ query, variables : { data } })
}



export {
    createAttendance,
    getAttendance,
    editAttendance,
    deleteAttendance
}
