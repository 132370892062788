import React, { useEffect } from "react";
import MetaTags from 'react-meta-tags';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { RowInput, TextField, Breadcrumb, Loader, SelectAPI } from '../../components/Common'
import { useDispatch, useSelector } from "react-redux"
import { storeCreate as storeCreateRedux, getUserSelect2 } from "../../store/actions"
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Button
} from "reactstrap";

const StoreCreate = props => {
    const dispatch = useDispatch()
    const { users, apk_name} = useSelector(state => ({
        users: state.Users.userSelect2Success,
        apk_name: state.Layout.apk_name,
    }))

    useEffect(() => dispatch(getUserSelect2({ name: '' })), [])

    if (!users) return <Loader />

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Tambah Data Toko | {apk_name}</title>
                </MetaTags>

                <Formik
                    initialValues={{
                        name: '',
                        email: '',
                        id_user : users?.length ? users[0]?.value : null,
                        category: '',
                        tlp: '',
                        acc_number: '',
                        acc_bank: '',
                        acc_owner: '',
                        id_merchant: '',
                        is_active: true
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string().required('Nama Toko tidak boleh kosong'),
                        email: Yup.string().email('Format email tidak sesuai').required('Email tidak boleh kosong'),
                        is_active: Yup.bool(),
                    })}

                    onSubmit={(values, { setSubmitting }) => dispatch(storeCreateRedux(values, setSubmitting, props.history))}

                    render={({ values, errors, touched, isSubmitting, setFieldValue }) => {
                        
                        const _handleSelect = (selectChoice, { name }) => setFieldValue(name, selectChoice.value);
                    
                        return (
                            <>
                                <Form>
                                    <Container fluid={true}>
                                        <Breadcrumb title="Tambah Data Toko" breadcrumbItems={[
                                            { title: 'Dashboard', link: '/dashboard' },
                                            { title: 'Data Toko', link: '/data_toko' },
                                            { title: 'Tambah', link: `/data_toko/tambah` }
                                        ]} />
                                        <Row>
                                            <Col lg={7}>
                                                <Card>
                                                    <CardBody>
                                                        <CardTitle className="mb-4"></CardTitle>
                                                        <RowInput
                                                            label="Nama Toko"
                                                            mandatory={true}
                                                            labelSize={3}
                                                            size={8}
                                                        >
                                                            <TextField
                                                                name="name"
                                                                type="text"
                                                                errors={errors.name}
                                                                touched={touched.name}
                                                            />
                                                        </RowInput>
                                                        <RowInput
                                                            label="Email"
                                                            mandatory={true}
                                                            labelSize={3}
                                                            size={8}
                                                        >
                                                            <TextField
                                                                name="email"
                                                                type="text"
                                                                errors={errors.email}
                                                                touched={touched.email}
                                                            />
                                                        </RowInput>
                                                        <RowInput
                                                            label="Penanggung Jawab"
                                                            mandatory={true}
                                                            labelSize={3}
                                                            size={8}
                                                        >
                                                            <SelectAPI
                                                                name="id_user"
                                                                errors={errors.id_user}
                                                                touched={touched.id_user}
                                                                disabled={false}
                                                                select_ref={false}
                                                                options={users}
                                                                defaultValue={users[0]}
                                                                nameQuery={`getAllUser`}
                                                                handleSelect={_handleSelect}
                                                            />
                                                        </RowInput>
                                                        <RowInput
                                                            label="Kategori"
                                                            labelSize={3}
                                                            size={8}
                                                        >
                                                            <TextField
                                                                name="category"
                                                                type="text"
                                                                errors={errors.category}
                                                                touched={touched.category}
                                                            />
                                                        </RowInput>
                                                        <RowInput
                                                            label="No. Telepon"
                                                            labelSize={3}
                                                            size={8}
                                                        >
                                                            <TextField
                                                                name="tlp"
                                                                type="text"
                                                                errors={errors.tlp}
                                                                touched={touched.tlp}
                                                            />
                                                        </RowInput>
                                                        <RowInput
                                                            label="No. Rekening"
                                                            labelSize={3}
                                                            size={8}
                                                        >
                                                            <TextField
                                                                name="acc_number"
                                                                type="text"
                                                                errors={errors.acc_number}
                                                                touched={touched.acc_number}
                                                            />
                                                        </RowInput>
                                                        <RowInput
                                                            label="Nama Bank"
                                                            labelSize={3}
                                                            size={8}
                                                        >
                                                            <TextField
                                                                name="acc_bank"
                                                                type="text"
                                                                errors={errors.acc_bank}
                                                                touched={touched.acc_bank}
                                                            />
                                                        </RowInput>
                                                        <RowInput
                                                            label="Pemilik Rekening"
                                                            labelSize={3}
                                                            size={8}
                                                        >
                                                            <TextField
                                                                name="acc_owner"
                                                                type="text"
                                                                errors={errors.acc_owner}
                                                                touched={touched.acc_owner}
                                                            />
                                                        </RowInput>
                                                        <RowInput
                                                            label="Merchant ID"
                                                            labelSize={3}
                                                            size={8}
                                                        >
                                                            <TextField
                                                                name="id_merchant"
                                                                type="text"
                                                                errors={errors.id_merchant}
                                                                touched={touched.id_merchant}
                                                            />
                                                        </RowInput>
                                                        <RowInput
                                                            label="Aktif"
                                                            labelSize={3}
                                                            size={8}
                                                        >
                                                            <TextField
                                                                name="is_active"
                                                                type="checkbox"
                                                                className='icheckbox_square-green'
                                                                errors={errors.is_active}
                                                                touched={touched.is_active}
                                                            />&nbsp; Iya/Tidak
                                                        </RowInput>
                                                        <hr></hr>
                                                        <Row className="justify-content-end">
                                                            <Col lg="12">
                                                                <Button type="submit" color="primary" disabled={isSubmitting}>
                                                                    <i className="bx bx-save font-size-16 align-middle me-2"></i>
                                                                    {isSubmitting ? 'Loading...' : 'Simpan'}
                                                                </Button>
                                                                &nbsp;
                                                                <Button type="button" color="secondary" onClick={() => { props.history.push('/data_toko') }}>
                                                                    <i className="bx  bx-left-arrow-circle font-size-16 align-middle me-2"></i>
                                                                    {'Kembali'}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Form>
                            </>
                        )
                    }}
                />
            </div>
        </React.Fragment>
    );
};



export default StoreCreate;
