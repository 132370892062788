import React, { useState } from "react";
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom'
import { DataGrid, deleteConfirm, Breadcrumb, Access } from '../../components/Common'
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Button
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux"
import { storeDelete } from "../../store/actions"
import { getExcel } from "../../helpers/api_helper"

const Stores = props => {

    const dispatch = useDispatch()
    const { loading, apk_name} = useSelector(state => ({
        loading: state.Stores.storeDeleteLoading,
        apk_name: state.Layout.apk_name,
    }))

    const tableColumns = [
        {
            name: 'Nama',
            selector: 'name',
            sortable: true,
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: true,
        },
        {
            name: 'Kategori',
            selector: 'category',
            sortable: true,
        },
        {
            name: 'No Telepon',
            selector: 'tlp',
            sortable: true,
        },
        {
            name: 'No Rekening',
            selector: 'acc_number',
            sortable: true,
        },
        {
            name: 'Bank',
            selector: 'acc_bank',
            sortable: true,
        },
        {
            name: 'Pemilik Rekening',
            selector: 'acc_owner',
            sortable: true,
        },
        {
            name: 'Merchant ID',
            selector: 'id_merchant',
            sortable: true,
        },
        {
            name: '',
            selector: 'id',
            sortable: true,
            right: true
        },

    ]

    const dataRender = ({ id, ...e }) => {
        return {
            id: <>
                <Access id={`25`}><i className="mdi mdi-24px mdi-book-edit-outline" onClick={() => handleEdit(id)}></i></Access>
                <Access id={`26`}><i className="mdi mdi-24px mdi-trash-can-outline" onClick={() => handleDelete({ id, ...e })}></i></Access>
            </>,
            ...e
        }
    }

    const handleEdit = (id) => {
        props.history.push(`/data_toko/edit/${id}`)
    }

    const handleDelete = ({ id, name }) => {
        deleteConfirm({
            name,
            command: () => dispatch(storeDelete({ id, code: name }))
        })
    }

    const handleExport = () => getExcel('/api/export_stores', 'Data Toko')

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Data Toko | {apk_name}</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumb title="Data Toko" breadcrumbItems={[
                        { title: 'Dashboard', link: '/dashboard' },
                        { title: 'Data Toko', link: '/data_toko' }
                    ]} />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="text-end">
                                        <Access id={`27`}><Button color="success" className="mr-1" onClick={handleExport}><i className="bx bx-file font-size-16 align-middle me-2"></i>Download Excel</Button>&nbsp;&nbsp;</Access>
                                        <Access id={`24`}><Link className="btn btn-primary" to={`/data_toko/tambah`}> <i className="bx bx-plus-circle font-size-16 align-middle me-2"></i> Tambah</Link></Access>
                                    </CardTitle>
                                    {!loading && <DataGrid
                                        dataRender={dataRender}
                                        title={'Data Toko'}
                                        nameQuery={`getIndexStore`}
                                        defSort={['name', 'asc']}
                                        tableColumns={tableColumns} />}
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment >
    );
};

export default Stores;
