import { del, get, post, put, stringify} from "../api_helper"

const postStoreCreate = data => {
    const query = `
        mutation createStore {
            createStore(input : ${stringify(data)}) {
             id
             email
            }
        }
    `
    return post({ query })
}

const getStore = data => {
    const query = `
        query getSingleStore {
            getSingleStore(id : ${data}) {
                name
                email
                category
                id_user
                user {
                    name
                }
                tlp
                acc_number
                acc_bank
                acc_owner
                id_merchant
                is_active
            }
        }
    `
    return post({ query })
}

const postStoreEdit = data => {
    const query = `
        mutation updateStore {
            updateStore(input : ${stringify(data)}) {
             id
            }
        }
    `
    return post({ query })
}


const postStoreDelete = data => {
    const query = `
        mutation daleteStore {
            daleteStore(input : ${stringify(data)}) {
             id
            }
        }
    `
    return post({ query })
}


const getStoreSelect2 = data => {
    const query = `
        query getAllStore {
            getAllStore(input : ${stringify(data)}) {
             label
             value
            }
        }
    `
    return post({ query })
}

export {
    postStoreCreate,
    getStore,
    postStoreEdit,
    postStoreDelete,
    getStoreSelect2
}
