import { takeEvery, fork, put, all, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { SET_RECAPSALE_FILTER } from "./actionTypes"
import {
  recapSaleGetFilter
} from "./actions"


function* setFilter({ payload }) {
  yield put(recapSaleGetFilter({ ...payload }))
}

export function* watchRecapSale() {
  yield takeEvery(SET_RECAPSALE_FILTER, setFilter)
}

function* recapSaleSaga() {
  yield all([fork(watchRecapSale)])
}

export default recapSaleSaga
