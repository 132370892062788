import { del, get, post, put, stringify} from "../api_helper"

const postSaleCreate = data => {
    const query = `
        mutation createSale {
            createSale(input : ${stringify(data)}) {
             id
            }
        }
    `
    return post({ query })
}

const getSale = data => {
    const query = `
        query getSingleSale {
            getSingleSale(id : ${data}) {
                id_user
                id_store
                store {
                    name
                }
                date
                order_no
                buyer_name
                buyer_tlp
                item_name
                id_status
                resi_no
                id_expedition
                id_supplier
                shopping
                omzet
            }
        }
    `
    return post({ query })
}

const postSaleEdit = data => {
    const query = `
        mutation updateSale {
            updateSale(input : ${stringify(data)}) {
             id
            }
        }
    `
    return post({ query })
}


const postSaleDelete = data => {
    const query = `
        mutation daleteSale {
            daleteSale(input : ${stringify(data)}) {
             id
            }
        }
    `
    return post({ query })
}


export {
    postSaleCreate,
    getSale,
    postSaleEdit,
    postSaleDelete
}
