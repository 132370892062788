import { post } from "../api_helper"

const getIncomeStatement = data => {
    const query = `
        query getIncomeStatement($data : filterIncomeStatement!) {
            getIncomeStatement(input : $data) {
                data { 
                    name
                    data {
                        name
                        amount
                        level
                    }
                }
            }
        }
    `
    return post({ query, variables: { data } })
}

export {
    getIncomeStatement
}
