import React  from "react";
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom'
import { DataGrid, deleteConfirm, Breadcrumb, Access } from '../../components/Common'
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux"
import { guideDelete } from "../../store/actions"

const Guides = props => {

    const dispatch = useDispatch()
    const { loading, apk_name} = useSelector(state => ({
        loading: state.WorkGuides.deleteLoading,
        apk_name: state.Layout.apk_name,
    }))

    const tableColumns = [
        {
            name: 'Nama',
            selector: 'name',
            sortable: true,
        },
        {
            name: '',
            selector: 'id',
            sortable: true,
            right: true,
        },

    ]

    const dataRender = ({ id, ...e }) => {
        return {
            id: <>
                <Access id={`37`}><i className="mdi mdi-24px mdi-book-edit-outline" onClick={() => handleEdit(id)}></i></Access>
                <i className="mdi mdi-24px mdi-folder-edit-outline" onClick={() => handleDetail(id)}></i>
                <Access id={`38`}><i className="mdi mdi-24px mdi-trash-can-outline" onClick={() => handleDelete({ id, ...e })}></i></Access>
            </>,
            ...e
        }
    }

    const handleEdit = (id) => {
        props.history.push(`/panduan_kerja/edit/${id}`)
    } 
    
    const handleDetail = (id) => {
        props.history.push(`/panduan_kerja/detail/${id}`)
    }

    const handleDelete = ({ id, name }) => {
        deleteConfirm({
            name,
            command: () => dispatch(guideDelete({ id, code: name }))
        })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Panduan Kerja | {apk_name}</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumb title="Panduan Kerja" breadcrumbItems={[
                        { title: 'Dashboard', link: '/dashboard' },
                        { title: 'Panduan Kerja', link: '/panduan_kerja' }
                    ]} />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="text-end">
                                        <Access id={`36`}><Link className="btn btn-primary" to={`/panduan_kerja/tambah`}> <i className="bx bx-plus-circle font-size-16 align-middle me-2"></i> Tambah</Link></Access>
                                    </CardTitle>
                                    {!loading && <DataGrid
                                        dataRender={dataRender}
                                        title={'Panduan Kerja'}
                                        nameQuery={`getIndexWorkGuide`}
                                        defSort={['name', 'asc']}
                                        tableColumns={tableColumns} />}
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment >
    );
};

export default Guides;
