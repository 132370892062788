import {
  EDIT_BASE,
  EDIT_BASE_SUCCESS,
  EDIT_BASE_ERROR
} from "./actionTypes"


export const baseEdit = (base, setSubmitting, history) => {
  return {
    type: EDIT_BASE,
    payload: { base, setSubmitting, history},
  }
}

export const baseEditSuccess = message => {
  return {
    type: EDIT_BASE_SUCCESS,
    payload: message,
  }
}

export const baseEditError = message => {
  return {
    type: EDIT_BASE_ERROR,
    payload: message,
  }
}

