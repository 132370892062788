import React, { useEffect } from "react";
import { Nav, NavLink, NavItem, TabContent, TabPane } from "reactstrap";
import {
    NavLink as RouterNavLink,
    Route,
    Switch,
    Redirect,
    useLocation
} from "react-router-dom";
import startCase from "lodash.startcase";
import { Loader } from "../../components/Common";
import { setTab } from "../../store/actions";
import { useDispatch } from "react-redux"

const components = {
    gaji_pokok: React.lazy(() => import("./base_salaries")),
    tunjangan: React.lazy(() => import("./allowances")),
    bonus: React.lazy(() => import("./bonuses")),
    status_kehadiran: React.lazy(() => import("./presence_statuses")),
    biaya_operasional: React.lazy(() => import("./operating_costs")),
};

const TabNav = () => {
    const dispatch = useDispatch()
    const { pathname } = useLocation();

    const getRoute = (route) => `/setting_aplikasi/${route}`;

    const renderNavItems = () =>
        Object.keys(components).map(route => (
            <NavItem key={`${route}-nav-item`}>
                <NavLink tag={RouterNavLink} to={getRoute(route)} exact>
                    {startCase(route)}
                </NavLink>
            </NavItem>
        ));

    const renderRoutes = () =>
        Object.entries(components).map(([route, Component]) => (
            <Route key={`${route}-route`} path={getRoute(route)}>
                <TabPane tabId={getRoute(route)}>
                    <Component />
                </TabPane>
            </Route>
        ));

    useEffect(() => {
        const path = pathname.split("/")
        const name = path.length === 3 ? path[2]?.replace("_", " ") : ''
        dispatch(setTab({ name, path: pathname }))        
    }, [pathname])

    return (
        <>
            <Nav className="icon-tab">{renderNavItems()}</Nav>
            <TabContent activeTab={pathname}>
                <React.Suspense fallback={<Loader />}>
                    <Switch>
                        {renderRoutes()}
                        <Redirect from="/" to="/setting_aplikasi/gaji_pokok" />
                    </Switch>
                </React.Suspense>
            </TabContent>
        </>
    );
};

export default TabNav;
