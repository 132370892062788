export const CREATE_OPSCOST = "CREATE_OPSCOST"
export const CREATE_OPSCOST_SUCCESS = "CREATE_OPSCOST_SUCCESS"
export const CREATE_OPSCOST_ERROR = "CREATE_OPSCOST_ERROR"

export const GET_OPSCOST = "GET_OPSCOST"
export const GET_OPSCOST_SUCCESS = "GET_OPSCOST_SUCCESS"
export const GET_OPSCOST_ERROR = "GET_OPSCOST_ERROR"

export const EDIT_OPSCOST = "EDIT_OPSCOST"
export const EDIT_OPSCOST_SUCCESS = "EDIT_OPSCOST_SUCCESS"
export const EDIT_OPSCOST_ERROR = "EDIT_OPSCOST_ERROR"

export const DELETE_OPSCOST = "DELETE_OPSCOST"
export const DELETE_OPSCOST_SUCCESS = "DELETE_OPSCOST_SUCCESS"
export const DELETE_OPSCOST_ERROR = "DELETE_OPSCOST_ERROR" 
