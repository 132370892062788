import {
  CREATE_ATTENDANCE,
  CREATE_ATTENDANCE_SUCCESS,
  CREATE_ATTENDANCE_ERROR,
  GET_ATTENDANCE,
  GET_ATTENDANCE_SUCCESS,
  GET_ATTENDANCE_ERROR,
  EDIT_ATTENDANCE,
  EDIT_ATTENDANCE_SUCCESS,
  EDIT_ATTENDANCE_ERROR,
  DELETE_ATTENDANCE,
  DELETE_ATTENDANCE_SUCCESS,
  DELETE_ATTENDANCE_ERROR,
  SET_ATTENDANCE_FILTER,
  GET_ATTENDANCE_FILTER
} from "./actionTypes"

export const attendanceCreate = (attendance, setSubmitting, history) => {
  return {
    type: CREATE_ATTENDANCE,
    payload: { attendance, setSubmitting, history},
  }
}

export const attendanceCreateSuccess = message => {
  return {
    type: CREATE_ATTENDANCE_SUCCESS,
    payload: message,
  }
}

export const attendanceCreateError = message => {
  return {
    type: CREATE_ATTENDANCE_ERROR,
    payload: message,
  }
}

export const attendanceGet = (attendance) => {
  return {
    type: GET_ATTENDANCE,
    payload: { attendance },
  }
}

export const attendanceGetSuccess = message => {
  return {
    type: GET_ATTENDANCE_SUCCESS,
    payload: message,
  }
}

export const attendanceGetError = message => {
  return {
    type: GET_ATTENDANCE_ERROR,
    payload: message,
  }
}

export const attendanceEdit = (attendance, setSubmitting, history) => {
  return {
    type: EDIT_ATTENDANCE,
    payload: { attendance, setSubmitting, history},
  }
}

export const attendanceEditSuccess = message => {
  return {
    type: EDIT_ATTENDANCE_SUCCESS,
    payload: message,
  }
}

export const attendanceEditError = message => {
  return {
    type: EDIT_ATTENDANCE_ERROR,
    payload: message,
  }
}

export const attendanceDelete = (attendance) => {
  return {
    type: DELETE_ATTENDANCE,
    payload: { attendance },
  }
}

export const attendanceDeleteSuccess = message => {
  return {
    type: DELETE_ATTENDANCE_SUCCESS,
    payload: message,
  }
}

export const attendanceDeleteError = message => {
  return {
    type: DELETE_ATTENDANCE_ERROR,
    payload: message,
  }
}

export const attendanceSetFilter = message => {
  return {
    type: SET_ATTENDANCE_FILTER,
    payload: message,
  }
}

export const attendanceGetFilter = message => {
  return {
    type: GET_ATTENDANCE_FILTER,
    payload: message,
  }
}
