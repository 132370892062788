import {
  GET_INCOME_STATEMENT_FILTER,
  SET_INCOME_STATEMENT_FILTER,
  GET_INCOME_STATEMENT,
  GET_INCOME_STATEMENT_SUCCESS,
  GET_INCOME_STATEMENT_ERROR
} from "./actionTypes"

const initialState = {
  getFilter: {
    page: 1,
    year: new Date()
  },
  loadingFilter: false,
  getError: null,
  getSuccess: [],
  getLoading: false,
}

const incomeStatement = (state = initialState, action) => {
  switch (action.type) {
    case SET_INCOME_STATEMENT_FILTER:
      state = {
        ...state,
        loadingFilter: true,
      }
      break
    case GET_INCOME_STATEMENT_FILTER:
      state = {
        ...state,
        getFilter: action.payload,
        loadingFilter: false
      }
      break
    case GET_INCOME_STATEMENT:
      state = {
        ...state,
        ...(action?.payload?.page === 1 && { getSuccess: [] }),
        getError: null,
        getLoading: true,
      }
      break
    case GET_INCOME_STATEMENT_SUCCESS:
      state = {
        ...state,
        getSuccess: [...state.getSuccess, ...action.payload],
        getLoading: false
      }
      break
    case GET_INCOME_STATEMENT_ERROR:
      state = {
        ...state,
        getError: action.payload,
        getLoading: false
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default incomeStatement
