import {
  CREATE_SALE,
  CREATE_SALE_SUCCESS,
  CREATE_SALE_ERROR,
  GET_SALE,
  GET_SALE_SUCCESS,
  GET_SALE_ERROR,
  EDIT_SALE,
  EDIT_SALE_SUCCESS,
  EDIT_SALE_ERROR,
  DELETE_SALE,
  DELETE_SALE_SUCCESS,
  DELETE_SALE_ERROR,
  GET_SALE_FILTER,
  SET_SALE_FILTER
} from "./actionTypes"
import moment from 'moment'

const initialState = {
  saleCreateSuccess: null,
  saleCreateError: null,
  saleCreateLoading: false,
  saleGetError: null,
  saleGetSuccess: null,
  saleGetLoading: false,
  saleEditError: null,
  saleEditSuccess: null,
  saleEditLoading: false,
  saleDeleteError: null,
  saleDeleteSuccess: null,
  saleDeleteLoading: false,
  saleGetFilter: null,
  loadingGetFilter: false,
}

const createSale = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SALE:
      state = {
        ...state,
        saleCreateSuccess: null,
        saleCreateError: null,
        saleCreateLoading: true
      }
      break
    case CREATE_SALE_SUCCESS:
      state = {
        ...state,
        saleCreateSuccess: action.payload,
        saleCreateLoading: false
      }
      break
    case CREATE_SALE_ERROR:
      state = {
        ...state,
        saleCreateError: action.payload,
        saleCreateLoading: false
      }
      break
    case GET_SALE:
      state = {
        ...state,
        saleGetError: null,
        saleGetSuccess: null,
        saleGetLoading: true,
      }
      break
    case GET_SALE_SUCCESS:
      state = {
        ...state,
        saleGetSuccess: action.payload,
        saleGetLoading: false
      }
      break
    case GET_SALE_ERROR:
      state = {
        ...state,
        saleGetError: action.payload,
        saleGetLoading: false
      }
      break
    case EDIT_SALE:
      state = {
        ...state,
        saleEditError: null,
        saleEditSuccess: null,
        saleEditLoading: true,
      }
      break
    case EDIT_SALE_SUCCESS:
      state = {
        ...state,
        saleEditSuccess: action.payload,
        saleEditLoading: false
      }
      break
    case EDIT_SALE_ERROR:
      state = {
        ...state,
        saleEditError: action.payload,
        saleEditLoading: false
      }
      break
    case DELETE_SALE:
      state = {
        ...state,
        saleDeleteError: null,
        saleDeleteSuccess: null,
        saleDeleteLoading: true,
      }
      break
    case DELETE_SALE_SUCCESS:
      state = {
        ...state,
        saleDeleteSuccess: action.payload,
        saleDeleteLoading: false
      }
      break
    case DELETE_SALE_ERROR:
      state = {
        ...state,
        saleDeleteError: action.payload,
        saleDeleteLoading: false
      }
      break
    case SET_SALE_FILTER:
      state = {
        ...state,
        saleGetFilter: null,
        loadingGetFilter: true,
      }
      break
    case GET_SALE_FILTER:
      state = {
        ...state,
        saleGetFilter: action.payload,
        loadingGetFilter: false
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default createSale
