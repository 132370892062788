import React, { useState } from "react";
import MetaTags from 'react-meta-tags';
import { Breadcrumb } from '../../components/Common'
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
} from "reactstrap";
import { useSelector } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom";
import TabNav from "./tabs";

const Settings = props => {
    const { path, name, apk_name} = useSelector(state => ({
        path: state.Settings.path,
        name : state.Settings.name,
        apk_name: state.Layout.apk_name,
    }))

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Setting Aplikasi | {apk_name}</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumb title={`Setting Aplikasi : ${name}`} breadcrumbItems={[
                        { title: 'Dashboard', link: '/dashboard' },
                        { title: 'Setting Aplikasi', link: '/setting_aplikasi' },
                        { title: name, link: path },
                    ]} />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Router>
                                        <TabNav />
                                    </Router>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment >
    );
};

export default Settings;
