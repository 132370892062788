import React, { useEffect } from "react";
import MetaTags from 'react-meta-tags';
import { Formik, FieldArray, Form } from 'formik';
import * as Yup from 'yup';
import { RowInput, TextField, DatePic, SelectAPI, Breadcrumb, Loader, FieldMask, TextArea } from '../../components/Common'
import { useDispatch, useSelector } from "react-redux"
import { attendanceEdit as asattendanceEditRedux, attendanceGet, getUserSelect2, presencesGet } from "../../store/actions"
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Button
} from "reactstrap";
import { useParams } from "react-router-dom";

const AttendanceEdit = props => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const { presences, attandance, apk_name} = useSelector(state => ({
        presences: state.Presences.presencesGetSuccess,
        attandance: state.Attendances.getSuccess,
        apk_name: state.Layout.apk_name,
    }))
    useEffect(() => {
        dispatch(presencesGet({ name: '', order: 1 }))
        dispatch(attendanceGet({ id: parseFloat(id) }))
    }, [])

    if (!presences || !attandance) return <Loader />

    const {
        date,
        employee_count,
        present_count,
        salary_cut,
        details
    } = attandance

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Edit Absensi | {apk_name}</title>
                </MetaTags>

                <Formik
                    initialValues={{
                        id: parseFloat(id),
                        date: new Date(date),
                        old_date :  new Date(date),
                        employee_count : details?.length,
                        present_count,
                        salary_cut,
                        details
                    }}
                    validationSchema={Yup.object().shape({
                        date: Yup.string().required('Tanggal tidak boleh kosong'),
                    })}

                    onSubmit={({ details, present_count, salary_cut, ...val }, { setSubmitting }) => {
                        let a = 0, b = 0
                        for (let s = 0; s < details.length; s++) {
                            a += parseFloat(details[s].salary_cut)
                            b += parseInt(details[s].is_present)
                        }

                        dispatch(asattendanceEditRedux({
                            ...val,
                            present_count: b,
                            salary_cut: a,
                            details
                        }, setSubmitting, props.history))
                    }}

                    render={({ values, errors, touched, isSubmitting, setFieldValue }) => {

                        const _handleSelect = (selectChoice, i) => {
                            setFieldValue(`details.[${i}].id_status`, selectChoice.value);
                            setFieldValue(`details.[${i}].salary_cut`, selectChoice.penalty);
                            setFieldValue(`details.[${i}].is_present`, selectChoice.is_present);
                        }

                        return (
                            <>
                                <Form>
                                    <Container fluid={true}>
                                        <Breadcrumb title="Edit Absensi" breadcrumbItems={[
                                            { title: 'Dashboard', link: '/dashboard' },
                                            { title: 'Laporan Absensi', link: '/absensi' },
                                            { title: 'Edit', link: `/absensi/edit/${id}` }
                                        ]} />
                                        <Row>
                                            <Col lg={12}>
                                                <Card>
                                                    <CardBody>
                                                        <CardTitle className="mb-4"></CardTitle>
                                                        <Row>
                                                            <RowInput
                                                                label="Tanggal"
                                                                mandatory={true}
                                                                labelSize={1}
                                                                size={4}
                                                            >
                                                                <DatePic
                                                                    name='date'
                                                                    value={values.date}
                                                                    showTime={false}
                                                                    placeholderText={`dd/mm/yyyy`}
                                                                    errors={errors.date}
                                                                    touched={touched.date}
                                                                    onChange={(date) => setFieldValue('date', date)}
                                                                />
                                                            </RowInput>
                                                            <FieldArray
                                                                name="details"
                                                                render={arrayHelpers => values?.details && values?.details?.length > 0 ? (values?.details.map((friend, i) => (
                                                                    <Row className="mb-2" key={i}>
                                                                        <Col lg={3}>
                                                                            {i === 0 ? <label>Nama Karyawan</label> : ''}
                                                                            <TextField
                                                                                name={`details.[${i}].user_name`}
                                                                                type="text"
                                                                                disabled={true}
                                                                                errors={errors?.details?.length ? errors?.details[i]?.user_name : null}
                                                                                touched={touched?.details?.length ? touched?.details[i]?.user_name : null}
                                                                            />
                                                                        </Col>
                                                                        <Col lg={3}>
                                                                            {i === 0 ? <label>Status Kehadiran</label> : ''}
                                                                            <SelectAPI
                                                                                name={`details.[${i}].id_status`}
                                                                                errors={errors?.details?.length ? errors?.details[i]?.id_status : null}
                                                                                touched={touched?.details?.length ? touched?.details[i]?.id_status : null}
                                                                                disabled={false}
                                                                                select_ref={false}
                                                                                options={presences}
                                                                                defaultValue={[{ value: values?.details[i]?.id_status, label: values?.details[i]?.status_name }]}
                                                                                nameQuery={`getAllPresenceStatus`}
                                                                                addField={`penalty is_present`}
                                                                                handleSelect={(selectChoice) => _handleSelect(selectChoice, i)}
                                                                            />
                                                                        </Col>
                                                                        <Col lg={4}>
                                                                            {i === 0 ? <label>Katerangan</label> : ''}
                                                                            <TextField
                                                                                name={`details.[${i}].note`}
                                                                                type="text"
                                                                                errors={errors?.details?.length ? errors?.details[i]?.note : null}
                                                                                touched={touched?.details?.length ? touched?.details[i]?.note : null}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                ))) : ''}
                                                            />
                                                        </Row>

                                                        <hr></hr>
                                                        <Row className="justify-content-end">
                                                            <Col lg="12">
                                                                <Button type="submit" color="primary" disabled={isSubmitting}>
                                                                    <i className="bx bx-save font-size-16 align-middle me-2"></i>
                                                                    {isSubmitting ? 'Loading...' : 'Simpan'}
                                                                </Button>
                                                                &nbsp;
                                                                <Button type="button" color="secondary" onClick={() => { props.history.push('/absensi') }}>
                                                                    <i className="bx  bx-left-arrow-circle font-size-16 align-middle me-2"></i>
                                                                    {'Kembali'}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Form>
                            </>
                        )
                    }}
                />
            </div>
        </React.Fragment>
    );
};



export default AttendanceEdit;
