import {
  GET_ALLOWANCES,
  GET_ALLOWANCES_SUCCESS,
  GET_ALLOWANCES_ERROR,
  EDIT_ALLOWANCES,
  EDIT_ALLOWANCES_SUCCESS,
  EDIT_ALLOWANCES_ERROR,
} from "./actionTypes"

const initialState = {
  allowancesGetError: null,
  allowancesGetSuccess: null,
  allowancesGetLoading: false,
  allowancesEditError: null,
  allowancesEditSuccess: null,
  allowancesEditLoading: false,
}

const createAllowances = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALLOWANCES:
      state = {
        ...state,
        allowancesGetError: null,
        allowancesGetSuccess: null,
        allowancesGetLoading: true,
      }
      break
    case GET_ALLOWANCES_SUCCESS:
      state = {
        ...state,
        allowancesGetSuccess: action.payload,
        allowancesGetLoading: false
      }
      break
    case GET_ALLOWANCES_ERROR:
      state = {
        ...state,
        allowancesGetError: action.payload,
        allowancesGetLoading: false
      }
      break

    case EDIT_ALLOWANCES:
      state = {
        ...state,
        allowancesEditError: null,
        allowancesEditSuccess: null,
        allowancesEditLoading: true,
      }
      break
    case EDIT_ALLOWANCES_SUCCESS:
      state = {
        ...state,
        allowancesEditSuccess: action.payload,
        allowancesEditLoading: false
      }
      break
    case EDIT_ALLOWANCES_ERROR:
      state = {
        ...state,
        allowancesEditError: action.payload,
        allowancesEditLoading: false
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default createAllowances
