import React, { useState } from "react";
import MetaTags from 'react-meta-tags';
import { Breadcrumb } from '../../components/Common'
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
} from "reactstrap";
import { useSelector } from "react-redux"
import { BrowserRouter as Router, useParams } from "react-router-dom";
import TabNav from "./tabs";

const UserDetail = props => {
    const { path, name, apk_name } = useSelector(state => ({
        path: state.Users.path,
        name: state.Users.name,
        apk_name: state.Layout.apk_name,
    }))
    const { id } = useParams()

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Detail Data Pegawai | {apk_name}</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumb title={`Detail Data Pegawai : ${name}`} breadcrumbItems={[
                        { title: 'Dashboard', link: '/dashboard' },
                        { title: 'Data Pegawai', link: '/data_pegawai' },
                        { title: name, link: path },
                    ]} />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Router>
                                        <TabNav id={id} />
                                    </Router>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment >
    );
};

export default UserDetail;
