import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ResetPassword from "../pages/Authentication/resetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//Penjualan
import SaleReport from "../pages/SaleReport/index"
import SaleCreate from "../pages/SaleReport/create"
import SaleEdit from "../pages/SaleReport/edit"

import RecapSaleReport from "../pages/RecapReport"
import RecapSaleAdminReport from "../pages/RecapReportAdmin"

//User
import UserCreate from "../pages/Users/create"
import Users from "../pages/Users"
import UserEdit from "../pages/Users/edit"
import UserDetail from "../pages/Users/detail"

//data toko
import Stores from "../pages/Stores"
import StoreCreate from "../pages/Stores/create" 
import StoreEdit from "../pages/Stores/edit"

//Setting
import Settings from "../pages/Settings"

//panduan kerja
import Guides from "../pages/WorkGuides"
import GuideCreate from "../pages/WorkGuides/create"
import GuideEdit from "../pages/WorkGuides/edit"
import GuideDetail from "../pages/WorkGuides/detail"

//Absensi
import Attendances from "../pages/Attendances"
import AttendanceCreate from "../pages/Attendances/create"
import AttendanceEdit from "../pages/Attendances/edit"

//Laporan Tarik Dana
import Withdrawals from "../pages/Withdrawals"
import WithdrawalCreate from "../pages/Withdrawals/create"
import WithdrawalEdit from "../pages/Withdrawals/edit"

//Laporan Gaji
import Salaries from "pages/Salaries"

//Laporan Laba/Rugi
import IncomeStatements from "pages/IncomeStatements"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/laporan_penjualan", component: SaleReport },
  { path: "/laporan_penjualan/tambah", component: SaleCreate },
  { path: "/laporan_penjualan/edit/:id", component: SaleEdit },
  { path: "/laporan_rekapan", component: RecapSaleReport },
  { path: "/laporan_rekapan_admin", component: RecapSaleAdminReport },
  { path: "/laporan_laba_rugi", component: IncomeStatements },
  { path: "/data_pegawai", component: Users },
  { path: "/data_pegawai/tambah", component: UserCreate },
  { path: "/data_pegawai/edit/:id", component: UserEdit },
  { path: "/data_pegawai/detail/:id", component: UserDetail },
  { path: "/data_pegawai/detail/:id/info", component: UserDetail },
  { path: "/data_pegawai/detail/:id/log_aktivitas", component: UserDetail },
  { path: "/data_toko", component: Stores },
  { path: "/data_toko/tambah", component: StoreCreate },
  { path: "/data_toko/edit/:id", component: StoreEdit },
  { path: "/panduan_kerja", component: Guides },
  { path: "/panduan_kerja/tambah", component: GuideCreate },
  { path: "/panduan_kerja/edit/:id", component: GuideEdit },
  { path: "/panduan_kerja/detail/:id", component: GuideDetail },
  { path: "/absensi", component: Attendances },
  { path: "/absensi/tambah", component: AttendanceCreate },
  { path: "/absensi/edit/:id", component: AttendanceEdit },
  { path: "/laporan_tarik_dana", component: Withdrawals },
  { path: "/laporan_tarik_dana/tambah", component: WithdrawalCreate },
  { path: "/laporan_tarik_dana/edit/:id", component: WithdrawalEdit },
  { path: "/laporan_gaji", component: Salaries },
  { path: "/setting_aplikasi", component: Settings },
  { path: "/setting_aplikasi/gaji_pokok", component: Settings },
  { path: "/setting_aplikasi/tunjangan", component: Settings },
  { path: "/setting_aplikasi/bonus", component: Settings },
  { path: "/setting_aplikasi/status_kehadiran", component: Settings },
  { path: "/setting_aplikasi/biaya_operasional", component: Settings },

  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/lupa-password", component: ForgetPwd },
  { path: "/reset-password", component: ResetPassword },
  { path: "/register", component: Register },
]

export { publicRoutes, authProtectedRoutes }
