import {
  SET_TAB,
  GET_TAB
} from "./actionTypes"

const initialState = {
  name: "Gaji Pokok",
  path: "/setting_aplikasi/gaji_pokok",
}

const setTab = (state = initialState, action) => {
  switch (action.type) {
    case SET_TAB:
      state = { ...state }
      break
    case GET_TAB:
      state = { ...action.payload }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default setTab
