import React, { useState, useEffect } from "react";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { DatePic, RowInput, SelectAPI, Loader } from '../../components/Common'
import { useDispatch, useSelector } from "react-redux"
import { salarySetFilter, getUserSelect2 } from "../../store/actions"
import {
    Card,
    Col,
    Row,
    Button,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Collapse
} from "reactstrap";
import classnames from "classnames"

const Filter = props => {
    const dispatch = useDispatch()
    const { filter, users, current_user, loadingFilter } = useSelector(state => ({
        filter: state.Salaries.getFilter,
        users: state.Users.userSelect2Success,
        loadingFilter: state.Salaries.loadingGetFilter,
        current_user: state.Login.current_user
    }))

    const { id_group = 0, id: id_user = 0, name: user_name = '', districtsGrupAccess=[]} = current_user

    const [col1, setcol1] = useState(false)
    const [loading, setLoading] = useState(false)

    const defaultUser = districtsGrupAccess.includes(id_group) ? [{ value: id_user, label: user_name }] : filter?.id_user || []
    const initialValues = {
        date: filter?.date || new Date(),
        id_user: defaultUser
    }

    useEffect(() => {
        dispatch(salarySetFilter(initialValues))
        dispatch(getUserSelect2({ name: '' }))
    }, [])

    const handleReset = (reset) => {
        setLoading(true)
        reset()
        setTimeout(() => {
            setLoading(false)
            dispatch(salarySetFilter({
                date: new Date(),
                id_user: defaultUser
            }))
        }, 10);
    }

    if (!users || loadingFilter) return <Loader />

    return (
        <React.Fragment>
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    date: Yup.string().required('Periode tidak boleh kosong'),
                })}

                onSubmit={(values, { setSubmitting }) => dispatch(salarySetFilter(values))}

                render={({ values, errors, touched, isSubmitting, setFieldValue, resetForm }) => {

                    const _handleSelect = (selectChoice, { name }) => {
                        setFieldValue(name, selectChoice);
                    }

                    return (
                        <>
                            <Form>
                                <Row>
                                    <Col lg={6}>
                                        <Card>
                                            <div className="accordion" id="accordion">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingOne">
                                                        <button
                                                            className={classnames(
                                                                "accordion-button",
                                                                "fw-medium",
                                                                { collapsed: !col1 }
                                                            )}
                                                            type="button"
                                                            onClick={() => setcol1(!col1)}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <b style={{ fontSize: 15, fontWeight: 550 }} > Sortir</b>
                                                        </button>
                                                    </h2>

                                                    <Collapse isOpen={col1} className="accordion-collapse">
                                                        <div className="accordion-body" style={{ padding: 30 }}>

                                                            <RowInput
                                                                label="Periode"
                                                                labelSize={2}
                                                            >
                                                                <InputGroup>
                                                                    <DatePic
                                                                        name='date'
                                                                        value={values.date}
                                                                        showTime={false}
                                                                        placeholderText={`mm/yyyy`}
                                                                        dateFormat="MM/yyyy"
                                                                        errors={errors.date}
                                                                        touched={touched.date}
                                                                        showMonthYearPicker={true}
                                                                        onChange={(date) => setFieldValue('date', date)}
                                                                    />
                                                                    <InputGroupAddon addonType="append">
                                                                        <InputGroupText>
                                                                            &nbsp;<i className='bx bx-calendar font-size-18 align-middle me-2' />
                                                                        </InputGroupText>
                                                                    </InputGroupAddon>
                                                                </InputGroup>
                                                            </RowInput>
                                                            <RowInput
                                                                label="Karyawan"
                                                                labelSize={2}
                                                                size={8}
                                                            >
                                                                {!loading ? <SelectAPI
                                                                    name="id_user"
                                                                    errors={errors.id_user}
                                                                    touched={touched.id_user}
                                                                    disabled={districtsGrupAccess.includes(id_group)}
                                                                    select_ref={false}
                                                                    placeholder={`Semua Karyawan`}
                                                                    options={users}
                                                                    defaultValue={defaultUser}
                                                                    isMulti={true}
                                                                    nameQuery={`getAllUser`}
                                                                    handleSelect={_handleSelect}
                                                                /> : ''}
                                                            </RowInput>
                                                            <hr></hr>
                                                            <Row className="justify-content-end">
                                                                <Col lg="12">
                                                                    <Button type="submit" color="primary" disabled={isSubmitting}>
                                                                        <i className='bx bx-filter font-size-16 align-middle me-2' ></i>
                                                                        {isSubmitting ? 'Loading...' : 'Sortir'}
                                                                    </Button>
                                                                    &nbsp;
                                                                    <Button type="button" color="secondary" onClick={() => handleReset(resetForm)}>
                                                                        <i className='bx bx-reset font-size-16 align-middle me-2' ></i>
                                                                        {'Reset'}
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Collapse>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                            </Form>
                        </>
                    )
                }}
            />
        </React.Fragment >
    );
};



export default Filter;


