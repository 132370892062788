import {
  SET_SALARY_FILTER,
  GET_SALARY_FILTER
} from "./actionTypes"


export const salarySetFilter = message => {
  return {
    type: SET_SALARY_FILTER,
    payload: message,
  }
}

export const salaryGetFilter = message => {
  return {
    type: GET_SALARY_FILTER,
    payload: message,
  }
}
