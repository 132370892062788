export const CREATE_STORE = "CREATE_STORE"
export const CREATE_STORE_SUCCESS = "CREATE_STORE_SUCCESS"
export const CREATE_STORE_ERROR = "CREATE_STORE_ERROR"

export const GET_STORE = "GET_STORE"
export const GET_STORE_SUCCESS = "GET_STORE_SUCCESS"
export const GET_STORE_ERROR = "GET_STORE_ERROR"

export const EDIT_STORE = "EDIT_STORE"
export const EDIT_STORE_SUCCESS = "EDIT_STORE_SUCCESS"
export const EDIT_STORE_ERROR = "EDIT_STORE_ERROR"

export const DELETE_STORE = "DELETE_STORE"
export const DELETE_STORE_SUCCESS = "DELETE_STORE_SUCCESS"
export const DELETE_STORE_ERROR = "DELETE_STORE_ERROR" 

export const GET_STORE_SELECT2 = "GET_STORE_SELECT2"
export const GET_STORE_SELECT2_SUCCESS = "GET_STORE_SELECT2_SUCCESS"
export const GET_STORE_SELECT2_ERROR = "GET_STORE_SELECT2_ERROR"