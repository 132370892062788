import React, { useState } from "react";
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom'
import { DataGrid, deleteConfirm, Breadcrumb, Access } from '../../components/Common'
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Button
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux"
import { attendanceDelete } from "../../store/actions"
import { getExcel } from "../../helpers/api_helper"
import moment from "moment";
import Filter from './filter2'

const Attendances = props => {
    const dispatch = useDispatch()
    const { loading, filter, loading2, apk_name} = useSelector(state => ({
        loading: state.Attendances.deleteLoading,
        filter: state.Attendances.getFilter,
        loading2: state.Attendances.loadingGetFilter,
        apk_name: state.Layout.apk_name,
    }))
    const tableColumns = [
        {
            name: 'Tanggal',
            selector: 'date',
            sortable: true,
        },
        {
            name: 'Jumlah Karyawan',
            selector: 'employee_count',
            sortable: true,
        },
        {
            name: 'Karyawan Hadir',
            selector: 'present_count',
            sortable: true,
        },
        {
            name: 'Karyawan Alpha',
            selector: 'alpha',
            sortable: true,
        },
        {
            name: 'Sanksi Potongan',
            selector: 'salary_cut',
            sortable: true,
        },
        {
            name: '',
            selector: 'id',
            sortable: true,
            right: true
        },

    ]

    const dataRender = ({ id, date, salary_cut, ...e }) => {
        return {
            date: moment(date).format('DD/MM/YYYY'),
            salary_cut: salary_cut.toLocaleString('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0}),
            id: <>
                <Access id={`42`}><i className="mdi mdi-24px mdi-book-edit-outline" onClick={() => handleEdit(id)}></i></Access>
                <Access id={`43`}><i className="mdi mdi-24px mdi-trash-can-outline" onClick={() => handleDelete({ id, date, ...e })}></i></Access>
            </>,
            ...e
        }
    }

    const handleEdit = (id) => props.history.push(`/absensi/edit/${id}`)

    const handleDelete = ({ id, date }) => {
        deleteConfirm({
            name: `Absensi Tgl ${moment(date).format('DD/MM/YYYY')}`,
            command: () => dispatch(attendanceDelete({ id, code: `Tgl ${moment(date).format('DD/MM/YYYY')}`, date }))
        })
    }

    const handleQuery = (query, values) => {
        Object.keys(values).forEach(e => {
            query += `${e}=${(e === 'date_1' || e === 'date_2') ? values[e] ? moment(values[e]).format('YYYY-MM-DD') : '' : values[e]}&`
        });
        query = query.slice(0, -1)
        return query
    }

    const handleExport = () => {
        const query = handleQuery(`?`, filter)
        getExcel(`/api/export_attendances${query}`, 'Laporan Absensi')
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Laporan Absensi | {apk_name}</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumb title="Laporan Absensi" breadcrumbItems={[
                        { title: 'Dashboard', link: '/dashboard' },
                        { title: 'Laporan Absensi', link: '/absensi' }
                    ]} />
                    <Row>
                        <Col lg={12}>
                            <Filter />
                            <Card>
                                <CardBody>
                                    <CardTitle className="text-end">
                                        <Button color="success" className="mr-1" onClick={handleExport}><i className="bx bx-file font-size-16 align-middle me-2"></i>Download Excel</Button>&nbsp;&nbsp;
                                        <Access id={`41`}><Link className="btn btn-primary" to={`/absensi/tambah`}> <i className="bx bx-plus-circle font-size-16 align-middle me-2"></i> Tambah</Link></Access>
                                    </CardTitle>
                                    {!loading && !loading2 && <DataGrid
                                        dataRender={dataRender}
                                        title={'Data Absensi'}
                                        nameQuery={`getIndexAttendance`}
                                        defSort={['date', 'asc']}
                                        filterAdd={{
                                            date_1: moment(filter?.date_1).format('YYYY-MM-DD'),
                                            date_2: moment(filter?.date_2).format('YYYY-MM-DD'),
                                        }}
                                        variablefilter={"inputPaginationAttendance!"}
                                        tableColumns={tableColumns} />}
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment >
    );
};

export default Attendances;
