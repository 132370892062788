import React, { useState } from "react";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { DatePic, RowInput } from '../../components/Common'
import { useDispatch, useSelector } from "react-redux"
import { incomeStatementSetFilter } from "../../store/actions"
import {
    Card,
    Col,
    Row,
    Button,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Collapse
} from "reactstrap";
import classnames from "classnames"
import { getExcel } from "../../helpers/api_helper"
import moment from "moment";

const Filter = props => {
    const dispatch = useDispatch()
    const { filter } = useSelector(state => ({
        filter: state.IncomeStatements.getFilter
    }))

    const [col1, setcol1] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleReset = (reset) => {
        setLoading(true)
        reset()
        setTimeout(() => {
            setLoading(false)
            dispatch(incomeStatementSetFilter({
                year: new Date(),
            }))
        }, 10);
    }

    const handleExport = () => {
        getExcel(`/api/export_income_statements?year=${moment(filter?.year).format('YYYY')}`, 'Laporan Laba Rugi')
    }

    return (
        <React.Fragment>
            <Formik
                initialValues={{
                    year: filter?.year || new Date()
                }}
                validationSchema={Yup.object().shape({
                    year: Yup.string().required('Tahun tidak boleh kosong'),
                })}

                onSubmit={(values, { setSubmitting }) => dispatch(incomeStatementSetFilter(values))}

                render={({ values, errors, touched, isSubmitting, setFieldValue, resetForm }) => {

                    return (
                        <>
                            <Form>
                                <Row>
                                    <Col lg={6}>
                                        <Card>
                                            <div className="accordion" id="accordion">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingOne">
                                                        <button
                                                            className={classnames(
                                                                "accordion-button",
                                                                "fw-medium",
                                                                { collapsed: !col1 }
                                                            )}
                                                            type="button"
                                                            onClick={() => setcol1(!col1)}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <b style={{ fontSize: 15, fontWeight: 550 }} > Sortir</b>
                                                        </button>
                                                    </h2>

                                                    <Collapse isOpen={col1} className="accordion-collapse">
                                                        <div className="accordion-body" style={{ padding: 30 }}>

                                                            <RowInput
                                                                label="Periode"
                                                                labelSize={2}
                                                            >
                                                                <InputGroup>
                                                                    <DatePic
                                                                        name='year'
                                                                        value={values.year}
                                                                        showTime={false}
                                                                        placeholderText={`yyyy`}
                                                                        dateFormat="yyyy"
                                                                        errors={errors.year}
                                                                        touched={touched.year}
                                                                        showYearPicker={true}
                                                                        onChange={(year) => setFieldValue('year', year)}
                                                                    />
                                                                    <InputGroupAddon addonType="append">
                                                                        <InputGroupText>
                                                                            &nbsp;<i className='bx bx-calendar font-size-18 align-middle me-2' />
                                                                        </InputGroupText>
                                                                    </InputGroupAddon>
                                                                </InputGroup>
                                                            </RowInput>
                                                            <hr></hr>
                                                            <Row className="justify-content-end">
                                                                <Col lg="12">
                                                                    <Button type="submit" color="primary" disabled={isSubmitting}>
                                                                        <i className='bx bx-filter font-size-16 align-middle me-2' ></i>
                                                                        {isSubmitting ? 'Loading...' : 'Sortir'}
                                                                    </Button>
                                                                    &nbsp;
                                                                    <Button color="success" className="mr-1" onClick={handleExport}><i className="bx bx-file font-size-16 align-middle me-2"></i>Download Excel</Button>&nbsp;
                                                                    <Button type="button" color="secondary" onClick={() => handleReset(resetForm)}>
                                                                        <i className='bx bx-reset font-size-16 align-middle me-2' ></i>
                                                                        {'Reset'}
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Collapse>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                            </Form>
                        </>
                    )
                }}
            />
        </React.Fragment >
    );
};



export default Filter;


