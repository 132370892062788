import { takeEvery, fork, put, all, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { CREATE_SALE, GET_SALE, EDIT_SALE, DELETE_SALE, SET_SALE_FILTER} from "./actionTypes"
import {
  saleCreateSuccess,
  saleCreateError,
  saleGetSuccess,
  saleGetError,
  saleEditError,
  saleEditSuccess,
  saleDeleteError,
  saleDeleteSuccess,
  saleGetFilter
} from "./actions"

import {
  postSaleCreate,
  getSale,
  postSaleEdit,
  postSaleDelete,
} from "../../helpers/backendAPI/sales"

import { Notify } from "../../components/Common"
import moment from 'moment'

function* saleGet({ payload: { sale } }) {
  try {
    const response = yield call(getSale, sale?.id)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(saleGetError(response?.errors[0]?.message))
    } else {
      yield put(
        saleGetSuccess(response?.data?.getSingleSale)
      )
    }
  } catch (error) {
    Notify('error', error?.message)
    yield put(saleGetError(error?.message))
  }
}

function* saleCreate({ payload: { sale: { date, ...s }, setSubmitting, history } }) {
  try {
    const response = yield call(postSaleCreate, {
      date: moment(date).isValid() ? moment(date).format() : null,
      ...s
    })
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(saleCreateError(response?.errors[0]?.message))
    } else {
      Notify('success', `Penjualan baru berhasil ditambahkan`)
      yield put(
        saleCreateSuccess('Penjualan baru berhasil ditambahkan')
      )
      history.push('/laporan_penjualan')
    }
    setSubmitting(false)
  } catch (error) {
    Notify('error', error?.message)
    setSubmitting(false)
    yield put(saleCreateError(error?.message))
  }
}

function* saleEdit({ payload: { sale: { date, ...s }, setSubmitting, history } }) {
  try {
    const response = yield call(postSaleEdit, {
      date: moment(date).isValid() ? moment(date).format() : null,
      ...s
    })
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(saleEditError(response?.errors[0]?.message))
    } else {
      Notify('success', `Penjualan berhasil diubah`)
      yield put(
        saleEditSuccess('Penjualan berhasil diubah')
      )
      history.push('/laporan_penjualan')
    }
    setSubmitting(false)
  } catch (error) {
    Notify('error', error?.message)
    setSubmitting(false)
    yield put(saleEditError(error?.message))
  }
}

function* saleDelete({ payload: { sale } }) {
  try {
    const response = yield call(postSaleDelete, sale)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(saleDeleteError(response?.errors[0]?.message))
    } else {
      Notify('success', `Penjualan berhasil dihapus`)
      yield put(
        saleDeleteSuccess('Penjualan berhasil dihapus')
      )
    }
  } catch (error) {
    Notify('error', error?.message)
    yield put(saleDeleteError(error?.message))
  }
}

function* saleSetFilter({ payload }) {
  yield put(saleGetFilter({ ...payload }))
}

export function* watchSale() {
  yield takeEvery(GET_SALE, saleGet)
  yield takeEvery(CREATE_SALE, saleCreate)
  yield takeEvery(EDIT_SALE, saleEdit)
  yield takeEvery(DELETE_SALE, saleDelete)
  yield takeEvery(SET_SALE_FILTER, saleSetFilter)
}

function* saleSaga() {
  yield all([fork(watchSale)])
}

export default saleSaga
