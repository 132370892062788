import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ResetSaga from "./auth/resetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import UsersSaga from "./users/saga"
import StoresSaga from "./stores/saga"
import SettingsSaga from "./settings/saga"
import BonusesSaga from "./settings/bonuses/saga"
import BaseSalariesSaga from "./settings/base_salaries/saga"
import AllowancesSaga from "./settings/allowances/saga"
import PresenceStatusesSaga from "./settings/presence_statuses/saga"
import OperatingCostsSaga from "./settings/operating_costs/saga"
import SaleSaga from "./sales/saga"
import RecapSaleSaga from "./recap_sales/saga"
import RecapSaleAdminSaga from "./recap_sales_admin/saga"
import WorkGuideSaga from "./work_guides/saga"
import AttendanceSaga from "./attendances/saga"
import SalarySaga from "./salaries/saga"
import WithdrawalSaga from "./withdrawals/saga"
import IncomeStatementSaga from "./income_statements/saga"
import LayoutSaga from "./layout/saga"
import ecommerceSaga from "./e-commerce/saga"
import calendarSaga from "./calendar/saga"
import chatSaga from "./chat/saga"
import cryptoSaga from "./crypto/saga"
import invoiceSaga from "./invoices/saga"
import projectsSaga from "./projects/saga"
import tasksSaga from "./tasks/saga"
import mailsSaga from "./mails/saga"
import contactsSaga from "./contacts/saga";
import dashboardSaga from "./dashboard/saga";
import dashboardSaasSaga from "./dashboard-saas/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ResetSaga),
    fork(ProfileSaga),
    fork(UsersSaga),
    fork(StoresSaga),
    fork(SettingsSaga),
    fork(BonusesSaga),
    fork(BaseSalariesSaga),
    fork(AllowancesSaga),
    fork(PresenceStatusesSaga),
    fork(OperatingCostsSaga),
    fork(SaleSaga),
    fork(RecapSaleSaga),
    fork(RecapSaleAdminSaga),
    fork(WorkGuideSaga),
    fork(AttendanceSaga),
    fork(SalarySaga),
    fork(WithdrawalSaga),
    fork(IncomeStatementSaga),
    fork(LayoutSaga),
    fork(ecommerceSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(mailsSaga),
    fork(cryptoSaga),
    fork(invoiceSaga),
    fork(projectsSaga),
    fork(tasksSaga),
    fork(contactsSaga),
    fork(dashboardSaga),
    fork(dashboardSaasSaga)
  ])
}
