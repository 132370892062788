import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component-footer'
import { Row, Col, Input, InputGroup, FormGroup } from 'reactstrap';
import _ from "lodash";
import { post } from '../../helpers/api_helper'
import { Notify } from '.'

const DataGridChild = ({ tableColumns, dataRender, title, title2, defSort, defPerPage = 10, addProps = {}, nameQuery, variablefilter = "inputPagination!", filterAdd = {} }) => {

    if (!title2) title2 = `Daftar ${title.replace('Data', '')}`
    const queryColumns = tableColumns.map(e => e.selector).join(' ')
    const [data, setData] = useState([]);
    const [footer, setFooter] = useState(null);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(defPerPage);
    const [vSearch, setSearch] = useState("")
    const [vSort, setSort] = useState(defSort)

    const Gridref = useRef()

    useEffect(() => {
        setLoading(true);
        const query = `
                query Daftar ($filter : ${variablefilter}) {
                ${nameQuery}(input: $filter) {
                    total
                    total_pages
                    data { ${queryColumns} }
                    summary { ${queryColumns}}
                    page
                }
                }
            `
        const variables = {
            filter: {
                page,
                per_page: perPage,
                search: vSearch,
                sort: vSort[0],
                order: vSort[1],
                ...filterAdd
            }
        }
        post({ query, variables }).then(response => {
            if (response?.errors?.length) {
                Notify('error', response?.errors[0]?.message)
            } else {
                setData(response?.data?.[nameQuery]?.data);
                setFooter(response?.data?.[nameQuery]?.summary);
                setTotalRows(response?.data?.[nameQuery]?.total);
            }
            setLoading(false);
        }).catch(e => {
            Notify('error', e?.message)
            setLoading(false);
        })
    }, [page, perPage, vSearch, vSort]);

    const handleSearch = async (e) => {
        const sVal = e.target.value;
        setSearch(sVal)
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    const handlePageChange = page => {
        setPage(page);
    };

    const handleSort = async (column, sortDirection) => {
        setSort([column.selector, sortDirection])
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Baris Per Laman',
        rangeSeparatorText: 'dari',
    };

    return (

        <React.Fragment>
            <div className="d-flex flex-wrap">
                <h5 className="card-title mb-4">{title2}</h5>
                <div className="ms-auto" style={{ width: '30%' }}>
                    <div className="toolbar d-flex flex-wrap gap-2 text-end" >
                        <FormGroup className="mb-3">
                            <InputGroup className="pill-input-group">
                                <Input className="form-control btn-pill" size={100} type="text" placeholder={`Cari ${title} ...`} aria-label="Recipient's username" value={vSearch} onChange={(e) => handleSearch(e)} />
                            </InputGroup>
                        </FormGroup>
                    </div>
                </div>
            </div>

            <Row>
                <Col sm="12">
                    <FormGroup className=" mb-3">
                        <DataTable
                            ref={ref => Gridref}
                            {...addProps}
                            style={{ marginTop: '-50px' }}
                            noDataComponent="Data Tidak Ditemukan"
                            striped={true}
                            columns={tableColumns}
                            data={data ? data.map((e) => dataRender(e)) : []}
                            progressPending={loading}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            paginationPerPage={defPerPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            onSort={handleSort}
                            footer={footer ? dataRender(footer) : null}
                            paginationComponentOptions={paginationComponentOptions}
                            progressComponent={<div style={{ minHeight: data.length ? 57 * data.length : 57, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>&nbsp;<i>Loading ...</i></div>}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </React.Fragment>

    );
};
   
function compare({ is_notmemo, ...prevProps }, { is_notmemo: n, ...nextProps }) {
    if (is_notmemo && n) {
        return false
    }
    return _.isEqual(_.omit({ ...prevProps }, _.functions({ ...prevProps })), _.omit({ ...nextProps }, _.functions({ ...nextProps })))
}

const DataGridFooter = React.memo(DataGridChild, compare);

export { DataGridFooter };