import {
  GET_BONUS,
  GET_BONUS_SUCCESS,
  GET_BONUS_ERROR,
  EDIT_BONUS,
  EDIT_BONUS_SUCCESS,
  EDIT_BONUS_ERROR,
} from "./actionTypes"


export const bonusGet = (bonus) => {
  return {
    type: GET_BONUS,
    payload: { bonus },
  }
}

export const bonusGetSuccess = message => {
  return {
    type: GET_BONUS_SUCCESS,
    payload: message,
  }
}

export const bonusGetError = message => {
  return {
    type: GET_BONUS_ERROR,
    payload: message,
  }
}

export const bonusEdit = (bonus, setSubmitting, history) => {
  return {
    type: EDIT_BONUS,
    payload: { bonus, setSubmitting, history},
  }
}

export const bonusEditSuccess = message => {
  return {
    type: EDIT_BONUS_SUCCESS,
    payload: message,
  }
}

export const bonusEditError = message => {
  return {
    type: EDIT_BONUS_ERROR,
    payload: message,
  }
}

