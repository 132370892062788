import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { SET_TAB } from "./actionTypes"
import { getTab } from "./actions"


//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* tabSet({ payload: { name, path } }) {
    yield put(getTab({ name, path }))
}

export function* watchSetTab() {
  yield takeEvery(SET_TAB, tabSet)
}

function* SettingsSaga() {
  yield all([fork(watchSetTab)])
}

export default SettingsSaga
