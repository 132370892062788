import { combineReducers } from "redux"
import { LOGOUT_USER } from "./auth/login/actionTypes";
// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import ResetPassword from "./auth/resetpwd/reducer"
import Profile from "./auth/profile/reducer"

//USER
import Users from "./users/reducer"

//Store
import Stores from "./stores/reducer"

//Setting
import Settings from "./settings/reducer"
import Bonuses from "./settings/bonuses/reducer"
import BaseSalaries from "./settings/base_salaries/reducer"
import Allowances from "./settings/allowances/reducer"
import Presences from "./settings/presence_statuses/reducer"
import OperatingCosts from "./settings/operating_costs/reducer"

//SALE
import Sales from "./sales/reducer"
import RecapSales from "./recap_sales/reducer"
import RecapSaleAdmin from "./recap_sales_admin/reducer"

//Panduan Kerja
import WorkGuides from "./work_guides/reducer"

//Absensi
import Attendances from "./attendances/reducer"

//Laporan Gaji
import Salaries from "./salaries/reducer"

//Laporan Tarik Dana
import Withdrawals from "./withdrawals/reducer"

//Laporan Laba/Rugi
import IncomeStatements from "./income_statements/reducer"

//E-commerce
import ecommerce from "./e-commerce/reducer"

//Calendar
import calendar from "./calendar/reducer"

//chat
import chat from "./chat/reducer"

//crypto
import crypto from "./crypto/reducer"

//invoices
import invoices from "./invoices/reducer"

//projects
import projects from "./projects/reducer"

//tasks
import tasks from "./tasks/reducer"

//contacts
import contacts from "./contacts/reducer"

//mails
import mails from "./mails/reducer";

//Dashboard 
import Dashboard from "./dashboard/reducer";

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer";

const appReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  ResetPassword,
  Profile,
  Users,
  Stores,
  Settings,
  Bonuses,
  BaseSalaries,
  Allowances,
  Presences,
  OperatingCosts,
  Sales,
  RecapSales,
  RecapSaleAdmin,
  WorkGuides,
  Attendances,
  Salaries,
  Withdrawals,
  IncomeStatements,
  ecommerce,
  calendar,
  chat,
  mails,
  crypto,
  invoices,
  projects,
  tasks,
  contacts,
  Dashboard,
  DashboardSaas
})

const rootReducer = (state, action) => {   
  // Clear all data in redux store to initial.
  if(action.type === LOGOUT_USER) {
    state = undefined;
  }
  
  return appReducer(state, action);
};

export default rootReducer
