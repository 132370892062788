import React, { useEffect } from "react";
import MetaTags from 'react-meta-tags';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { RowInput, TextField, DatePic, TextArea, SelectAPI, Loader, Breadcrumb } from '../../components/Common'
import { useSelector, useDispatch } from "react-redux"
import { userCreate as userCreateRedux, getGrupUserSelect2 } from "../../store/actions"
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Button
} from "reactstrap";

const UserCreate = props => {

    const dispatch = useDispatch()
    const { userGroupLoading, groups, apk_name} = useSelector(state => ({
        userGroupLoading: state.Users.userGroupLoading,
        groups: state.Users.userGroupSuccess,
        apk_name: state.Layout.apk_name,
    }))

    useEffect(() => {
        dispatch(getGrupUserSelect2({ name: '' }))
    }, [])

    if (!groups && !groups?.length) return <Loader />
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Tambah Data Pegawai | {apk_name}</title>
                </MetaTags>

                <Formik
                    initialValues={{
                        name: '',
                        email: '',
                        password: '',
                        confrim_password: '',
                        place_of_birth: '',
                        date_of_birth: null,
                        date_started_work: null,
                        id_group: groups[0]?.value,
                        address: '',
                        image: '',
                        is_active: true
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string().required('Nama Lengkap tidak boleh kosong'),
                        email: Yup.string().email('Format email tidak sesuai').required('Email tidak boleh kosong'),
                        password: Yup.string().required('Password tidak boleh kosong'),
                        confrim_password: Yup.string().required('Ulangi Password tidak boleh kosong')
                            .oneOf([Yup.ref('password'), null], 'Ulangi Passwords tidak sama'),
                        is_active: Yup.bool(),
                        id_group: Yup.number('Jabatan tidak boleh kosong').required('Jabatan tidak boleh kosong'),

                    })}

                    onSubmit={(values, { setSubmitting }) => dispatch(userCreateRedux(values, setSubmitting, props.history))}

                    render={({ values, errors, status, touched, isSubmitting, setFieldValue, handleSubmit }) => {

                        const _handleSelect = (selectChoice, { name }) => {
                            setFieldValue(name, selectChoice.value);
                        }

                        return (
                            <>
                                <Form>
                                    <Container fluid={true}>
                                        <Breadcrumb title="Tambah Data Pegawai" breadcrumbItems={[
                                            { title: 'Dashboard', link: '/dashboard' },
                                            { title: 'Data Pegawai', link: '/data_pegawai' },
                                            { title: 'Tambah', link: `/data_pegawai/tambah` }
                                        ]} />
                                        <Row>
                                            <Col lg={7}>
                                                <Card>
                                                    <CardBody>
                                                        <CardTitle className="mb-4"></CardTitle>

                                                        <RowInput
                                                            label="Nama Pegawai"
                                                            mandatory={true}
                                                            labelSize={3}
                                                            size={8}
                                                        >
                                                            <TextField
                                                                name="name"
                                                                type="text"
                                                                errors={errors.name}
                                                                touched={touched.name}
                                                            />
                                                        </RowInput>
                                                        <RowInput
                                                            label="Email"
                                                            mandatory={true}
                                                            labelSize={3}
                                                            size={8}
                                                        >
                                                            <TextField
                                                                name="email"
                                                                type="text"
                                                                errors={errors.email}
                                                                touched={touched.email}
                                                            />
                                                        </RowInput>

                                                        <RowInput
                                                            label="Password"
                                                            mandatory={true}
                                                            labelSize={3}
                                                            size={8}
                                                        >
                                                            <TextField
                                                                name="password"
                                                                type="password"
                                                                errors={errors.password}
                                                                touched={touched.password}
                                                            />
                                                        </RowInput>
                                                        <RowInput
                                                            label="Ulangi Password"
                                                            mandatory={true}
                                                            labelSize={3}
                                                            size={8}
                                                        >
                                                            <TextField
                                                                name="confrim_password"
                                                                type="password"
                                                                errors={errors.confrim_password}
                                                                touched={touched.confrim_password}
                                                            />
                                                        </RowInput>
                                                        <RowInput
                                                            label="Tempat Lahir"
                                                            labelSize={3}
                                                            size={8}
                                                        >
                                                            <TextField
                                                                name="place_of_birth"
                                                                type="text"
                                                                errors={errors.place_of_birth}
                                                                touched={touched.place_of_birth}
                                                            />
                                                        </RowInput>
                                                        <RowInput
                                                            label="Tanggal Lahir"
                                                            labelSize={3}
                                                            size={8}
                                                        >
                                                            <DatePic
                                                                name='date_of_birth'
                                                                value={values.date_of_birth}
                                                                showTime={false}
                                                                placeholderText={`dd/mm/yyyy`}
                                                                errors={errors.date_of_birth}
                                                                touched={touched.date_of_birth}
                                                                onChange={(date) => {
                                                                    setFieldValue('date_of_birth', date)
                                                                }}
                                                            />
                                                        </RowInput>
                                                        <RowInput
                                                            label="Tanggal Mulai Bekerja"
                                                            labelSize={3}
                                                            size={8}
                                                        >
                                                            <DatePic
                                                                name='date_started_work'
                                                                value={values.date_started_work}
                                                                showTime={false}
                                                                placeholderText={`dd/mm/yyyy`}
                                                                errors={errors.date_started_work}
                                                                touched={touched.date_started_work}
                                                                onChange={(date) => {
                                                                    setFieldValue('date_started_work', date)
                                                                }}
                                                            />
                                                        </RowInput>
                                                        <RowInput
                                                            label="Alamat"
                                                            labelSize={3}
                                                            size={8}
                                                        >
                                                            <TextArea
                                                                name="address"
                                                                rows={3}
                                                                errors={errors.address}
                                                                touched={touched.address}
                                                            />
                                                        </RowInput>
                                                        <RowInput
                                                            label="Jabatan"
                                                            mandatory={true}
                                                            labelSize={3}
                                                            size={8}
                                                        >
                                                            <SelectAPI
                                                                name="id_group"
                                                                errors={errors.id_group}
                                                                touched={touched.id_group}
                                                                disabled={false}
                                                                select_ref={false}
                                                                options={groups}
                                                                defaultValue={groups[0]}
                                                                nameQuery={`getAllGroup`}
                                                                handleSelect={_handleSelect}
                                                            />
                                                        </RowInput>
                                                        <RowInput
                                                            label="Aktif"
                                                            labelSize={3}
                                                            size={8}
                                                        >
                                                            <TextField
                                                                name="is_active"
                                                                type="checkbox"
                                                                className='icheckbox_square-green'
                                                                errors={errors.is_active}
                                                                touched={touched.is_active}
                                                            />&nbsp; Iya/Tidak
                                                        </RowInput>
                                                        <hr></hr>
                                                        <Row className="justify-content-end">
                                                            <Col lg="12">
                                                                <Button type="submit" color="primary" disabled={isSubmitting}>
                                                                    <i className="bx bx-save font-size-16 align-middle me-2"></i>
                                                                    {isSubmitting ? 'Loading...' : 'Simpan'}
                                                                </Button>
                                                                &nbsp;
                                                                <Button type="button" color="secondary" onClick={() => { props.history.push('/data_pegawai') }}>
                                                                    <i className="bx  bx-left-arrow-circle font-size-16 align-middle me-2"></i>
                                                                    {'Kembali'}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Form>
                            </>
                        )
                    }}
                />
            </div>
        </React.Fragment>
    );
};



export default UserCreate;
