import {
  CREATE_SALE,
  CREATE_SALE_SUCCESS,
  CREATE_SALE_ERROR,
  GET_SALE,
  GET_SALE_SUCCESS,
  GET_SALE_ERROR,
  EDIT_SALE,
  EDIT_SALE_SUCCESS,
  EDIT_SALE_ERROR,
  DELETE_SALE,
  DELETE_SALE_SUCCESS,
  DELETE_SALE_ERROR,
  GET_SALE_FILTER,
  SET_SALE_FILTER
} from "./actionTypes"

export const saleCreate = (sale, setSubmitting, history) => {
  return {
    type: CREATE_SALE,
    payload: { sale, setSubmitting, history},
  }
}

export const saleCreateSuccess = message => {
  return {
    type: CREATE_SALE_SUCCESS,
    payload: message,
  }
}

export const saleCreateError = message => {
  return {
    type: CREATE_SALE_ERROR,
    payload: message,
  }
}

export const saleGet = (sale) => {
  return {
    type: GET_SALE,
    payload: { sale },
  }
}

export const saleGetSuccess = message => {
  return {
    type: GET_SALE_SUCCESS,
    payload: message,
  }
}

export const saleGetError = message => {
  return {
    type: GET_SALE_ERROR,
    payload: message,
  }
}

export const saleEdit = (sale, setSubmitting, history) => {
  return {
    type: EDIT_SALE,
    payload: { sale, setSubmitting, history},
  }
}

export const saleEditSuccess = message => {
  return {
    type: EDIT_SALE_SUCCESS,
    payload: message,
  }
}

export const saleEditError = message => {
  return {
    type: EDIT_SALE_ERROR,
    payload: message,
  }
}

export const saleDelete = (sale) => {
  return {
    type: DELETE_SALE,
    payload: { sale },
  }
}

export const saleDeleteSuccess = message => {
  return {
    type: DELETE_SALE_SUCCESS,
    payload: message,
  }
}

export const saleDeleteError = message => {
  return {
    type: DELETE_SALE_ERROR,
    payload: message,
  }
}


export const saleSetFilter = message => {
  return {
    type: SET_SALE_FILTER,
    payload: message,
  }
}

export const saleGetFilter = message => {
  return {
    type: GET_SALE_FILTER,
    payload: message,
  }
}

