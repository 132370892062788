import React, { useState } from "react";
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom'
import { DataGrid, deleteConfirm, Breadcrumb ,Access} from '../../components/Common'
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Button
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux"
import { userDelete } from "../../store/actions"
import { getExcel } from "../../helpers/api_helper"
import moment from 'moment'
import 'moment/locale/id';

const Users = props => {

    const dispatch = useDispatch()
    const { loading, apk_name} = useSelector(state => ({
        loading: state.Users.userDeleteLoading,
        apk_name: state.Layout.apk_name,
    }))

    const tableColumns = [
        {
            name: 'Nama',
            selector: 'name',
            sortable: true,
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: true,
        },
        {
            name: 'Jabatan',
            selector: 'group_name',
            sortable: true,
        },
        {
            name: 'Lama Kerja',
            selector: 'work_day',
            sortable: true,
        },
        {
            name: 'Terakhir Login',
            selector: 'last_login',
            sortable: true,
        },
        {
            name: '',
            selector: 'id',
            sortable: true,
            right: true
        },

    ]

    const dataRender = ({ id, last_login, work_day, ...e }) => {
        return {
            work_day: work_day ? `${work_day} Hari` : '',
            last_login: last_login ? moment(last_login).format('dddd, DD MMMM YYYY, hh:mm:ss a') : '',
            id: <>
                <Access id={`14`}><i className="mdi mdi-24px mdi-book-edit-outline" onClick={() => handleEdit(id)}></i></Access>
                <Access id={`16`}><i className="mdi mdi-24px mdi-folder-open-outline" onClick={() => handleDetail(id)}></i></Access>
                <Access id={`15`}><i className="mdi mdi-24px mdi-trash-can-outline" onClick={() => handleDelete({ id, ...e })}></i></Access>
            </>,
            ...e
        }
    }

    const handleDetail = (id) => {
        props.history.push(`/data_pegawai/detail/${id}/info`)
    }

    const handleEdit = (id) => {
        props.history.push(`/data_pegawai/edit/${id}`)
    }

    const handleDelete = ({ id, name }) => {
        deleteConfirm({
            name,
            command: () => dispatch(userDelete({ id, code: name }))
        })
    }

    const handleExport = () => getExcel('/api/export_users', 'Data Pegawai')

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Data Pegawai | {apk_name}</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumb title="Data Pegawai" breadcrumbItems={[
                        { title: 'Dashboard', link: '/dashboard' },
                        { title: 'Data Pegawai', link: '/data_pegawai' }
                    ]} />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="text-end">
                                        <Access id={`17`}><Button color="success" className="mr-1" onClick={handleExport}><i className="bx bx-file font-size-16 align-middle me-2"></i>Download Excel</Button>&nbsp;&nbsp;</Access>
                                        <Access id={`13`}><Link className="btn btn-primary" to={`/data_pegawai/tambah`}> <i className="bx bx-plus-circle font-size-16 align-middle me-2"></i> Tambah</Link></Access>
                                    </CardTitle>
                                    {!loading && <DataGrid
                                        dataRender={dataRender}
                                        title={'Data Pegawai'}
                                        nameQuery={`getIndexUser`}
                                        defSort={['name', 'asc']}
                                        tableColumns={tableColumns} />}
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment >
    );
};

export default Users;
