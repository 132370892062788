import React from "react";
import MetaTags from 'react-meta-tags';
import { DataGridFooter, Breadcrumb, Access, deleteConfirm} from '../../components/Common'
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Button
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux"
import { withdrawalDelete } from "../../store/actions"
import { getExcel } from "../../helpers/api_helper"
import { Link } from "react-router-dom";
import moment from "moment";
import Filter from './filter2'

const Withdrawals = props => {
    const dispatch = useDispatch()
    const { loading, filter, loading2, apk_name } = useSelector(state => ({
        loading: state.Withdrawals.deleteLoading,
        filter: state.Withdrawals.getFilter,
        loading2: state.Withdrawals.loadingFilter,
        apk_name: state.Layout.apk_name,
    }))

    const tableColumns = [
        {
            name: 'Karyawan',
            selector: 'user_name',
            sortable: true,
        },
        {
            name: 'Nama Toko',
            selector: 'store_name',
            sortable: true,
        },
        {
            name: 'Email',
            selector: 'store_email',
            sortable: true,
        },
        {
            name: 'Nama Bank',
            selector: 'store_bank',
            sortable: true,
        },
        {
            name: 'Nomer Rekening',
            selector: 'store_number',
            sortable: true,
        },
        {
            name: 'Penerima',
            selector: 'store_owner',
            sortable: true,
        },
        {
            name: 'Tanggal',
            selector: 'date',
            sortable: true,
        },
        {
            name: 'Jumlah',
            selector: 'amount',
            sortable: true,
        },
        {
            name: '',
            selector: 'id',
            sortable: true,
        },

    ]

    const dataRender = ({ date, amount, id, ...e }) => {
        return {
            amount: amount.toLocaleString('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }),
            date: moment(date).isValid() ? moment(date).format('DD/MM/YYYY') : date,
            id: id ? <>
                <Access id={`20`}><i className="mdi mdi-24px mdi-book-edit-outline" onClick={() => handleEdit(id)}></i></Access>
                <Access id={`21`}><i className="mdi mdi-24px mdi-trash-can-outline" onClick={() => handleDelete({ id, ...e })}></i></Access>
            </> : '',
            ...e
        }
    }


    const handleEdit = (id) => props.history.push(`/laporan_tarik_dana/edit/${id}`)

    const handleDelete = ({ id, store_name }) => {
        deleteConfirm({
            name: `Penarikan Dana ${store_name}`,
            command: () => dispatch(withdrawalDelete({ id, code: store_name }))
        })
    }


    const handleQuery = (query, values) => {
        Object.keys(values).forEach(e => {
            if (e === 'id_store') {
                query += `${e}=${values[e] && values[e].length ? values[e].map(({ value }) => value) : ''}&`
                query += `store_name=${values[e] && values[e].length ? values[e].map(({ label }) => label) : 'Semua Toko'}&`
            } else if (e === 'id_user') {
                query += `${e}=${values[e] && values[e].length ? values[e].map(({ value }) => value) : ''}&`
                query += `user_name=${values[e] && values[e].length ? values[e].map(({ label }) => label) : 'Semua Karyawan'}&`
            } else {
                query += `${e}=${(e === 'date_1' || e === 'date_2') ? values[e] ? moment(values[e]).format('YYYY-MM-DD') : '' : values[e]}&`
            }
        });
        query = query.slice(0, -1)
        return query
    }

    const handleExport = () => {
        const query = handleQuery(`?`, filter)
        getExcel(`/api/export_withdrawals${query}`, 'Laporan Penarikan Dana')
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Laporan Penarikan Dana | {apk_name}</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumb title="Laporan Penarikan Dana" breadcrumbItems={[
                        { title: 'Dashboard', link: '/dashboard' },
                        { title: 'Laporan Penarikan Dana', link: '/laporan_tarik_dana' }
                    ]} />
                    <Row>
                        <Col lg={12}>
                            <Filter />
                            <Card>
                                <CardBody>
                                    <CardTitle className="text-end">
                                        <Access id={`22`}><Button color="success" className="mr-1" onClick={handleExport}><i className="bx bx-file font-size-16 align-middle me-2"></i>Download Excel</Button>&nbsp;&nbsp;</Access>
                                        <Access id={`19`}><Link className="btn btn-primary" to={`/laporan_tarik_dana/tambah`}> <i className="bx bx-plus-circle font-size-16 align-middle me-2"></i> Tambah</Link></Access>
                                    </CardTitle>
                                    {!loading && !loading2 && filter && <DataGridFooter
                                        dataRender={dataRender}
                                        title={'Data Penarikan Dana'}
                                        nameQuery={`getIndexWithdrawal`}
                                        defSort={['date', 'asc']}
                                        filterAdd={{
                                            date_1: moment(filter?.date_1).format('YYYY-MM-DD'),
                                            date_2: moment(filter?.date_2).format('YYYY-MM-DD'),
                                            id_store: filter?.id_store,
                                            id_user: filter?.id_user
                                        }}
                                        variablefilter={"inputWithdrawalPagination!"}
                                        tableColumns={tableColumns} />}
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment >
    );
};

export default Withdrawals;
