import React from "react";
import MetaTags from 'react-meta-tags';
import { DataGridFooter, Breadcrumb, Access } from '../../components/Common'
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Button
} from "reactstrap";
import { useSelector } from "react-redux"
import { getExcel } from "../../helpers/api_helper"
import moment from "moment";
import Filter from './filter2'

const RecapSaleReport = props => {
    const { filter, loading2, apk_name} = useSelector(state => ({
        filter: state.RecapSales.getFilter,
        loading2: state.RecapSales.loadingFilter,
        apk_name: state.Layout.apk_name,
    }))

    const tableColumns = [
        {
            name: 'Periode',
            selector: 'period',
            sortable: true,
        },
        {
            name: 'Nama Toko',
            selector: 'store_name',
            sortable: true,
        }, 
        {
            name: 'Nama Karyawan',
            selector: 'user_name',
            sortable: true,
        },
        {
            name: 'Total Omzet',
            selector: 'omzet',
            sortable: true,
        },
        {
            name: 'Total Belanja Supplier',
            selector: 'shopping',
            sortable: true,
        },
        {
            name: 'Total Profit',
            selector: 'profit',
            sortable: true,
        },
        {
            name: 'Jumlah Order',
            selector: 'orders',
            sortable: true,
        },

    ]

    const dataRender = ({ omzet, shopping, profit, ...e }) => {
        return {
            omzet: omzet.toLocaleString('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0}),
            shopping: shopping.toLocaleString('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }),
            profit: profit.toLocaleString('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }),
            ...e
        }
    }

    const handleQuery = (query, values) => {
        Object.keys(values).forEach(e => {
            if (e === 'id_store') {
                query += `${e}=${values[e] && values[e].length ? values[e].map(({ value }) => value) : ''}&`
                query += `store_name=${values[e] && values[e].length ? values[e].map(({ label }) => label) : 'Semua Toko'}&`
            } else if (e === 'id_user') {
                query += `${e}=${values[e] && values[e].length ? values[e].map(({ value }) => value) : ''}&`
                query += `user_name=${values[e] && values[e].length ? values[e].map(({ label }) => label) : 'Semua Karyawan'}&`
            } else {
                query += `${e}=${(e === 'date_1' || e === 'date_2') ? values[e] ? moment(values[e]).format('YYYY-MM-DD') : '' : values[e]}&`
            }
        });
        query = query.slice(0, -1)
        return query
    }

    const handleExport = () => {
        const query = handleQuery(`?`, filter)
        getExcel(`/api/export_recap_sales${query}`, 'Laporan Rekapan')
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Laporan Rekapan | {apk_name}</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumb title="Laporan Rekapan" breadcrumbItems={[
                        { title: 'Dashboard', link: '/dashboard' },
                        { title: 'Laporan Rekapan', link: '/laporan_rekapan' }
                    ]} />
                    <Row>
                        <Col lg={12}>
                            <Filter />
                            <Card>
                                <CardBody>
                                    <CardTitle className="text-end">
                                        <Access id={`7`}><Button color="success" className="mr-1" onClick={handleExport}><i className="bx bx-file font-size-16 align-middle me-2"></i>Download Excel</Button></Access>
                                    </CardTitle>
                                    {!loading2 && filter && <DataGridFooter
                                        dataRender={dataRender}
                                        title={'Data Rekapan'}
                                        nameQuery={`getIndexRecapSales`}
                                        defSort={['period', 'asc']}
                                        filterAdd={{
                                            date_1 : moment(filter?.date_1).format('YYYY-MM-DD'),
                                            date_2 : moment(filter?.date_2).format('YYYY-MM-DD'),
                                            id_store : filter?.id_store,
                                            id_user : filter?.id_user
                                        }}
                                        variablefilter={"inputRecapPagination!"}
                                        tableColumns={tableColumns} />}
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment >
    );
};

export default RecapSaleReport;
