import { del, get, post, put, stringify } from "../api_helper"

const createGuide = data => {
    const query = `
        mutation createWorkGuide($data : CreateWorkGuideInput!){
            createWorkGuide(input : $data) {
             id
            }
        }
    `
    return post({ query, variables : { data } })
}

const getGuide = data => {
    const query = `
        query getSingleWorkGuide($id : Int!) {
            getSingleWorkGuide(id : $id) {
                name
                description
            }
        }
    `
    return post({ query,  variables : { ...data }  })
}

const editGuide = data => {
    const query = `
        mutation updateWorkGuide($data : UpdateWorkGuideInput!) {
            updateWorkGuide(input : $data) {
             id
            }
        }
    `
    return post({ query, variables : { data }})
}


const deleteGuide = data => {
    const query = `
        mutation daleteWorkGuide($data : DeleteWorkGuideInput!) {
            daleteWorkGuide(input : $data) {
             id
            }
        }
    `
    return post({ query, variables : { data } })
}



export {
    createGuide,
    getGuide,
    editGuide,
    deleteGuide
}
