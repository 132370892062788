import {
  CREATE_WORK_GUIDE,
  CREATE_WORK_GUIDE_SUCCESS,
  CREATE_WORK_GUIDE_ERROR,
  GET_WORK_GUIDE,
  GET_WORK_GUIDE_SUCCESS,
  GET_WORK_GUIDE_ERROR,
  EDIT_WORK_GUIDE,
  EDIT_WORK_GUIDE_SUCCESS,
  EDIT_WORK_GUIDE_ERROR,
  DELETE_WORK_GUIDE,
  DELETE_WORK_GUIDE_SUCCESS,
  DELETE_WORK_GUIDE_ERROR
} from "./actionTypes"

export const guideCreate = (guide, setSubmitting, history) => {
  return {
    type: CREATE_WORK_GUIDE,
    payload: { guide, setSubmitting, history},
  }
}

export const guideCreateSuccess = message => {
  return {
    type: CREATE_WORK_GUIDE_SUCCESS,
    payload: message,
  }
}

export const guideCreateError = message => {
  return {
    type: CREATE_WORK_GUIDE_ERROR,
    payload: message,
  }
}

export const guideGet = (guide) => {
  return {
    type: GET_WORK_GUIDE,
    payload: { guide },
  }
}

export const guideGetSuccess = message => {
  return {
    type: GET_WORK_GUIDE_SUCCESS,
    payload: message,
  }
}

export const guideGetError = message => {
  return {
    type: GET_WORK_GUIDE_ERROR,
    payload: message,
  }
}

export const guideEdit = (guide, setSubmitting, history) => {
  return {
    type: EDIT_WORK_GUIDE,
    payload: { guide, setSubmitting, history},
  }
}

export const guideEditSuccess = message => {
  return {
    type: EDIT_WORK_GUIDE_SUCCESS,
    payload: message,
  }
}

export const guideEditError = message => {
  return {
    type: EDIT_WORK_GUIDE_ERROR,
    payload: message,
  }
}

export const guideDelete = (guide) => {
  return {
    type: DELETE_WORK_GUIDE,
    payload: { guide },
  }
}

export const guideDeleteSuccess = message => {
  return {
    type: DELETE_WORK_GUIDE_SUCCESS,
    payload: message,
  }
}

export const guideDeleteError = message => {
  return {
    type: DELETE_WORK_GUIDE_ERROR,
    payload: message,
  }
}
