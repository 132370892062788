import React from 'react'
import DatePicker from 'react-datepicker';
import { Field, ErrorMessage } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";
import id from "date-fns/locale/id";

class DatePicChild extends React.Component {

    render() {
     
        const { name, onChange, startDate, endDate } = this.props
        return (<div >
            <Field name={name}
                render={({ field }) => (
                    <DatePicker
                        {...field}
                        locale={id}
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        inline
                        monthsShown={2}
                    />
                )}
            />
            <ErrorMessage
                name={name}
                component="div" style={{ color: 'red' }}
                className="invalid-feedback" />
        </div>
        )
    }
}

function compare({ is_notmemo, ...prevProps }, { is_notmemo: n, ...nextProps }) {
    if (is_notmemo && n) {
        return false
    }
    return _.isEqual(_.omit({ ...prevProps }, _.functions({ ...prevProps })), _.omit({ ...nextProps }, _.functions({ ...nextProps })))
}

const DateRange = React.memo(DatePicChild, compare);

export { DateRange }