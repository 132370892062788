
import React from 'react'
import { Field, ErrorMessage } from 'formik';
import _ from "lodash";

class TextAreaChild extends React.Component {
    render() {
        let { errors, touched, name, rows = 5, className = `form-control`, onChange, placeholder, onBlur = () => { }, disabled } = this.props

        if (errors && touched) {
            className = `${className} is-invalid`
        }
        return (
            <React.Fragment>

                <Field name={name}
                    render={({ field }) => (
                        <textarea
                            {...{
                                ...field,
                                rows,
                                ... (onChange && { onChange }),
                                ... (onBlur && { onBlur }),
                                disabled,
                                ... (placeholder && { placeholder }),
                                ... (className && { className })
                            }}
                        />
                    )}
                />
                <ErrorMessage
                    name={name}
                    component="div" style={{ color: 'red' }}
                    className="invalid-feedback" />
            </React.Fragment>
        )
    }
}


function compare({ is_notmemo, ...prevProps }, { is_notmemo: n, ...nextProps }) {
    if (is_notmemo && n) {
        return false
    }
    return _.isEqual(_.omit({ ...prevProps }, _.functions({ ...prevProps })), _.omit({ ...nextProps }, _.functions({ ...nextProps })))
}

const TextArea = React.memo(TextAreaChild, compare);
export { TextArea }