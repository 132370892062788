import {
  GET_INCOME_STATEMENT_FILTER,
  SET_INCOME_STATEMENT_FILTER,
  GET_INCOME_STATEMENT,
  GET_INCOME_STATEMENT_SUCCESS,
  GET_INCOME_STATEMENT_ERROR,
} from "./actionTypes"

export const incomeStatementSetFilter = message => {
  return {
    type: SET_INCOME_STATEMENT_FILTER,
    payload: message,
  }
}

export const incomeStatementGetFilter = message => {
  return {
    type: GET_INCOME_STATEMENT_FILTER,
    payload: message,
  }
}

export const incomeStatementGet = (message) => {
  return {
    type: GET_INCOME_STATEMENT,
    payload: message,
  }
}

export const incomeStatementGetSuccess = message => {
  return {
    type: GET_INCOME_STATEMENT_SUCCESS,
    payload: message,
  }
}

export const incomeStatementGetError = message => {
  return {
    type: GET_INCOME_STATEMENT_ERROR,
    payload: message,
  }
}
