import { takeEvery, fork, put, all, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { SET_RECAPSALEADMIN_FILTER } from "./actionTypes"
import {
  recapSaleAdminGetFilter
} from "./actions"


function* setFilter({ payload }) {
  yield put(recapSaleAdminGetFilter({ ...payload }))
}

export function* watchRecapSale() {
  yield takeEvery(SET_RECAPSALEADMIN_FILTER, setFilter)
}

function* recapSaleSaga() {
  yield all([fork(watchRecapSale)])
}

export default recapSaleSaga
