import {
  CREATE_STORE,
  CREATE_STORE_SUCCESS,
  CREATE_STORE_ERROR,
  GET_STORE,
  GET_STORE_SUCCESS,
  GET_STORE_ERROR,
  EDIT_STORE,
  EDIT_STORE_SUCCESS,
  EDIT_STORE_ERROR,
  DELETE_STORE,
  DELETE_STORE_SUCCESS,
  DELETE_STORE_ERROR,
  GET_STORE_SELECT2,
  GET_STORE_SELECT2_SUCCESS,
  GET_STORE_SELECT2_ERROR,
} from "./actionTypes"

export const storeCreate = (store, setSubmitting, history) => {
  return {
    type: CREATE_STORE,
    payload: { store, setSubmitting, history},
  }
}

export const storeCreateSuccess = message => {
  return {
    type: CREATE_STORE_SUCCESS,
    payload: message,
  }
}

export const storeCreateError = message => {
  return {
    type: CREATE_STORE_ERROR,
    payload: message,
  }
}

export const storeGet = (store) => {
  return {
    type: GET_STORE,
    payload: { store },
  }
}

export const storeGetSuccess = message => {
  return {
    type: GET_STORE_SUCCESS,
    payload: message,
  }
}

export const storeGetError = message => {
  return {
    type: GET_STORE_ERROR,
    payload: message,
  }
}

export const storeEdit = (store, setSubmitting, history) => {
  return {
    type: EDIT_STORE,
    payload: { store, setSubmitting, history},
  }
}

export const storeEditSuccess = message => {
  return {
    type: EDIT_STORE_SUCCESS,
    payload: message,
  }
}

export const storeEditError = message => {
  return {
    type: EDIT_STORE_ERROR,
    payload: message,
  }
}

export const storeDelete = (store) => {
  return {
    type: DELETE_STORE,
    payload: { store },
  }
}

export const storeDeleteSuccess = message => {
  return {
    type: DELETE_STORE_SUCCESS,
    payload: message,
  }
}

export const storeDeleteError = message => {
  return {
    type: DELETE_STORE_ERROR,
    payload: message,
  }
}

export const getStoreSelect2 = (store) => ({
  type: GET_STORE_SELECT2,
  payload: { store }
})

export const getStoreSelect2Success = store => ({
  type: GET_STORE_SELECT2_SUCCESS,
  payload: store,
})

export const getStoreSelect2Error = error => ({
  type: GET_STORE_SELECT2_ERROR,
  payload: error,
})
