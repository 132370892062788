import { takeEvery, fork, put, all, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { CREATE_WORK_GUIDE, GET_WORK_GUIDE, EDIT_WORK_GUIDE, DELETE_WORK_GUIDE } from "./actionTypes"
import {
  guideGetSuccess,
  guideGetError,
  guideCreateSuccess,
  guideCreateError,
  guideEditError,
  guideEditSuccess,
  guideDeleteError,
  guideDeleteSuccess
} from "./actions"

import {
  createGuide,
  getGuide,
  editGuide,
  deleteGuide
} from "../../helpers/backendAPI/work_guides"

import { Notify } from "../../components/Common"

function* guideGet({ payload: { guide } }) {
  try {
    const response = yield call(getGuide, guide)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(guideGetError(response?.errors[0]?.message))
    } else {
      yield put(
        guideGetSuccess(response?.data?.getSingleWorkGuide)
      )
    }
  } catch (error) {
    Notify('error', error?.message)
    yield put(guideGetError(error?.message))
  }
}

function* guideCreate({ payload: { guide, setSubmitting, history } }) {
  try {
    const response = yield call(createGuide, guide)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(guideCreateError(response?.errors[0]?.message))
    } else {
      Notify('success', `Panduan kerja baru berhasil ditambahkan`)
      yield put(
        guideCreateSuccess('Panduan kerja baru berhasil ditambahkan')
      )
      history.push('/panduan_kerja')
    }
    setSubmitting(false)
  } catch (error) {
    Notify('error', error?.message)
    setSubmitting(false)
    yield put(guideCreateError(error?.message))
  }
}

function* guideEdit({ payload: { guide, setSubmitting, history } }) {
  try {
    const response = yield call(editGuide, guide)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(guideEditError(response?.errors[0]?.message))
    } else {
      Notify('success', `Panduan kerja berhasil diubah`)
      yield put(
        guideEditSuccess('Panduan kerja berhasil diubah')
      )
      history.push('/panduan_kerja')
    }
    setSubmitting(false)
  } catch (error) {
    Notify('error', error?.message)
    setSubmitting(false)
    yield put(guideEditError(error?.message))
  }
}

function* guideDelete({ payload: { guide } }) {
  try {
    const response = yield call(deleteGuide, guide)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(guideDeleteError(response?.errors[0]?.message))
    } else {
      Notify('success', `Panduan kerja berhasil dihapus`)
      yield put(
        guideDeleteSuccess('Panduan kerja berhasil dihapus')
      )
    }
  } catch (error) {
    Notify('error', error?.message)
    yield put(guideDeleteError(error?.message))
  }
}

export function* watchGuide() {
  yield takeEvery(GET_WORK_GUIDE, guideGet)
  yield takeEvery(CREATE_WORK_GUIDE, guideCreate)
  yield takeEvery(EDIT_WORK_GUIDE, guideEdit)
  yield takeEvery(DELETE_WORK_GUIDE, guideDelete)
}

function* guideSaga() {
  yield all([fork(watchGuide)])
}

export default guideSaga
