import { takeEvery, fork, put, all, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { CREATE_USER, GET_GROUP_USER_SELECT2, GET_USER, EDIT_USER, DELETE_USER, GET_USER_SELECT2, SET_TAB} from "./actionTypes"
import {
  userCreateSuccess,
  userCreateError,
  getGrupUserSelect2Error,
  getGrupUserSelect2Success,
  userGetSuccess,
  userGetError,
  userEditError,
  userEditSuccess,
  userDeleteError,
  userDeleteSuccess,
  getUserSelect2Error,
  getUserSelect2Success,
  userGetTab,
} from "./actions"

import {
  postUserCreate,
  getUserGroup,
  getUser,
  postUserEdit,
  postUserDelete,
  getUserSelect2
} from "../../helpers/backendAPI/users"

import { Notify } from "../../components/Common"
import moment from "moment"

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* createUser({ payload: { user: { confrim_password, date_of_birth, date_started_work, place_of_birth, is_active, ...users }, setSubmitting, history } }) {
  try {
    const response = yield call(postUserCreate, {
      is_active: is_active ? 1 : 0,
      place_of_birth : place_of_birth || '',
      date_of_birth: moment(date_of_birth).isValid() ? moment(date_of_birth).format() : null,
      date_started_work: moment(date_started_work).isValid() ? moment(date_started_work).format() : null,
      ...users,
    })
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(userCreateError(response?.errors[0]?.message))
    } else {
      Notify('success', `Pegawai baru berhasil ditambahkan`)
      yield put(
        userCreateSuccess('Pegawai baru berhasil ditambahkan')
      )
      history.push('/data_pegawai')
    }
    setSubmitting(false)
  } catch (error) {
    Notify('error', error)
    setSubmitting(false)
    yield put(userCreateError(error?.message))
  }
}

function* userGet({ payload: { user } }) {
  try {
    const response = yield call(getUser, user?.id)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(userGetError(response?.errors[0]?.message))
    } else {
      yield put(
        userGetSuccess(response?.data?.getSingleUser)
      )
    }
  } catch (error) {
    yield put(userGetError(error?.message))
  }
}

function* createEdit({ payload: { user: { address, image, date_of_birth, date_started_work, place_of_birth, is_active, ...users }, setSubmitting, history } }) {
  try {
    const response = yield call(postUserEdit, {
      address: address || '',
      image: image || '',
      place_of_birth : place_of_birth || '',
      is_active: is_active ? 1 : 0,
      date_of_birth: moment(date_of_birth).isValid() ? moment(date_of_birth).format() : null,
      date_started_work: moment(date_started_work).isValid() ? moment(date_started_work).format() : null,
      ...users,
    })
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(userEditError(response?.errors[0]?.message))
    } else {
      Notify('success', `Data Pegawai berhasil diubah`)
      yield put(
        userEditSuccess('Data Pegawai berhasil diubah')
      )
      history.push('/data_pegawai')
    }
    setSubmitting(false)
  } catch (error) {
    Notify('error', error)
    setSubmitting(false)
    yield put(userEditError(error?.message))
  }
}

function* userDelete({ payload: { user } }) {
  try {
    const response = yield call(postUserDelete, user)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(userDeleteError(response?.errors[0]?.message))
    } else {
      Notify('success', `Data Pegawai berhasil dihapus`)
      yield put(
        userDeleteSuccess('Data Pegawai berhasil dihapus')
      )
    }
  } catch (error) {
    yield put(userDeleteError(error?.message))
  }
}

function* getGrupUserSelect2({ payload: { user } }) {
  try {
    const response = yield call(getUserGroup, user)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(getGrupUserSelect2Error(response?.errors[0]?.message))
    } else {
      yield put(
        getGrupUserSelect2Success(response?.data?.getAllGroup)
      )
    }
  } catch (error) {
    yield put(getGrupUserSelect2Success(error?.message))
  }
}

function* userSelect2({ payload: { user } }) {
  try {
    const response = yield call(getUserSelect2, user)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(getUserSelect2Error(response?.errors[0]?.message))
    } else {
      yield put(
        getUserSelect2Success(response?.data?.getAllUser)
      )
    }
  } catch (error) {
    yield put(getUserSelect2Error(error?.message))
  }
}

function* tabSet({ payload: { name, path } }) {
  yield put(userGetTab({ name, path }))
}

export function* watchUserCreate() {
  yield takeEvery(CREATE_USER, createUser)
  yield takeEvery(GET_GROUP_USER_SELECT2, getGrupUserSelect2)
  yield takeEvery(GET_USER_SELECT2, userSelect2)
  yield takeEvery(GET_USER, userGet)
  yield takeEvery(EDIT_USER, createEdit)
  yield takeEvery(DELETE_USER, userDelete)
  yield takeEvery(SET_TAB, tabSet)
}

function* userCreateSaga() {
  yield all([fork(watchUserCreate)])
}

export default userCreateSaga
