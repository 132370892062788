import {
  CREATE_STORE,
  CREATE_STORE_SUCCESS,
  CREATE_STORE_ERROR,
  GET_STORE,
  GET_STORE_SUCCESS,
  GET_STORE_ERROR,
  EDIT_STORE,
  EDIT_STORE_SUCCESS,
  EDIT_STORE_ERROR,
  DELETE_STORE,
  DELETE_STORE_SUCCESS,
  DELETE_STORE_ERROR,
  GET_STORE_SELECT2,
  GET_STORE_SELECT2_ERROR,
  GET_STORE_SELECT2_SUCCESS,
} from "./actionTypes"

const initialState = {
  storeCreateSuccess: null,
  storeCreateError: null,
  storeCreateLoading: false,
  storeGetError: null,
  storeGetSuccess: null,
  storeGetLoading: false, 
  storeEditError: null,
  storeEditSuccess: null,
  storeEditLoading: false, 
  storeDeleteError: null,
  storeDeleteSuccess: null,
  storeDeleteLoading: false,
  storeSelect2Error: null,
  storeSelect2Success: null,
  storeSelect2Loading: false,
}

const createStore = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_STORE:
      state = {
        ...state,
        storeCreateSuccess: null,
        storeCreateError: null,
        storeCreateLoading: true
      }
      break
    case CREATE_STORE_SUCCESS:
      state = {
        ...state,
        storeCreateSuccess: action.payload,
        storeCreateLoading: false
      }
      break
    case CREATE_STORE_ERROR:
      state = { ...state, storeCreateError: action.payload, storeCreateLoading: false }
      break

    case GET_STORE:
      state = {
        ...state,
        storeGetError: null,
        storeGetSuccess: null,
        storeGetLoading: true,
      }
      break
    case GET_STORE_SUCCESS:
      state = {
        ...state,
        storeGetSuccess: action.payload,
        storeGetLoading: false
      }
      break
    case GET_STORE_ERROR:
      state = {
        ...state,
        storeGetError: action.payload,
        storeGetLoading: false
      }
      break 
      
      case EDIT_STORE:
      state = {
        ...state,
        storeEditError: null,
        storeEditSuccess: null,
        storeEditLoading: true,
      }
      break
    case EDIT_STORE_SUCCESS:
      state = {
        ...state,
        storeEditSuccess: action.payload,
        storeEditLoading: false
      }
      break
    case EDIT_STORE_ERROR:
      state = {
        ...state,
        storeEditError: action.payload,
        storeEditLoading: false
      }
      break   
      
      case DELETE_STORE:
      state = {
        ...state,
        storeDeleteError: null,
        storeDeleteSuccess: null,
        storeDeleteLoading: true,
      }
      break
    case DELETE_STORE_SUCCESS:
      state = {
        ...state,
        storeDeleteSuccess: action.payload,
        storeDeleteLoading: false
      }
      break
    case DELETE_STORE_ERROR:
      state = {
        ...state,
        storeDeleteError: action.payload,
        storeDeleteLoading: false
      }
      break
    
      case GET_STORE_SELECT2:
      state = {
        ...state,
        storeSelect2Error: null,
        storeSelect2Success: null,
        storeSelect2Loading: true,
      }
      break
    case GET_STORE_SELECT2_SUCCESS:
      state = {
        ...state,
        storeSelect2Success: action.payload,
        storeSelect2Loading: false
      }
      break
    case GET_STORE_SELECT2_ERROR:
      state = {
        ...state,
        storeSelect2Error: action.payload,
        storeSelect2Loading: false
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default createStore
