import {
  CREATE_ATTENDANCE,
  CREATE_ATTENDANCE_SUCCESS,
  CREATE_ATTENDANCE_ERROR,
  GET_ATTENDANCE,
  GET_ATTENDANCE_SUCCESS,
  GET_ATTENDANCE_ERROR,
  EDIT_ATTENDANCE,
  EDIT_ATTENDANCE_SUCCESS,
  EDIT_ATTENDANCE_ERROR,
  DELETE_ATTENDANCE,
  DELETE_ATTENDANCE_SUCCESS,
  DELETE_ATTENDANCE_ERROR,
  SET_ATTENDANCE_FILTER,
  GET_ATTENDANCE_FILTER
} from "./actionTypes"

import moment from "moment"

const initialState = {
  createSuccess: null,
  createError: null,
  createLoading: false,
  getError: null,
  getSuccess: null,
  getLoading: false,
  editError: null,
  editSuccess: null,
  editLoading: false,
  deleteError: null,
  deleteSuccess: null,
  deleteLoading: false,
  getFilter: {
    date_1: moment().startOf('month')._d,
    date_2: moment().endOf('month')._d,
  },
  loadingGetFilter: false,
}

const createAttendance = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ATTENDANCE:
      state = {
        ...state,
        createSuccess: null,
        createError: null,
        createLoading: true
      }
      break
    case CREATE_ATTENDANCE_SUCCESS:
      state = {
        ...state,
        createSuccess: action.payload,
        createLoading: false
      }
      break
    case CREATE_ATTENDANCE_ERROR:
      state = {
        ...state,
        createError: action.payload,
        createLoading: false
      }
      break

    case GET_ATTENDANCE:
      state = {
        ...state,
        getError: null,
        getSuccess: null,
        getLoading: true,
      }
      break
    case GET_ATTENDANCE_SUCCESS:
      state = {
        ...state,
        getSuccess: action.payload,
        getLoading: false
      }
      break
    case GET_ATTENDANCE_ERROR:
      state = {
        ...state,
        getError: action.payload,
        getLoading: false
      }
      break

    case EDIT_ATTENDANCE:
      state = {
        ...state,
        editError: null,
        editSuccess: null,
        editLoading: true,
      }
      break
    case EDIT_ATTENDANCE_SUCCESS:
      state = {
        ...state,
        editSuccess: action.payload,
        editLoading: false
      }
      break
    case EDIT_ATTENDANCE_ERROR:
      state = {
        ...state,
        editError: action.payload,
        editLoading: false
      }
      break

    case DELETE_ATTENDANCE:
      state = {
        ...state,
        deleteError: null,
        deleteSuccess: null,
        deleteLoading: true,
      }
      break
    case DELETE_ATTENDANCE_SUCCESS:
      state = {
        ...state,
        deleteSuccess: action.payload,
        deleteLoading: false
      }
      break
    case DELETE_ATTENDANCE_ERROR:
      state = {
        ...state,
        deleteError: action.payload,
        deleteLoading: false
      }
      break
    case SET_ATTENDANCE_FILTER:
      state = {
        ...state,
        getFilter: null,
        loadingGetFilter: true,
      }
      break
    case GET_ATTENDANCE_FILTER:
      state = {
        ...state,
        getFilter: action.payload,
        loadingGetFilter: false
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default createAttendance
