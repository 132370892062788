import React from 'react'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import _ from "lodash";

const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  // integerLimit: 7, // limit length of integer numbers
  allowNegative: true,
  allowLeadingZeroes: false,
}

const CurrencyInputChild = ({ maskOptions, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  })

  return <MaskedInput mask={currencyMask} {...inputProps} />
}

CurrencyInputChild.defaultProps = {
  inputMode: 'numeric',
  maskOptions: {},
}

function compare({ is_notmemo, ...prevProps }, { is_notmemo :n, ...nextProps }) {
  if(is_notmemo && n ){
    return false
  }
  return _.isEqual(_.omit({ ...prevProps }, _.functions({ ...prevProps })), _.omit({ ...nextProps }, _.functions({ ...nextProps })))
}

const CurrencyInput = React.memo(CurrencyInputChild, compare);

export { CurrencyInput }
