import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
    Card,
    CardBody,
    CardTitle,
} from "reactstrap";
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import ReactApexChart from "react-apexcharts";
import moment from 'moment';

const GET_DASHBOARD = gql`
 query getDashboardRevenue($filter : filterRevenue!){
    getDashboardRevenue(input : $filter){
      date
      profit
    }
  }
`;

const SUBSCRIPTION_DASHBOARD = gql`
subscription Revenue($filter : filterRevenue!){
    dashboardRevenue(input : $filter){
      date
      profit
    }
  }
`;

const Revenue = () => {
    const { filter: { date_option, date_1, date_2, ...f } } = useSelector(state => ({
        filter: state.Dashboard.getFilter
    }))

    const filter = {
        date_1: moment(date_1).format('YYYY-MM-DD'),
        date_2: moment(date_2).format('YYYY-MM-DD'),
        ...f
    }

    const { loading, error, data, subscribeToMore = () => { } } = useQuery(GET_DASHBOARD, {
        variables: {
            filter
        },
        fetchPolicy: 'network-only'
    });

    useEffect(() => {
        subscribeToMore && subscribeToMore({
            document: SUBSCRIPTION_DASHBOARD,
            variables: { filter },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData?.data) return prev;
                const { dashboardRevenue } = subscriptionData?.data;
                return {
                    getDashboardRevenue: dashboardRevenue
                };
            }
        })
    }, [])

    if (loading) return (
        <Card style={{
            opacity: 0.7,
            cursor: 'not-allowed',
            pointerEvents: 'none',
            height: 700
        }}>
            <CardBody>
                <CardTitle> Loading ...</CardTitle>
                <hr className="mb-4" />
            </CardBody>
        </Card>)

    if (error) return (
        <Card style={{
            opacity: 0.7,
            cursor: 'not-allowed',
            pointerEvents: 'none',
            height: 700
        }}>
            <CardBody>
                <CardTitle> Have Problems ...</CardTitle>
                <hr className="mb-4" />
            </CardBody>
        </Card>)
    const { getDashboardRevenue } = data;

    const series = [
        {
            name: "",
            data: getDashboardRevenue.map(e => e.profit),
        },
    ]

    const options = {
        chart: {
            height: 550,
            type: "area",
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                    customIcons: []
                },
                export: {
                    csv: {
                        filename: undefined,
                        columnDelimiter: ',',
                        headerCategory: 'category',
                        headerValue: 'value'
                    },
                    svg: {
                        filename: undefined,
                    },
                    png: {
                        filename: undefined,
                    }
                },
                autoSelected: 'zoom'
            },
        },
        colors: ["#556ee6"],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
            width: 4,
        },
        fill: {
            type: "gradient",
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.75,
                opacityTo: 0.70,
                stops: [30, 100, 100, 100],
            },
        },
        xaxis: {
            categories: getDashboardRevenue.map(e => e.date),
        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    return 'Rp. ' + value.toLocaleString();
                }
            },
        },

        markers: {
            size: 5,
            strokeWidth: 3,

            hover: {
                size: 6,
                sizeOffset: 2,
            },
        },
    }

    return (
        <Card>
            <CardBody>
                <CardTitle>Profit</CardTitle>
                <hr className="mb-4" />
                <div id="area-chart" dir="ltr">
                    <ReactApexChart
                        options={options}
                        series={series}
                        type="area"
                        height={550}
                        className="apex-charts"
                    />
                </div>
            </CardBody>
        </Card>
    )
}

export default Revenue;