import { takeEvery, fork, put, all, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { CREATE_WITHDRAWAL, GET_WITHDRAWAL, EDIT_WITHDRAWAL, DELETE_WITHDRAWAL, SET_WITHDRAWAL_FILTER } from "./actionTypes"
import {
  withdrawalGetSuccess,
  withdrawalGetError,
  withdrawalCreateSuccess,
  withdrawalCreateError,
  withdrawalEditError,
  withdrawalEditSuccess,
  withdrawalDeleteError,
  withdrawalDeleteSuccess,
  withdrawalGetFilter
} from "./actions"

import {
  createWithdrawal,
  getWithdrawal,
  editWithdrawal,
  deleteWithdrawal
} from "../../helpers/backendAPI/withdrawals"

import { Notify } from "../../components/Common"

function* withdrawalGet({ payload: { withdrawal } }) {
  try {
    const response = yield call(getWithdrawal, withdrawal)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(withdrawalGetError(response?.errors[0]?.message))
    } else {
      yield put(
        withdrawalGetSuccess(response?.data?.getSingleWithdrawal)
      )
    }
  } catch (error) {
    Notify('error', error?.message)
    yield put(withdrawalGetError(error?.message))
  }
}

function* withdrawalCreate({ payload: { withdrawal, setSubmitting, history } }) {
  try {
    const response = yield call(createWithdrawal, withdrawal)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(withdrawalCreateError(response?.errors[0]?.message))
    } else {
      Notify('success', `Tarik Dana baru berhasil ditambahkan`)
      yield put(
        withdrawalCreateSuccess('Tarik Dana baru berhasil ditambahkan')
      )
      history.push('/laporan_tarik_dana')
    }
    setSubmitting(false)
  } catch (error) {
    Notify('error', error?.message)
    setSubmitting(false)
    yield put(withdrawalCreateError(error?.message))
  }
}

function* withdrawalEdit({ payload: { withdrawal, setSubmitting, history } }) {
  try {
    const response = yield call(editWithdrawal, withdrawal)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(withdrawalEditError(response?.errors[0]?.message))
    } else {
      Notify('success', `Tarik Dana berhasil diubah`)
      yield put(
        withdrawalEditSuccess('Tarik Dana berhasil diubah')
      )
      history.push('/laporan_tarik_dana')
    }
    setSubmitting(false)
  } catch (error) {
    Notify('error', error?.message)
    setSubmitting(false)
    yield put(withdrawalEditError(error?.message))
  }
}

function* withdrawalDelete({ payload: { withdrawal } }) {
  try {
    const response = yield call(deleteWithdrawal, withdrawal)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(withdrawalDeleteError(response?.errors[0]?.message))
    } else {
      Notify('success', `Tarik Dana berhasil dihapus`)
      yield put(
        withdrawalDeleteSuccess('Tarik Dana berhasil dihapus')
      )
    }
  } catch (error) {
    Notify('error', error?.message)
    yield put(withdrawalDeleteError(error?.message))
  }
}

function* setFilter({ payload }) {
  yield put(withdrawalGetFilter({ ...payload }))
}


export function* watchWithdrawal() {
  yield takeEvery(GET_WITHDRAWAL, withdrawalGet)
  yield takeEvery(CREATE_WITHDRAWAL, withdrawalCreate)
  yield takeEvery(EDIT_WITHDRAWAL, withdrawalEdit)
  yield takeEvery(DELETE_WITHDRAWAL, withdrawalDelete)
  yield takeEvery(SET_WITHDRAWAL_FILTER, setFilter)
}

function* withdrawalSaga() {
  yield all([fork(watchWithdrawal)])
}

export default withdrawalSaga
