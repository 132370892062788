import toastr from "toastr";
import "toastr/build/toastr.min.css";

const Notify = (toastname, message) => {

  toastr.options = {
    positionClass: 'toast-bottom-right',
    progressBar: true,
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 30,
    hideDuration: 10,
  };

  switch (toastname) {
    case 'success':
      toastr.success(message, 'berhasil',{
        iconClass: 'toast-success',
      });
      break;
    case 'info':
      toastr.info(message, 'info');
      break;
    case 'warn':
      toastr.warning(message, 'peringatan', {
        iconClass: 'toast-warning',
      });
      break;
    case 'error':
      toastr.error(message, 'maaf', {
        iconClass: 'toast-error',
      });
      break;
    default:
      break;
  }
}

export { Notify };