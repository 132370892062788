import React, { useEffect } from "react";
import MetaTags from 'react-meta-tags';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { RowInput, TextField, DatePic, Select2, SelectAPI, Breadcrumb, Loader, FieldMask, TextArea} from '../../components/Common'
import { useDispatch, useSelector } from "react-redux"
import { saleEdit as saleEditRedux, getStoreSelect2, saleGet} from "../../store/actions"
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Button
} from "reactstrap";
import Statis from "./static_data"
import { useParams } from "react-router-dom";

const SaleEdit = props => {
    const { status, expeditions, suppliers } = Statis
    const dispatch = useDispatch()

    const { id }= useParams()
    const { stores, sale, apk_name } = useSelector(state => ({
        stores: state.Stores.storeSelect2Success,
        sale : state.Sales.saleGetSuccess,
        apk_name: state.Layout.apk_name,
    }))

    useEffect(() => {
        dispatch(getStoreSelect2({ name: '' }))
        dispatch(saleGet({ id }))
    }, [])

    if (!stores || !sale) return <Loader />

    const {
        id_store,
        store,
        date,
        order_no,
        buyer_name,
        buyer_tlp,
        item_name,
        id_status,
        resi_no,
        id_expedition,
        id_supplier,
        shopping,
        omzet
    }=sale
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Edit Data Penjualan | {apk_name}</title>
                </MetaTags>

                <Formik
                    initialValues={{
                        id : parseFloat(id),
                        id_store,
                        date : new Date(date),
                        order_no,
                        buyer_name,
                        buyer_tlp,
                        item_name,
                        id_status,
                        resi_no,
                        id_expedition,
                        id_supplier,
                        shopping,
                        omzet
                    }}
                    validationSchema={Yup.object().shape({
                        order_no: Yup.string().required('No Pesanan tidak boleh kosong'),
                        shopping: Yup.number().typeError('Nominal Belanja Harus Angka').required('Nominal Belanja Tidak Boleh Kosong'),
                        omzet: Yup.number().typeError('Penghasilan Harus Angka').required('Penghasilan Tidak Boleh Kosong'),
                    })}

                    onSubmit={(values, { setSubmitting }) => dispatch(saleEditRedux(values, setSubmitting, props.history))}

                    render={({ values, errors, touched, isSubmitting, setFieldValue }) => {

                        const _handleSelect = (selectChoice, { name }) => {
                            setFieldValue(name, selectChoice.value);
                        }

                        const onChangePrice = (event) => {
                            const { name, value } = event.target
                            let newval = 0
                            if (value) newval = value.replace(/,/g, '')
                            setFieldValue(name, parseFloat(newval))
                        }

                        return (
                            <>
                                <Form>
                                    <Container fluid={true}>
                                        <Breadcrumb title="Edit Data Penjualan" breadcrumbItems={[
                                            { title: 'Dashboard', link: '/dashboard' },
                                            { title: 'Laporan Penjualan', link: '/laporan_penjualan' },
                                            { title: 'Edit', link: `/laporan_penjualan/id/${id}` }
                                        ]} />
                                        <Row>
                                            <Col lg={12}>
                                                <Card>
                                                    <CardBody>
                                                        <CardTitle className="mb-4"></CardTitle>
                                                        <Row>
                                                            <Col md={6}>
                                                                <RowInput
                                                                    label="Dari Toko"
                                                                    mandatory={true}
                                                                    labelSize={3}
                                                                >
                                                                    <SelectAPI
                                                                        name="id_store"
                                                                        errors={errors.id_store}
                                                                        touched={touched.id_store}
                                                                        disabled={false}
                                                                        select_ref={false}
                                                                        options={stores}
                                                                        defaultValue={[{ value: id_store, label: store?.name }]}
                                                                        nameQuery={`getAllStore`}
                                                                        handleSelect={_handleSelect}
                                                                    />
                                                                </RowInput>
                                                                <RowInput
                                                                    label="Tanggal"
                                                                    mandatory={true}
                                                                    labelSize={3}
                                                                    size={8}
                                                                >
                                                                    <DatePic
                                                                        name='date'
                                                                        value={values.date}
                                                                        showTime={false}
                                                                        placeholderText={`dd/mm/yyyy`}
                                                                        errors={errors.date}
                                                                        touched={touched.date}
                                                                        onChange={(date) => setFieldValue('date', date)}
                                                                    />
                                                                </RowInput>
                                                                <RowInput
                                                                    label="No Pesanan"
                                                                    mandatory={true}
                                                                    labelSize={3}
                                                                >
                                                                    <TextField
                                                                        name="order_no"
                                                                        type="text"
                                                                        errors={errors.order_no}
                                                                        touched={touched.order_no}
                                                                    />
                                                                </RowInput>
                                                                <RowInput
                                                                    label="Nama Pembeli"
                                                                    labelSize={3}
                                                                >
                                                                    <TextField
                                                                        name="buyer_name"
                                                                        type="text"
                                                                        errors={errors.buyer_name}
                                                                        touched={touched.buyer_name}
                                                                    />
                                                                </RowInput>
                                                                <RowInput
                                                                    label="No Telp Pembeli"
                                                                    labelSize={3}
                                                                >
                                                                    <TextField
                                                                        name="buyer_tlp"
                                                                        type="text"
                                                                        errors={errors.buyer_tlp}
                                                                        touched={touched.buyer_tlp}
                                                                    />
                                                                </RowInput>
                                                                <RowInput
                                                                    label="Status"
                                                                    labelSize={3}
                                                                >
                                                                    <Select2
                                                                        name="id_status"
                                                                        errors={errors.id_status}
                                                                        touched={touched.id_status}
                                                                        disabled={false}
                                                                        select_ref={false}
                                                                        options={status}
                                                                        defaultValue={[{ value: id_status, label: status.find(e => e.value === id_status)?.label }]}
                                                                        view={10}
                                                                        handleSelect={_handleSelect}
                                                                    />
                                                                </RowInput>
                                                                <RowInput
                                                                    label="No Resi"
                                                                    labelSize={3}
                                                                >
                                                                    <TextField
                                                                        name="resi_no"
                                                                        type="text"
                                                                        errors={errors.resi_no}
                                                                        touched={touched.resi_no}
                                                                    />
                                                                </RowInput>
                                                                <RowInput
                                                                    label="Ekspedisi"
                                                                    labelSize={3}
                                                                >
                                                                    <Select2
                                                                        name="id_expedition"
                                                                        errors={errors.id_expedition}
                                                                        touched={touched.id_expedition}
                                                                        disabled={false}
                                                                        select_ref={false}
                                                                        options={expeditions}
                                                                        defaultValue={[{ value: id_expedition, label: expeditions.find(e => e.value === id_expedition)?.label }]}
                                                                        view={10}
                                                                        handleSelect={_handleSelect}
                                                                    />
                                                                </RowInput>
                                                            </Col>
                                                            <Col md={6}>
                                                                <RowInput
                                                                    label="Supplier"
                                                                    labelSize={3}
                                                                >
                                                                    <Select2
                                                                        name="id_supplier"
                                                                        errors={errors.id_supplier}
                                                                        touched={touched.id_supplier}
                                                                        disabled={false}
                                                                        select_ref={false}
                                                                        options={suppliers}
                                                                        defaultValue={[{ value: id_supplier, label: suppliers.find(e => e.value === id_supplier)?.label }]}
                                                                        view={10}
                                                                        handleSelect={_handleSelect}
                                                                    />
                                                                </RowInput>
                                                                <RowInput
                                                                    label="Nominal Belanja"
                                                                    labelSize={3}
                                                                >
                                                                    <FieldMask
                                                                        name={`shopping`}
                                                                        type="text"
                                                                        onChange={(e) => onChangePrice(e)}
                                                                        errors={errors?.shopping}
                                                                        touched={touched?.shopping}
                                                                    />
                                                                </RowInput>
                                                                <RowInput
                                                                    label="Penghasilan"
                                                                    labelSize={3}
                                                                >
                                                                    <FieldMask
                                                                        name={`omzet`}
                                                                        type="text"
                                                                        onChange={(e) => onChangePrice(e)}
                                                                        errors={errors?.omzet}
                                                                        touched={touched?.omzet}
                                                                    />
                                                                </RowInput>
                                                                <RowInput
                                                                    label="Nama Barang"
                                                                    labelSize={3}
                                                                >
                                                                    <TextArea
                                                                        name="item_name"
                                                                        rows={14}
                                                                        errors={errors.item_name}
                                                                        touched={touched.item_name}
                                                                    />
                                                                </RowInput>
                                                            </Col>
                                                        </Row>

                                                        <hr></hr>
                                                        <Row className="justify-content-end">
                                                            <Col lg="12">
                                                                <Button type="submit" color="primary" disabled={isSubmitting}>
                                                                    <i className="bx bx-save font-size-16 align-middle me-2"></i>
                                                                    {isSubmitting ? 'Loading...' : 'Simpan'}
                                                                </Button>
                                                                &nbsp;
                                                                <Button type="button" color="secondary" onClick={() => { props.history.push('/laporan_penjualan') }}>
                                                                    <i className="bx  bx-left-arrow-circle font-size-16 align-middle me-2"></i>
                                                                    {'Kembali'}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Form>
                            </>
                        )
                    }}
                />
            </div>
        </React.Fragment>
    );
};



export default SaleEdit;
