export const CREATE_SALE = "CREATE_SALE"
export const CREATE_SALE_SUCCESS = "CREATE_SALE_SUCCESS"
export const CREATE_SALE_ERROR = "CREATE_SALE_ERROR"

export const GET_SALE = "GET_SALE"
export const GET_SALE_SUCCESS = "GET_SALE_SUCCESS"
export const GET_SALE_ERROR = "GET_SALE_ERROR"

export const EDIT_SALE = "EDIT_SALE"
export const EDIT_SALE_SUCCESS = "EDIT_SALE_SUCCESS"
export const EDIT_SALE_ERROR = "EDIT_SALE_ERROR"

export const DELETE_SALE = "DELETE_SALE"
export const DELETE_SALE_SUCCESS = "DELETE_SALE_SUCCESS"
export const DELETE_SALE_ERROR = "DELETE_SALE_ERROR" 

export const SET_SALE_FILTER = "SET_SALE_FILTER"
export const GET_SALE_FILTER = "GET_SALE_FILTER"