import React from 'react'
import { Label, Col } from 'reactstrap'
import _ from "lodash";

class RowInputChild extends React.Component {
    render() {
        const { children, label, size, mandatory, labelSize = 3, labelStyle = {}, lineHeight=4 } = this.props
        return (

            <div className={`row mb-${lineHeight} align-items-center`}>
                <Label
                    htmlFor="horizontal-firstname-Input"
                    className={`col-sm-${labelSize} col-form-label`}
                >
                    {label}{mandatory && <span style={{ color: 'red' }}> *</span>}
                </Label>
                <Col sm={size}>
                    {children}
                </Col>
            </div>
        )
    }
}


function compare({ is_notmemo, ...prevProps }, { is_notmemo :n,  ...nextProps }) {
    if(is_notmemo && n ){
      return false
    }
    return _.isEqual(_.omit({ ...prevProps }, _.functions({ ...prevProps })), _.omit({ ...nextProps }, _.functions({ ...nextProps })))
  }

const RowInput = React.memo(RowInputChild, compare);

export { RowInput }