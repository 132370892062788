import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { RESET_PASSWORD } from "./actionTypes"
import { userResetPasswordSuccess, userResetPasswordError } from "./actions"

import {
  postResetPwd
} from "../../../helpers/backendAPI/auth"

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* resetUser({ payload: { user, setSubmitting } }) {
  try {
    const response = yield call(postResetPwd, user)

    if (response?.errors?.length) {
      yield put(userResetPasswordError(response?.errors[0]?.message))
    }else{
      yield put(
        userResetPasswordSuccess(
          response?.data?.createResetPassword?.message
        )
      )
    }
    setSubmitting(false)
  } catch (error) {
    setSubmitting(false)
    yield put(userResetPasswordError(error))
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(RESET_PASSWORD, resetUser)
}

function* resetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default resetPasswordSaga
