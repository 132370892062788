export const CREATE_WITHDRAWAL = "CREATE_WITHDRAWAL"
export const CREATE_WITHDRAWAL_SUCCESS = "CREATE_WITHDRAWAL_SUCCESS"
export const CREATE_WITHDRAWAL_ERROR = "CREATE_WITHDRAWAL_ERROR"

export const GET_WITHDRAWAL = "GET_WITHDRAWAL"
export const GET_WITHDRAWAL_SUCCESS = "GET_WITHDRAWAL_SUCCESS"
export const GET_WITHDRAWAL_ERROR = "GET_WITHDRAWAL_ERROR"

export const EDIT_WITHDRAWAL = "EDIT_WITHDRAWAL"
export const EDIT_WITHDRAWAL_SUCCESS = "EDIT_WITHDRAWAL_SUCCESS"
export const EDIT_WITHDRAWAL_ERROR = "EDIT_WITHDRAWAL_ERROR"

export const DELETE_WITHDRAWAL = "DELETE_WITHDRAWAL"
export const DELETE_WITHDRAWAL_SUCCESS = "DELETE_WITHDRAWAL_SUCCESS"
export const DELETE_WITHDRAWAL_ERROR = "DELETE_WITHDRAWAL_ERROR" 

export const SET_WITHDRAWAL_FILTER = "SET_WITHDRAWAL_FILTER"
export const GET_WITHDRAWAL_FILTER = "GET_WITHDRAWAL_FILTER"