import { takeEvery, fork, put, all, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { CREATE_STORE, GET_STORE_SELECT2, GET_STORE, EDIT_STORE, DELETE_STORE } from "./actionTypes"
import {
  storeCreateSuccess,
  storeCreateError,
  getStoreSelect2Error,
  getStoreSelect2Success,
  storeGetSuccess,
  storeGetError,
  storeEditError,
  storeEditSuccess,
  storeDeleteError,
  storeDeleteSuccess
} from "./actions"

import {
  postStoreCreate,
  getStore,
  postStoreEdit,
  postStoreDelete,
  getStoreSelect2,
} from "../../helpers/backendAPI/stores"

import { Notify } from "../../components/Common"

function* storeGet({ payload: { store } }) {
  try {
    const response = yield call(getStore, store?.id)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(storeGetError(response?.errors[0]?.message))
    } else {
      yield put(
        storeGetSuccess(response?.data?.getSingleStore)
      )
    }
  } catch (error) {
    Notify('error', error?.message)
    yield put(storeGetError(error?.message))
  }
}

function* createStore({ payload: { store : { is_active, ...s }, setSubmitting, history } }) {
  try {
    const response = yield call(postStoreCreate, {
      is_active: is_active ? 1 : 0,
      ...s
    })
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(storeCreateError(response?.errors[0]?.message))
    } else {
      Notify('success', `Data Toko baru berhasil ditambahkan`)
      yield put(
        storeCreateSuccess('Data Toko baru berhasil ditambahkan')
      )
      history.push('/data_toko')
    }
    setSubmitting(false)
  } catch (error) {
    Notify('error', error?.message)
    setSubmitting(false)
    yield put(storeCreateError(error?.message))
  }
}

function* editStore({ payload: { store: { is_active, ...s }, setSubmitting, history } }) {
  try {
    const response = yield call(postStoreEdit, {
      is_active: is_active ? 1 : 0,
      ...s
    })
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(storeEditError(response?.errors[0]?.message))
    } else {
      Notify('success', `Data Toko berhasil diubah`)
      yield put(
        storeEditSuccess('Data Toko berhasil diubah')
      )
      history.push('/data_toko')
    }
    setSubmitting(false)
  } catch (error) {
    Notify('error', error?.message)
    setSubmitting(false)
    yield put(storeEditError(error?.message))
  }
}

function* deleteStore({ payload: { store } }) {
  try {
    const response = yield call(postStoreDelete, store)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(storeDeleteError(response?.errors[0]?.message))
    } else {
      Notify('success', `Data Toko berhasil dihapus`)
      yield put(
        storeDeleteSuccess('Data Toko berhasil dihapus')
      )
    }
  } catch (error) {
    Notify('error', error?.message)
    yield put(storeDeleteError(error?.message))
  }
}

function* getSelect2Store({ payload: { store } }) {
  try {
    const response = yield call(getStoreSelect2, store)
    if (response?.errors?.length) {
      Notify('error', response?.errors[0]?.message)
      yield put(getStoreSelect2Error(response?.errors[0]?.message))
    } else {
      yield put(
        getStoreSelect2Success(response?.data?.getAllStore)
      )
    }
  } catch (error) {
    Notify('error', error?.message)
    yield put(getStoreSelect2Error(error?.message))
  }
}

export function* watchStore() {
  yield takeEvery(GET_STORE, storeGet)
  yield takeEvery(CREATE_STORE, createStore)
  yield takeEvery(EDIT_STORE, editStore)
  yield takeEvery(DELETE_STORE, deleteStore)
  yield takeEvery(GET_STORE_SELECT2, getSelect2Store)
}

function* storeSaga() {
  yield all([fork(watchStore)])
}

export default storeSaga
